import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PasswordForgottenPage} from './password-forgotten/password-forgotten.page';
import {PasswordResetPage} from './password-reset/password-reset.page';
import {PasswordRegisterPage} from './password-register/password-register.page';
import {LoggedGuard} from '../../guards/logged.guard';

const routes: Routes = [
    {
        path: 'password/password-forgotten',
        component: PasswordForgottenPage,
        canActivate: [LoggedGuard]
    },
    {
        path: 'password/password-reset/:token',
        component: PasswordResetPage,
        canActivate: [LoggedGuard]
    },
    {
        path: 'password/password-register/:invitationId',
        component: PasswordRegisterPage,
        canActivate: [LoggedGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class PasswordRoutingModule { }
