<app-header titleKey="NOTIFICATIONS_LABEL"></app-header>

<ion-content id="notification-list">
    <ion-refresher
        #refresher
        slot="fixed"
        [disabled]="!(userRepository.isLoggedIn() | async)"
        (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}"
        >
        </ion-refresher-content>
    </ion-refresher>
    <ion-list>
        <app-notification-card [id]="'notification-list-item-' + i"
                               *ngFor="let notification of (notifications$ | async); index as i;"
                               [notif]="notification"
                               [i]="i">
        </app-notification-card>
    </ion-list>

    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noNotificationsDataLoading>
        <app-empty-notification-card></app-empty-notification-card>
        <app-empty-notification-card></app-empty-notification-card>
        <app-empty-notification-card></app-empty-notification-card>
    </app-loading-no-data>
</ion-content>
