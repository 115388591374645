import {Component, Inject, OnDestroy} from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
import {AuthSessionRepository} from '../../repositories/auth/authSession.repository';
import {UserRepository} from '../../repositories/user/user.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {ConnectionStatus, Network} from '@capacitor/network';
import {Browser} from '@capacitor/browser';
import {TranslateService} from '@ngx-translate/core';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';
import {consume} from '../../common/rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-cgu-pca',
    templateUrl: './cgu-pca.component.html',
    styleUrls: ['./cgu-pca.component.scss'],
})
export class CguPcaComponent implements OnDestroy {

    public tandc = false;
    private cguPath: string;
    private pcaPath: string;
    private toast;
    private subscription: Subscription;

    constructor(private userRepository: UserRepository,
                private modalController: ModalController,
                private toastController: ToastController,
                private authSessionRepository: AuthSessionRepository,
                private resourceStatusService: ResourceStatusService,
                private translateService: TranslateService,
                @Inject(ENV) public environment: Env) {
        this.cguPath = this.translateService.currentLang === 'fr' ? environment.cguPathFr : environment.cguPathEn;
        this.pcaPath = this.translateService.currentLang === 'fr' ? environment.pcaPathFr : environment.pcaPathEn;
        this.subscription = Subscription.EMPTY;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public async acceptCguPca() {
        if (this.tandc) {
            this.subscription = this.userRepository.acceptTermsAndConditions().pipe(
                consume(this.resourceStatusService),
            ).subscribe(async (result) => {
                await this.modalController.dismiss();
            });
        } else {
            const message = this.translateService.instant('CHECKBOX_ERROR_TOAST');
            const toastOptions = {
                message,
                color: 'danger',
                keyboardClose: true,
                duration: 4000
            };
            this.toast = await this.toastController.create(toastOptions);
            await this.toast.present();
        }
    }

    public async clickedTerms(){
        const offline = await this.checkIfOffline();
        if (!offline) {
            const url = this.cguPath;
            await Browser.open(
                {
                    url,
                    windowName: '_system'
                }
            );
        }
    }

    public async clickedPrivacy() {
        const offline = await this.checkIfOffline();
        if (!offline) {
            const url = this.pcaPath;
            await Browser.open(
                {
                    url,
                    windowName: '_system'
                }
            );
        }
    }

    private async checkIfOffline(): Promise<boolean> {
        const networkStatus: ConnectionStatus = await Network.getStatus();
        if (!networkStatus.connected) {
            const message = this.translateService.instant('NO_INTERNET_CONNECTION');
            const toastOptions = {
                message,
                color: 'danger',
                keyboardClose: true,
                duration: 4000
            };
            const toast = await this.toastController.create(toastOptions);
            await toast.present();
            return true;
        }
        return false;
    }

}
