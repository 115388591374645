<ion-header mode="md">
  <ion-toolbar mode="md">
    <ion-buttons slot="start">
      <ion-menu-button *ngIf="menuIconEnabled"
                       color="primary">
      </ion-menu-button>
      <ion-back-button *ngIf="!menuIconEnabled && backArrowEnabled"
                       [defaultHref]="defaultBackHref"
                       color="primary"
                       text="">
      </ion-back-button>
    </ion-buttons>
    <ion-title class="ios title-ios app-title">
      {{ titleKey ? (titleKey | translate: titleInterpolation) : titleText }}
    </ion-title>
    <ion-buttons slot="end">
      <app-admin-button [ngClass]="adminIconEnabled && event ? '' : 'ion-hide'" [roles]="event ? event.roles : []"></app-admin-button>
      <app-offline-button [ngClass]="offlineIconEnabled ? '' : 'ion-hide'"></app-offline-button>
      <app-profile-button [ngClass]="profileIconEnabled ? '' : 'ion-hide'" [event]="event"></app-profile-button>
      <app-scan-button [ngClass]="qrCodeIconEnabled ? '' : 'ion-hide'"></app-scan-button>
    </ion-buttons>
  </ion-toolbar>
  <app-loading></app-loading> <!-- this is only the normal loading, the blocking loading is in the app component, singleton for the whole app-->
<!--  <app-error></app-error>
REMOVED from here because an instance of it is added to the DOM each time we add an header
what happens is that if the user visits X pages with their header we have X error toast each time.
MOVED to the main app component
-->
</ion-header>
