import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {News, NewsService} from '../../api';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {EventNews} from '../../store/reducers/eventNews/event-news.reducer';
import {selectAnEventNewsById} from '../../store/reducers/eventNews/event-news.selectors';
import {upsertOneEventNews} from '../../store/reducers/eventNews/event-news.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetAnEventNewsById extends NetworkBoundResource<News, News> {
    constructor(private store: Store<AppState>,
                private newsService: NewsService,
                private eventId: number,
                private newsId: number) {
        super();
    }

    protected loadFromDatabase(): Observable<News> {
        return this.store.pipe(select(selectAnEventNewsById, this.newsId));
    }

    protected saveNetworkResult(news: News) {
        const eventNews: EventNews = {...news, eventId: this.eventId};
        this.store.dispatch(upsertOneEventNews({ eventNews }));
    }

    protected shouldFetch(dataFromDatabase: News): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.FIVE_MINUTES,
                resourceId: this.constructor.name + '-' + this.eventId + '-' + this.newsId
            }
        };
    }

    protected startNetworkCall(): Observable<News> {
        return this.newsService.getNews(this.eventId, this.newsId);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_NEWS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId + '-' + this.newsId);
    }
}
