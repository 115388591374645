import {AppState} from '../../index';
import {adapter} from './staticPageRooms.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {Room} from '../../../api';

export const staticPageRoomsKey = 'staticPageRooms';

export const selectStaticPageRoomsState =
    createFeatureSelector<AppState, EntityState<Room>>(staticPageRoomsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllStaticPageRooms = createSelector(
    selectStaticPageRoomsState,
    (state: EntityState<Room>) => selectAll(state)
);

export const selectStaticPageRoomsById = createSelector(
    selectStaticPageRoomsState,
    (state: EntityState<Room>, id: number) =>
        selectAll(state).find(staticPageRoom => staticPageRoom.id === id)
);
