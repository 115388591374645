import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    handleError(error: any): void {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}