<app-header [titleText]="(cicgNews$ | async)?.title" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/news"></app-header>

<div class="page-header">
    <div *appShowWhen="[isDesktop]" [ngClass]="(cicgNews$ | async)?.image && (cicgNews$ | async)?.image.length > 0 ?
            'header-image header-image--desktop' : 'header-image default_bg header-image--desktop'"
         [ngStyle]="{'background-image': ((cicgNews$ | async)?.image && (cicgNews$ | async)?.image.length > 0 ? 'url(' + (cicgNews$ | async)?.image + ')' : '' )}">
    </div>
    <div *appShowWhen="[isMobile]" [ngClass]="(cicgNews$ | async)?.image && (cicgNews$ | async)?.image.length > 0 ?
            'header-image header-image--mobile' : 'header-image no-header'"
         [ngStyle]="{'background-image': ((cicgNews$ | async)?.image && (cicgNews$ | async)?.image.length > 0 ? 'url(' + (cicgNews$ | async)?.image + ')' : '' )}">
    </div>
</div>
<ion-content class="practical_info_page"
             scroll-events>
    <div class="news-detail-page page-header"
         *ngIf="(cicgNews$ | async) != null">

        <div [ngClass]="(cicgNews$ | async)?.image && (cicgNews$ | async)?.image.length > 0 ? 'main-content' : 'main-content main-no-header'">
            <div class="ion-padding">
                <ion-item lines="full" class="ion-margin-bottom">
                    <ion-label class="ion-text-wrap">
                        <ion-text color="primary">
                            <h4>{{(cicgNews$ | async)?.date}}</h4>
                        </ion-text>
                        <h1>{{(cicgNews$ | async)?.title}}</h1>
                    </ion-label>
                </ion-item>
                <ion-item id="news-detail-text"
                          class="ion-text-wrap">
                    <div appLinkParser [innerHTML]="(cicgNews$ | async)?.body"></div>
                </ion-item>
            </div>
        </div>
    </div>
    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noNewsDataLoading>
        <div class="main-content">
            <div class="ion-padding">
                <ion-item lines="full" class="ion-margin-bottom">
                    <ion-label class="ion-text-wrap">
                        <ion-skeleton-text animated style="width: 30%; line-height: 16px;"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 50%; line-height: 18px;"></ion-skeleton-text>
                    </ion-label>
                </ion-item>
                <div class="ion-padding">
                    <p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
                    <p><ion-skeleton-text animated style="width: 90%"></ion-skeleton-text></p>
                    <p><ion-skeleton-text animated style="width: 95%"></ion-skeleton-text></p>
                </div>
            </div>
        </div>
    </app-loading-no-data>
</ion-content>
