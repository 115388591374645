import {Event} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as EventActions from './event.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export function selectId(event: Event): number {
    return event.id;
}

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({
    selectId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<Event> = adapter.getInitialState();

const allEventsReducer = createReducer(
  initialState,
  on(EventActions.upsertEventsInAllEvents, (state, { events }) => upsertMany(events, state)),
  on(EventActions.upsertAnEventInAllEvents, (state, { event }) => adapter.upsertOne(event, state)),
  on(EventActions.clearEventsInAllEvents, (state) => removeAll(state)),
  on(EventActions.clearEventsInAnyEvents, (state) => removeAll(state))
);

const publishedEventsReducer = createReducer(
    initialState,
    on(EventActions.upsertEventsInPublishedEvents, (state, { events }) => upsertMany(events, state)),
    on(EventActions.clearEventsInPublishedEvents, (state) => removeAll(state)),
    on(EventActions.clearEventsInAnyEvents, (state) => removeAll(state))
);

const archivedEventsReducer = createReducer(
    initialState,
    on(EventActions.upsertEventsInArchivedEvents, (state, { events }) => upsertMany(events, state)),
    on(EventActions.clearEventsInArchivedEvents, (state) => removeAll(state)),
    on(EventActions.clearEventsInAnyEvents, (state) => removeAll(state))
);

export function allReducer(state: EntityState<Event> | undefined, action: Action) {
    return allEventsReducer(state, action);
}

export function publishedReducer(state: EntityState<Event> | undefined, action: Action) {
    return publishedEventsReducer(state, action);
}

export function archivedReducer(state: EntityState<Event> | undefined, action: Action) {
    return archivedEventsReducer(state, action);
}

function removeAll(state: EntityState<Event>) {
    return adapter.removeAll(state);
}

function upsertMany(events: Event[], state: EntityState<Event>) {
    return adapter.upsertMany(events, state);
}
