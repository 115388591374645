import {Subscription} from 'rxjs';
import {FilesRepository} from '../../repositories/files/files.repository';
import {Media} from '../../api';
import {consume} from '../../common/rxjs/operators';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';

export abstract class AbstractFileOpener {
    protected fileDownloadSubscription: Subscription;

    protected constructor(private filesRepository: FilesRepository,
                          protected resourceStatusService: ResourceStatusService) {
        this.fileDownloadSubscription = Subscription.EMPTY;
    }

    protected openFile(eventId: number, file: Media) {
        // we overwrite the fileDownload$ every time, in any case each file download
        // operation completes and closes the observable!! (see FilesRepository)
        if (!this.fileDownloadSubscription.closed) {
            this.fileDownloadSubscription.unsubscribe();
        }

        this.fileDownloadSubscription = this.filesRepository.downloadAndOpenFile(eventId, file)
            .pipe(
                consume(this.resourceStatusService)
            )
            .subscribe();
    }
}
