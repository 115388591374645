<div>
    <h1>{{"ERROR" | translate}}</h1>
    <p>{{errorMessage}}</p>
    <ion-button
            *ngIf="showClose"
            fill="clear"
            (click)="dismissError()">
        {{"CLOSE_BUTTON" | translate}}
    </ion-button>
    <ion-button
            *ngIf="showRetry"
            fill="clear"
            (click)="retry()">
        {{"RETRY_BUTTON" | translate}}
    </ion-button>
</div>
