<app-header titleKey="NOTIFICATION_DETAILS_LABEL"
            [menuIconEnabled]="false"
            [backArrowEnabled]="true"
            defaultBackHref="notifications">
</app-header>

<ion-content id="notification-details-page">
    <div class="ion-padding"
         *ngIf="(notification$ | async) != null">
        <ion-item (click)="openEvent()" lines="full">
            <ion-avatar slot="start" class="item-icon-square">
                <img *ngIf="(notification$ | async)?.event_logo; else initial"
                     [src]="(notification$ | async)?.id === -1 ?
                        (notification$ | async)?.event_logo :
                        environment.storage_base_path + (notification$ | async)?.event_logo">
                <ng-template #initial>
                    <div class="item-icon-image">
                        <div class="initials-container ion-text-center">
                            <div class="initials">
                        <span>
                            {{(notification$ | async)?.event_title ? (notification$ | async)?.event_title.substr(0, 2) : 'EV'}}
                        </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ion-avatar>
            <ion-label>
                <h1 class="ion-text-wrap">{{(notification$ | async)?.event_title}}</h1>
                <p>{{date}}</p>
            </ion-label>

        </ion-item>

        <ion-item>
            <h1 id="title">{{(notification$ | async)?.object}}</h1>
        </ion-item>
        <ion-item [ngClass]="(notification$ | async)?.medias?.length > 0 ? '' : 'no-border'">
            <div appLinkParser id="long-text" [innerHtml]="(notification$ | async)?.content"></div>
        </ion-item>

        <div *ngIf="(notification$ | async)?.medias && (notification$ | async)?.medias?.length > 0">
            <div class="ion-padding-start ion-padding-end">
                <h2>{{ 'FILE_CAROUSEL_TITLE' | translate }}</h2>
            </div>
            <app-file-carousel id="file-carousel-container"
                               [files]="(notification$ | async)?.medias"
                               (itemClick)="onFileCarouselClick($event)"
                               [eventId]="(notification$ | async)?.event_id">
            </app-file-carousel>
        </div>
    </div>

    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noNotificationDataLoading>
        <div class="ion-padding">
            <ion-item lines="full">
                <ion-avatar slot="start" class="item-icon-square">
                    <ion-skeleton-text animated></ion-skeleton-text>
                </ion-avatar>
                <ion-label>
                    <h1 class="ion-text-wrap">
                        <ion-skeleton-text animated style="width: 50%; line-height: 18px;">
                        </ion-skeleton-text>
                    </h1>
                    <p>
                        <ion-skeleton-text animated style="width: 30%">
                        </ion-skeleton-text>
                    </p>
                </ion-label>
            </ion-item>
            <ion-item>
                <h1 style="width: 100%">
                    <ion-skeleton-text animated style="width: 80%; line-height: 24px;">
                    </ion-skeleton-text>
                </h1>
            </ion-item>
            <ion-item>
                <div style="width: 100%">
                    <ion-skeleton-text animated style="width: 90%">
                    </ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 95%">
                    </ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 80%">
                    </ion-skeleton-text>
                </div>
            </ion-item>
        </div>
    </app-loading-no-data>
</ion-content>
