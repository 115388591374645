<ion-list [ngClass]="classRounded ? 'person-card' : ''">
    <ion-item *ngFor="let item of items;">
        <ion-avatar slot="start" *ngIf="classRounded; else square">
            <ion-skeleton-text animated style="width: 55px; height: 55px"></ion-skeleton-text>
        </ion-avatar>
        <ng-template #square>
            <ion-thumbnail slot="start">
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>
        </ng-template>
        <ion-label>
            <p *ngFor="let textRow of item">
                <ion-skeleton-text animated [ngStyle]="{'width': textRow}"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-list>
