import {ParticipantSetting} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as EventSettingsActions from './event-setting.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface EventParticipantSetting extends ParticipantSetting {
    eventId: number;
}

export function selectId(eventParticipantSetting: EventParticipantSetting): number {
    return eventParticipantSetting.eventId;
}

export const adapter: EntityAdapter<EventParticipantSetting> =
    createEntityAdapter<EventParticipantSetting>({
        selectId,
        sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
    });

export const initialState: EntityState<EventParticipantSetting> = adapter.getInitialState();

const eventSettingsReducer = createReducer(
    initialState,
    on(EventSettingsActions.upsertCurrentUserEventSettings, (state, { eventParticipantSettings }) =>
        adapter.upsertOne(eventParticipantSettings, state)),
    on(EventSettingsActions.clearCurrentUserEventSettingsForAllEvents, (state) =>
        adapter.removeAll(state))
);

export function reducer(state: EntityState<EventParticipantSetting> | undefined, action: Action) {
    return eventSettingsReducer(state, action);
}
