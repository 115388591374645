import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {
    GdprService,
    LanguageCode,
    Media,
    NewPasswordDataRequest,
    PasswordAuthorization,
    PasswordResetDataRequest,
    User, UserPushToken,
    UsersService
} from '../../api';
import {LoadUser} from './loadUser';
import {Observable, of} from 'rxjs';
import {Resource} from '../../common/repository/resource';
import {RequestResetPassword} from './requestResetPassword';
import {clearUser, setUserLang} from '../../store/reducers/user/user.actions';
import {
    isGuest,
    isLoggedIn, LoggedOutEvent, notifyLoggedIn,
    notifyLoggedOut,
    selectUser,
    selectUserLang
} from '../../store/reducers/user/user.selectors';
import {SetUserLang} from './setUserLang';
import {switchMap, take} from 'rxjs/operators';
import {completeOnResourceSuccessOrError} from '../../common/rxjs/operators';
import {ChangePassword} from './changePassword';
import {DownloadUserData} from './downloadUserData';
import {FilesRepository} from '../files/files.repository';
import {DeleteUserData} from './deleteUserData';
import {ResetPassword} from './resetPassword';
import {CheckTermsAndConditions} from './checkTermsAndConditions';
import {AcceptTermsAndConditions} from './acceptTermsAndConditions';
import {SendFirebaseTokenToServer} from './sendFirebaseTokenToServer';

@Injectable({
    providedIn: 'root'
})
export class UserRepository {

    constructor(private store: Store<AppState>,
                private userService: UsersService,
                private gdprService: GdprService,
                private filesRepository: FilesRepository) {
    }

    loadUser(): Observable<Resource<User>> {
        const loadUser = new LoadUser(this.store, this.userService);
        return loadUser.call();
    }

    requestResetPassword(email: string): Observable<Resource<boolean>> {
        const requestResetPassword = new RequestResetPassword(this.store, this.userService, email);
        return requestResetPassword.call();
    }

    resetPassword(token: string, passwordResetDataRequest: PasswordResetDataRequest): Observable<Resource<boolean>> {
        const resetPassword = new ResetPassword(this.store, this.userService, token, passwordResetDataRequest);
        return resetPassword.call();
    }

    getCurrentUser(): Observable<User> {
        return this.store.pipe(select(selectUser));
    }

    getCurrentUserLang(): Observable<string> {
        return this.store.pipe(select(selectUserLang));
    }

    isLoggedIn(): Observable<boolean> {
        return this.store.pipe(select(isLoggedIn));
    }

    isGuest(): Observable<boolean> {
        return this.store.pipe(select(isGuest));
    }

    clearUser(): void {
        return this.store.dispatch(clearUser());
    }

    setCurrentUserLang(languageCode: LanguageCode): Observable<Resource<any>> {
        return this.isLoggedIn().pipe(
            take(1),
            switchMap((isLogged: boolean) => {
                    if (isLogged) {
                        const setUserLangObservable = new SetUserLang(this.store, this.userService, languageCode);
                        return setUserLangObservable.call().pipe(
                            completeOnResourceSuccessOrError()
                        );
                    }
                    else {
                        this.store.dispatch(setUserLang( { languageCode }));
                        return Resource.simulateResourceCycleWithEmptyData();
                    }
                }
            )
        );
    }

    changeCurrentUserPassword(newPasswordData: NewPasswordDataRequest): Observable<Resource<any>> {
        const changePassword = new ChangePassword(this.userService, newPasswordData);
        return changePassword.call().pipe(
            completeOnResourceSuccessOrError()
        );
    }

    downloadCurrentUserData(): Observable<Resource<void>> {
        const dataMedia: Media = {description: '', id: 0, path: '', type: '', title: 'data.txt'};
        const downloadUserData = new DownloadUserData(this.gdprService);
        return downloadUserData.call().pipe(
            this.filesRepository.processAndOpenFile(dataMedia)
        );
    }

    deleteCurrentUserData(passwordAuthorization: PasswordAuthorization): Observable<Resource<void>> {
        const deleteUserData = new DeleteUserData(this.gdprService, passwordAuthorization);
        return deleteUserData.call();
    }

    observeLogout(): Observable<LoggedOutEvent> {
        return this.store.pipe(notifyLoggedOut);
    }

    observeLogin(): Observable<void> {
        return this.store.pipe(notifyLoggedIn);
    }

    checkTermsAndConditions(): Observable<Resource<boolean>> {
        return this.getCurrentUser().pipe(
            switchMap((user: User) => {
                if (user.email && user.account_status === 0) {
                    const checkTermsAndConditions = new CheckTermsAndConditions(this.gdprService);
                    return checkTermsAndConditions.call();
                }
                else {
                    return of(Resource.success(CheckTermsAndConditions.name, true)); // we return that the T&C are fine
                }
            })
        );
    }

    acceptTermsAndConditions(): Observable<Resource<void>> {
        const acceptTermsAndConditions = new AcceptTermsAndConditions(this.gdprService);
        return acceptTermsAndConditions.call();
    }

    sendFirebaseTokenToServer(pushToken: UserPushToken): Observable<Resource<void>> {
        const sendFirebaseTokenToServer = new SendFirebaseTokenToServer(this.userService, pushToken);
        return sendFirebaseTokenToServer.call();
    }

}
