<app-header titleKey="TOURISM_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>

    <div id="tourism-api-page" class="practical_info_page tourism-api-page">
        <ion-list class="attraction-list ion-padding-top"
                  *ngIf="attractions$ && (attractions$ | async)?.length > 0">
            <ion-item id="{{'attraction-list-item-' + i}}"
                      *ngFor="let attraction of (attractions$ | async); index as i;"
                      (click)="openAttraction(attraction)" detail>
                <ion-avatar slot="start">
                    <img *ngIf="attraction.banners.length > 0; else initial" [src]="attraction.banners[0]">
                    <ng-template #initial>
                        <div class="item-icon-image">
                            <div class="initials-container ion-text-center">
                                <div class="initials">
                                    <span>
                                        {{attraction.name ? attraction.name.substr(0, 2) : 'AT'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                </ion-avatar>
                <ion-label>
                    <h2 class="ion-text-nowrap ellipsis">
                        {{attraction.name}}
                    </h2>
                </ion-label>
            </ion-item>
            <ion-item class="no-margin-left" (click)="openLink()" lines="none">
                <h5 class="item-label">
                    {{'MORE_INFO_TEXT' | translate}}
                    <ion-text color="primary">https://www.geneve.com</ion-text>
                </h5>
            </ion-item>
        </ion-list>

        <span id="practical-information-empty-list"
              *ngIf="attractions$ && (attractions$ | async)?.length === 0">
            {{'API_NO_RESULTS' | translate}}
        </span>

        <!--  LOADING TEMPLATE  -->
        <app-loading-no-data #noTourismDataLoading>
            <app-loading-list-no-data></app-loading-list-no-data>
        </app-loading-no-data>
    </div>
</ion-content>
