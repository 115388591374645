import {NgModule} from '@angular/core';
import {PracticalInformationRoutingModule} from './practical-information-routing.module';
import {PracticalInformationListPageModule} from './list/practical-information-list.module';
import {TpgApiPageModule} from './tpg-api/tpg-api.module';
import {IframePageModule} from './iframe/iframe.module';
import {CffApiPageModule} from './cff-api/cff-api.module';
import {RestaurantApiPageModule} from './restaurant-api/restaurant-api.module';
import {NewsApiPageModule} from './news-api/news-api.module';
import {RoomsApiPageModule} from './rooms-api/rooms-api.module';
import {TourismApiPageModule} from './tourism-api/tourism-api.module';

@NgModule({
  imports: [
    PracticalInformationListPageModule,
    TpgApiPageModule,
    CffApiPageModule,
    RestaurantApiPageModule,
    NewsApiPageModule,
    RoomsApiPageModule,
    TourismApiPageModule,
    IframePageModule,
    PracticalInformationRoutingModule,
  ]
})
export class PracticalInformationModule {}
