import {Observable} from 'rxjs';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {ParticipantSetting, SettingsService} from '../../../api';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {EventParticipantSetting} from '../../../store/reducers/eventSettings/event-setting.reducer';
import {upsertCurrentUserEventSettings} from '../../../store/reducers/eventSettings/event-setting.actions';

export class UpdateEventParticipantSettingsByEventId extends JustNetworkResource<any, ParticipantSetting> {
    constructor(private store: Store<AppState>,
                private settingsService: SettingsService,
                private eventId: number,
                private participantSetting: ParticipantSetting) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.settingsService.updateMySettings(this.eventId, this.participantSetting);
    }

    protected onNetworkSuccess(dataFromNetwork: any): ParticipantSetting {
        const eventParticipantSettings: EventParticipantSetting = {
            ...this.participantSetting,
            eventId: this.eventId
        };
        this.store.dispatch(upsertCurrentUserEventSettings( { eventId: this.eventId, eventParticipantSettings }));
        return this.participantSetting;
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EDIT_SETTINGS_KO_TOAST', false);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }
}
