import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {NotificationsPage} from './notifications.page';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponentModule} from '../../common/ui/app-header/header.component.module';
import {NotificationCardComponentModule} from '../../components/notification-card/notification-card.component.module';
import {NotificationsRoutingModule} from './notifications-routing.module';
import {NotificationDetailPageModule} from './notification-detail/notification-detail.module';
import {UiStateManagementModule} from '../../common/ui/state-management/ui.state.management.module';
import {EmptyNotificationCardComponentModule} from '../../components/empty-notification-card/empty-notification-card.component.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild(),
        HeaderComponentModule,
        NotificationCardComponentModule,
        NotificationDetailPageModule,
        NotificationsRoutingModule,
        UiStateManagementModule,
        EmptyNotificationCardComponentModule
    ],
  declarations: [NotificationsPage]
})
export class NotificationsPageModule {}
