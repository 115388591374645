import {Observable} from 'rxjs';
import {GdprService, PasswordAuthorization} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class DeleteUserData extends JustNetworkResource<any, any> {
    constructor(private gdprService: GdprService,
                private passwordAuthorization: PasswordAuthorization) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.gdprService.deleteUser(this.passwordAuthorization);
    }

    protected onNetworkSuccess(dataFromNetwork: any): any {
        // the API implementation returns a 200 ok even if the body of the response contains an error.
        // So we have to check here the content of the body an if it is error we should throw an error.
        if (dataFromNetwork.code === '403') {
            throw new HttpErrorResponse({status: 403});
        }
        return dataFromNetwork;
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('DELETE_DATA_KO_TOAST', false);
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('DELETE_DATA_PASSWORD_KO_TOAST', false);
    }
}
