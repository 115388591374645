<div class="event-div-menu">
  <ion-card class="menu-card">
    <ion-grid id="menu-event-card-grid">
      <ion-row class="event-card-content">
        <ion-col class="ion-no-padding" size="2">
          <div [ngClass]="myevent.organisation_logo_url  === '/storage/' || myevent.organisation_logo_url  === null || myevent.organisation_logo_url  === '' ? 'event-card-logo-image has-no-logo' : 'event-card-logo-image'" [ngStyle]="{'background-image': 'url(' + basePath +  (myevent.organisation_logo_url ? myevent.organisation_logo_url : '') + ')'}">
            <ion-badge class="event-card-ongoing-badge-title" *ngIf="isCurrent() && isPublished() && !liveConference || isCurrent() && isPublished() && !streamVideo"> <img alt="clock icon" class="badge-icon-title" src="./assets/imgs/notification_clock.svg"/></ion-badge>
            <ion-badge class="event-card-ongoing-badge-title event-card-ongoing-badge-unpublished" *ngIf="!isPublished()"> <img alt="clock icon" class="badge-icon-title" src="./assets/imgs/notification_clock.svg"/></ion-badge>
            <ion-badge class="event-card-ongoing-badge-title" *ngIf="isCurrent() && isPublished() && liveConference || isCurrent() && isPublished() && streamVideo"> <img alt="camera icon" class="badge-icon-title" src="./assets/imgs/video_camera.svg"/></ion-badge>
            <div  *ngIf="myevent.organisation_logo_url  === '/storage/' || myevent.organisation_logo_url  === null || myevent.organisation_logo_url  === ''" class="initials-container ion-text-center">
              <div class="initials"><span>{{myevent.organisation ? myevent.organisation.substr(0,2) : 'L'}}</span></div>
            </div>
          </div>
        </ion-col>
        <ion-col size="7" class="event-card-title event-card-title-side ion-no-padding">
          <h3 class="event-card-title-big event-card-title-big-side">{{myevent.title}}</h3>
        </ion-col>
        <ion-col class="ion-no-padding" size="3">
          <ion-grid id="date-grid">
            <ion-row class="ion-align-items-center">
              <ion-col size="5" class="event-card-date ion-text-center ion-no-padding">
                <h1 class="event-card-day">{{startDay}}</h1>
                <h5 class="event-card-month">{{startMonth}}.</h5>
              </ion-col>
              <ion-col size="1" class="start-date-separator">
              </ion-col>
              <ion-col size="5" class="event-card-end-date ion-text-center ion-no-padding">
                <h1 class="event-card-day">{{endDay}}</h1>
                <h5 class="event-card-month">{{endMonth}}.</h5>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</div>
