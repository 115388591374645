import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthSessionService} from '../../api';
import {Resource} from '../../common/repository/resource';
import {Login} from './login';
import {AuthSession} from '../../api/model/authSession';
import {clearAuthSession, initAuthSession} from '../../store/reducers/auth/authSession.actions';
import {
    isValidAuthSession,
    notifyInvalidatedAuthSession,
    selectAccessToken,
    selectRefreshToken
} from '../../store/reducers/auth/authSession.selectors';
import {AppState} from '../../store';


@Injectable({
    providedIn: 'root'
})

export class AuthSessionRepository {

    constructor(private store: Store<AppState>,
                private authSessionService: AuthSessionService) {}

    login(user: string, password: string): Observable<Resource<boolean>> {
        const login = new Login(this.store, this.authSessionService, user, password);
        return login.call();
    }

    getAccessToken(): Observable<string> {
        return this.store.pipe(select(selectAccessToken));
    }

    getRefreshToken(): Observable<string> {
        return this.store.pipe(select(selectRefreshToken));
    }

    setAuthSession(authSession: AuthSession) {
        this.store.dispatch(initAuthSession({ authSession }));
    }

    isValidaAuthSession(): Observable<boolean> {
        return this.store.pipe(select(isValidAuthSession));
    }

    clearAuthSession() {
        this.store.dispatch(clearAuthSession());
    }

    observeInvalidateAuthSession(): Observable<void> {
        return this.store.pipe(notifyInvalidatedAuthSession);
    }
}
