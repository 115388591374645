import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loading-list-no-data',
    templateUrl: './loading-list-no-data.component.html',
    styleUrls: ['./loading-list-no-data.component.scss'],
})
export class LoadingListNoDataComponent implements OnInit {
    private static randomWidth(): string {
        return Math.floor(Math.random() * (95 - 40 + 1)) + 40 + '%';
    }

    public items: string[][];
    public classRounded: boolean;

    private internalNumberOfItems: number;
    private internalNumberOfTextRows: number;

    constructor() {
        this.internalNumberOfItems = Math.floor((Math.random() * (6 - 3 + 1)) + 3);
        this.internalNumberOfTextRows = 1;
        this.classRounded = false;
        this.setItems();
    }

    @Input()
    set numberOfItems(numberOfItems: number) {
        this.internalNumberOfItems = numberOfItems;
        this.setItems();
    }

    @Input()
    set numberOfTextRows(numberOfTextRows: number) {
        this.internalNumberOfTextRows = numberOfTextRows;
        this.setItems();
    }

    @Input()
    set rounded(isRounded: boolean) {
        this.classRounded = isRounded;
    }

    ngOnInit() {}

    private setItems() {
        this.items = [];
        for (let i = 0; i < this.internalNumberOfItems; i++) {
            const textRows = [];
            for (let j = 0; j < this.internalNumberOfTextRows; j++) {
                textRows.push(LoadingListNoDataComponent.randomWidth());
            }
            this.items.push(textRows);
        }
    }
}
