import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {EventDetailPage} from './event-detail.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../../common/ui/directives/directives.module';
import {EventDetailRoutingModule} from './event-detail-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderComponentModule,
        TranslateModule,
        DirectivesModule,
        EventDetailRoutingModule
    ],
  declarations: [EventDetailPage]
})
export class EventDetailPageModule {}
