import {Observable} from 'rxjs';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {ParticipantProfileUpdate, ProfilesService} from '../../../api';

export class UpdateEventParticipantForCurrentUser extends JustNetworkResource<any, any> {
    constructor(private profilesService: ProfilesService,
                private eventId: number,
                private profileUpdate: ParticipantProfileUpdate) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.profilesService.updateMyProfile(this.eventId, this.profileUpdate);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EDIT_PROFILE_KO_TOAST', false);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }
}
