import {MbscModule} from '@mobiscroll/angular';
import {FormsModule} from '@angular/forms';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';

import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {storageSync, StorageSyncEffects} from './common/store/capacitor';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';

import {BASE_PATH, BASE_PATH_AUTH, LanguageCode} from './api';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor, TokenInterceptor} from './common/api/interceptors/token.interceptor';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PracticalInformationModule} from './pages/practical-information/practical-information.module';
import {MenuEventCardComponentModule} from './components/menu-event-card/menu-event-card.component.module';
import {ENV} from '../environments/variables';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {NotificationsPageModule} from './pages/notifications/notifications.module';
import {UserRepository} from './repositories/user/user.repository';
import {Observable, of} from 'rxjs';
import {Resource} from './common/repository/resource';
import {Status} from './common/repository/status';
import {HydrateRepository} from './repositories/hydrate/hydrate.repository';
import {userKey} from './store/reducers/user/user.selectors';
import {staticPagesKey} from './store/reducers/staticPages/staticPage.selectors';
import {staticPageRoomsKey} from './store/reducers/staticPageRooms/staticPageRooms.selectors';
import {notificationsKey} from './store/reducers/notifications/notification.selectors';
import {hydratedKey} from './store/reducers/hydrate/hydrate.selectors';
import {allEventsKey, archivedEventsKey, publishedEventsKey} from './store/reducers/events/event.selectors';
import {authSessionKey} from './store/reducers/auth/authSession.selectors';
import {EventsPageModule} from './pages/events/events.module';
import {eventNewsKey} from './store/reducers/eventNews/event-news.selectors';
import {PasswordModule} from './pages/password/password.module';
import {LoginComponentModule} from './components/login/login.component.module';
import {APP_BASE_HREF, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {CguPcaComponentModule} from './components/cgu-pca/cgu-pca.component.module';
import {eventSessionsKey} from './store/reducers/eventSessions/event-session.selectors';
import {
    eventParticipantsForCurrentUserKey,
    eventParticipantsKey
} from './store/reducers/eventParticipants/event-participant.selectors';
import {eventSettingsKey} from './store/reducers/eventSettings/event-setting.selectors';
import {CookieLawModule} from 'angular2-cookie-law';
import {DirectivesModule} from './common/ui/directives/directives.module';
import {staticPageNewsKey} from './store/reducers/staticPageNews/staticPageNews.selectors';
import {PublicSurveyModule} from './pages/public-survey/public-survey.module';
import {SplashPageModule} from './pages/splash/splash.module';
import {UiStateManagementModule} from './common/ui/state-management/ui.state.management.module';
import {BrandedTranslateHttpLoader} from './common/utils/translation/branded-translate-http-loader';
import { ErrorHandler } from '@angular/core';
import {SentryErrorHandler} from './sentry-error.handler';


export function onSyncError(err) {
    console.log(err);
}

// Rehydrate strategy
export const storageSyncReducer = storageSync({
    keys: [
        authSessionKey,
        staticPagesKey,
        userKey,
        allEventsKey,
        publishedEventsKey,
        archivedEventsKey,
        notificationsKey,
        eventNewsKey,
        staticPageNewsKey,
        staticPageRoomsKey,
        eventParticipantsKey,
        eventParticipantsForCurrentUserKey,
        eventSessionsKey,
        eventSettingsKey,
    ],   // Only sync the `collection` state
    ignoreActions: [], // Don't sync when these actions occur
    hydratedStateKey: hydratedKey, // Add this key to the state
    onSyncError      // If a sync fails
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
    return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [storageMetaReducer];

export function createTranslateLoader(http: HttpClient) {
    return new BrandedTranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initApp(hydrateRepository: HydrateRepository, userRepository: UserRepository, translate: TranslateService) {
    // the fallback lang is always english
    translate.setDefaultLang('en');

    return () => new Promise<any>((resolve: any) => {
        // we have to subscribe to hydrate events to be sure that the store got restored from local storage
        hydrateRepository.getHydrateStatus().pipe(
            // we only keep going if the hydrate is successful
            filter((hydrate: boolean) => {
                return hydrate;
            }),
            // we get the current lang
            switchMap(() => {
                return userRepository.getCurrentUserLang();
            }),
            // we take the lang once to check it is set
            take(1),
            switchMap((currentLang: string): Observable<string> => {
                // if the lang is not set
                if (currentLang == null) {
                    // we get the browser lang to set it as initial user lang
                    let lang = translate.getBrowserLang();
                    if (lang === undefined) {
                        lang = 'en'; // if we do not even have the browser lang we force english
                    }

                    const languageCode: LanguageCode = { lang_code: lang };
                    return userRepository.setCurrentUserLang(languageCode).pipe(
                        filter((resource: Resource<any>) => {
                            return resource.status === Status.SUCCESS;
                        }),
                        map(() => {
                            return languageCode.lang_code;
                        })
                    );
                }
                else {
                    // we set the current lang as the one to use
                    return of(currentLang);
                }
            }),
            switchMap((langToUse: string) => {
                return translate.use(langToUse);
            })
        ).subscribe(() => {
            resolve(null);
        }, err => {
            console.error(`Problem with language initialization.'`);
            console.error(JSON.stringify(err));
        });
    });
}

// @ts-ignore
@NgModule({
    declarations: [AppComponent],
    imports: [
        MbscModule,
        FormsModule,
        PublicSurveyModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        EventsPageModule,
        PracticalInformationModule,
        PasswordModule,
        NotificationsPageModule,
        LoginComponentModule,
        SplashPageModule,
        CguPcaComponentModule,
        MenuEventCardComponentModule,
        AppRoutingModule,
        StoreModule.forRoot(
            reducers,
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictStateSerializability: true,
                    strictActionSerializability: false,
                },
                metaReducers,
            }
        ),
        EffectsModule.forRoot([StorageSyncEffects]),
        // To see the redux dev tool in browser
        // must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        CookieLawModule,
        DirectivesModule,
        UiStateManagementModule,
    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [HydrateRepository, UserRepository, TranslateService]},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: APP_BASE_HREF, useValue : '/'},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: ENV, useValue: environment},
        {provide: BASE_PATH, useValue: environment.base_path},
        {provide: BASE_PATH_AUTH, useValue: environment.base_path_auth},
        {provide: ErrorHandler, useClass: SentryErrorHandler},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
