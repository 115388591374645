<!--<app-header titleKey="EVENTS_MENU"></app-header>-->
<app-header titleKey="EVENTS_MENU"
            [menuIconEnabled]="true"
            [backArrowEnabled]="false"
            [adminIconEnabled]="false"
            [profileIconEnabled]="false"></app-header>

<ion-tabs class="event-tabs">
    <ion-tab-bar *ngIf="isDesktop" slot="top">
        <ion-tab-button tab="upcoming" layout="icon-hide">
            <ion-label>{{'UPCOMING_TAB_INFO'| translate}}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="archived" layout="icon-hide">
            <ion-label>{{'ARCHIVE_TAB_PROGRAM' | translate}}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
    <ion-tab-bar *ngIf="!isDesktop" slot="bottom">
        <ion-tab-button tab="upcoming" layout="icon-hide">
            <ion-label>{{'UPCOMING_TAB_INFO'| translate}}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="archived" layout="icon-hide">
            <ion-label>{{'ARCHIVE_TAB_PROGRAM' | translate}}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>
