import {AppState} from '../../index';
import {adapter} from './staticPage.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {StaticPage} from '../../../api';

export interface OrderedStaticPage extends StaticPage {
    rank: number;
}

export const staticPagesKey = 'staticPages';

export const selectStaticPagesState =
    createFeatureSelector<AppState, EntityState<OrderedStaticPage>>(staticPagesKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllStaticPages = createSelector(
    selectStaticPagesState,
    (state: EntityState<OrderedStaticPage>): OrderedStaticPage[] => selectAll(state)
        .filter((staticPage: OrderedStaticPage) => staticPage.rank >= 0)
        .sort((a: OrderedStaticPage, b: OrderedStaticPage) => a.rank - b.rank)
);

export const selectStaticPageBySlug = createSelector(
    selectAllStaticPages,
    (state: OrderedStaticPage[], slug: string): OrderedStaticPage =>
        state.find(staticPage => staticPage.slug === slug)
);
