import {Observable} from 'rxjs';
import {DownloadService, Media} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class GetFile extends JustNetworkResource<Blob, Blob> {
    constructor(private downloadService: DownloadService,
                private eventId: number,
                private media: Media) {
        super(true);
    }

    protected startNetworkCall(): Observable<Blob> {
        return this.downloadService.getFile(this.eventId, this.media.file.path, this.media.file.file_id);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('DOWNLOAD_FILE_ERROR', false);
    }
}
