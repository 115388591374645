<app-header [titleKey]="isMainList ? 'PRACTICAL_INFORMATION_LABEL' : null"
            [titleText]="title ? title : null"
            [menuIconEnabled]="isMainList"
            [backArrowEnabled]="!isMainList"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <div id="practical-information-page" class="practical_info_page">
        <ion-list id="practical-information-list" *ngIf="(staticPages$ | async) && (staticPages$ | async).length > 0">
            <ion-item id="{{'practical-information-list-item-' + i}}"
                      class="no-margin-left"
                      *ngFor="let staticPage of staticPages$ | async; index as i;"
                      (click)="staticPageClick(staticPage)" detail>
                <ion-avatar slot="start" class="with-fixed-size">
                    <div *ngIf="(staticPage.cover_url  === '/storage/' ||
                                    staticPage.cover_url  === null ||
                                    staticPage.cover_url  === ''); else elseBlock"
                          class="initials-container ion-text-center">
                        <div class="initials">
                            <span [ngClass]="isAndroidOriOS ? 'span-sub' : ''">
                                {{ translate.currentLang === 'fr' ? (staticPage.title_fr ?
                                staticPage.title_fr.substr(0,2) : 'PI') :
                                (staticPage.title ? staticPage.title.substr(0,2) : 'IP')}}
                            </span>
                        </div>
                    </div>
                    <ng-template #elseBlock>
                        <img [src]="environment.storage_base_path + staticPage.cover_url"
                             alt="static-page-icon">
                    </ng-template>
                </ion-avatar>
                <ion-label>
                    <h2 class="ion-text-nowrap ellipsis">{{ translate.currentLang === 'fr' ? staticPage.title_fr : staticPage.title }}</h2>
                </ion-label>
            </ion-item>
        </ion-list>
        <span id="practical-information-empty-list" *ngIf="(staticPages$ | async) && (staticPages$ | async).length === 0" >
        </span>
    </div>
    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noStaticPagesDataLoading>
        <app-loading-list-no-data></app-loading-list-no-data>
    </app-loading-no-data>
</ion-content>
