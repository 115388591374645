import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
import {Network} from '@capacitor/network';
import {TranslateService} from '@ngx-translate/core';
import {ToastController} from '@ionic/angular';
import {getToastOptionInfo} from '../../../../../pages/common/constants';

@Component({
  selector: 'app-offline-button',
  templateUrl: './offline-button.component.html',
  styleUrls: ['./offline-button.component.scss'],
})
export class OfflineButtonComponent implements OnInit, OnDestroy {

  public networkStatus;
  private networkListener: PluginListenerHandle;
  private toast;

  constructor(private translateService: TranslateService,
              private toastController: ToastController,
              private zone: NgZone) {
    this.networkStatus =  {
      connected: false,
      connectionType: 'unknown'
    };
  }

  async ngOnInit() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
        this.zone.run(() => {
            this.networkStatus = status;
        });
    });
    await this.zone.run(async () => {
        this.networkStatus = await Network.getStatus();
    });
  }

  async ngOnDestroy() {
    await this.networkListener.remove();
  }

  public async showNoConnectionError(){
    const message = this.translateService.instant('NO_INTERNET_CONNECTION');
    const toastOptions = getToastOptionInfo(message);
    this.toast = await this.toastController.create(toastOptions);
    await this.toast.present();
  }

}
