import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Configuration} from '../configuration';
import {BASE_PATH_AUTH} from '../variables';
import {Observable} from 'rxjs';
import {AuthSession} from '../model/authSession';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';
import {AuthBodyData} from '../model/authBody';


@Injectable({
    providedIn: 'root'
})

export class AuthSessionService {
    public static AUTH_PATH = '/oauth/token';
    protected basePath = 'https://eventmanager.cicg.ch';
    private defaultHeaders: HttpHeaders;
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient,
                @Inject(ENV) public environment: Env,
                @Optional()@Inject(BASE_PATH_AUTH) basePath: string,
                @Optional() configuration: Configuration) {

        this.defaultHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa(environment.client_id + ':' + environment.client_secret)
        });

        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    public login(user: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<AuthSession>;
    public login(user: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthSession>>;
    public login(user: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthSession>>;
    public login(user: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined || password === null || password === undefined) {
            throw new Error('Required parameter user or password was null or undefined when calling login.');
        }

        const loginUrl = `${this.basePath}${AuthSessionService.AUTH_PATH}`;

        const httpHeaderAccepts: string[] = [
            'application/json',
            'application/_*',
            'image/_*'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            this.defaultHeaders = this.defaultHeaders.set('Accept', httpHeaderAcceptSelected);
        }

        const body: AuthBodyData = {
            client_id: this.environment.client_id,
            client_secret: this.environment.client_secret,
            grant_type: 'password',
            username: user,
            password
        };

        const httpOptions = {
            headers: this.defaultHeaders,
            observe,
            reportProgress
        };

        return this.httpClient.post<AuthSession>(loginUrl, body, httpOptions);
    }

    public loginApp(observe?: 'body', reportProgress?: boolean): Observable<AuthSession>;
    public loginApp(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthSession>>;
    public loginApp(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthSession>>;
    public loginApp(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        const loginUrl = `${this.basePath}${AuthSessionService.AUTH_PATH}`;

        const httpHeaderAccepts: string[] = [
            'application/json',
            'application/_*',
            'image/_*'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            this.defaultHeaders = this.defaultHeaders.set('Accept', httpHeaderAcceptSelected);
        }

        const body: AuthBodyData = {
            client_id: this.environment.client_id,
            client_secret: this.environment.client_secret,
            grant_type: 'client_credentials',
        };

        const httpOptions = {
            headers: this.defaultHeaders,
            observe,
            reportProgress
        };

        return this.httpClient.post<AuthSession>(loginUrl, body, httpOptions);
    }

    public refreshToken(refreshToken: string, observe?: 'body', reportProgress?: boolean): Observable<AuthSession>;
    public refreshToken(refreshToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthSession>>;
    public refreshToken(refreshToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthSession>>;
    public refreshToken(refreshToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refreshToken === null || refreshToken === undefined) {
            throw new Error('Required parameter refresh token was null or undefined when calling refresh token.');
        }

        const loginUrl = `${this.basePath}${AuthSessionService.AUTH_PATH}`;

        const httpHeaderAccepts: string[] = [
            'application/json',
            'application/_*',
            'image/_*'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            this.defaultHeaders = this.defaultHeaders.set('Accept', httpHeaderAcceptSelected);
        }

        const body: AuthBodyData = {
            client_id: this.environment.client_id,
            client_secret: this.environment.client_secret,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        };

        const httpOptions = {
            headers: this.defaultHeaders,
            observe,
            reportProgress
        };

        return this.httpClient.post<AuthSession>(loginUrl, body, httpOptions);
    }

}
