import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'public-survey-welcome',
    templateUrl: './public-survey-welcome.page.html',
    styleUrls: ['./public-survey-welcome.page.scss'],
})
export class PublicSurveyWelcomePage {

    constructor(private router: Router) {}

    public takeSurvey(){
        this.router.navigate(['public-survey/questions']);
    }

}
