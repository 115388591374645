import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PasswordResetDataRequest, UsersService} from '../../api';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class ResetPassword extends JustNetworkResource<any, boolean> {
    constructor(private store: Store<AppState>,
                private userService: UsersService,
                private token: string,
                private passwordResetDataRequest: PasswordResetDataRequest) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.userService.userPasswordUpdateByToken(this.token, this.passwordResetDataRequest);
    }

    protected onNetworkSuccess(res: any): boolean {
        return true;
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        if (error.status === 422) {
            return UserFriendlyError.displayableAsToast('NEW_PASSWORD_TOO_WEAK_ERROR', false, true);
        } else{
            return UserFriendlyError.genericAsToast(false);
        }
    }
}
