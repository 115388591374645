import {AppState} from '../../index';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {AuthSession} from '../../../api/model/authSession';
import {pipe} from 'rxjs';
import {filter, map, scan} from 'rxjs/operators';

export const authSessionKey = 'authSession';

export const selectAuthSessionState = createFeatureSelector<AppState, AuthSession>(authSessionKey);

export const selectAccessToken = createSelector(
    selectAuthSessionState,
    (state: AuthSession) => state.access_token
);

export const selectRefreshToken = createSelector(
    selectAuthSessionState,
    (state: AuthSession) => state.refresh_token
);

export const isValidAuthSession = createSelector(
    selectAuthSessionState,
    (state: AuthSession) => state.access_token != null
);


interface InvalidatedAuthSessionEvent {
    wasValidAuthSession: boolean;
    wasJustInvalidatedAuthSession: boolean;
}

export const notifyInvalidatedAuthSession = pipe(
    select(isValidAuthSession),
    scan((invalidatedAuthSessionEvent: InvalidatedAuthSessionEvent, isCurrentlyValidAuthSession: boolean): InvalidatedAuthSessionEvent => {
        if (!invalidatedAuthSessionEvent.wasValidAuthSession && isCurrentlyValidAuthSession) {
            return { wasValidAuthSession: true, wasJustInvalidatedAuthSession: false };
        }
        else if (invalidatedAuthSessionEvent.wasValidAuthSession && !isCurrentlyValidAuthSession) {
            return { wasValidAuthSession: false, wasJustInvalidatedAuthSession: true };
        }
        return { wasValidAuthSession: invalidatedAuthSessionEvent.wasValidAuthSession &&
                isCurrentlyValidAuthSession, wasJustInvalidatedAuthSession: false };
    }, { wasValidAuthSession: false, wasJustInvalidatedAuthSession: false }),
    filter((invalidatedAuthSessionEvent: InvalidatedAuthSessionEvent) => {
        return invalidatedAuthSessionEvent.wasJustInvalidatedAuthSession;
    }),
    map(() => {
        return;
    })
);
