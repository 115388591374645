import {NetworkOperationResult} from './networkOperationResult';
import {concat, Observable, of} from 'rxjs';
import {Resource} from './resource';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from './userFriendlyError';

export abstract class JustNetworkResource<ResultType, RequestType> extends NetworkOperationResult<ResultType, RequestType> {
    protected abstract startNetworkCall(): Observable<RequestType>;

    public call(): Observable<Resource<ResultType>> {
        return super.networkAllowsFetch().pipe(
            switchMap((networkFetchAllowed: boolean) => {
                if (networkFetchAllowed) {
                    // we fetch from network or execute any other network operation
                    // we return the result as an observable
                    return concat<Resource<ResultType>>(
                        // we initially signal we are loading with no data
                        of(this.generateLoadingResource()),
                        // then we try to fetch the data from network
                        this.fetchFromNetwork()
                    );
                }
                else {
                    // otherwise we warn the user that they are offline and the data they are looking for is not in cache
                    return of(
                        Resource.error<ResultType>(
                            this.constructor.name,
                            UserFriendlyError.displayableAsToast('CONNECTION_MISSING_INTERNET', false, true)
                        )
                    );
                }
            })
        );
    }

    private fetchFromNetwork(): Observable<Resource<ResultType>> {
        return this.startNetworkCall()
            .pipe(
                take(1),
                map((dataFromNetwork: RequestType): ResultType => {
                    // we notify network success
                    return this.onNetworkSuccess(dataFromNetwork);
                }),
                switchMap((dataFromNetwork: ResultType): Observable<Resource<ResultType>> => {
                    // we return the network data as success resource
                    return of(super.generateSuccessResource(dataFromNetwork));
                }),
                catchError((error: HttpErrorResponse): Observable<Resource<ResultType>> => {
                    // we catch any other error that might occur
                    // we notify network error
                    const convertedError: Error = this.handleNetworkFailure(error);
                    // we send the data we got from the database until now along with the error
                    return of(Resource.error(this.constructor.name, convertedError));
                })
            );
    }
}
