import {AppState} from '../../index';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {User} from '../../../api';
import {pipe} from 'rxjs';
import {filter, map, scan} from 'rxjs/operators';

export const userKey = 'user';

export const selectUser = createFeatureSelector<AppState, User>(userKey);

export const selectUserLang = createSelector(
    selectUser,
    (state: User) => state.lang_code
);

export const isLoggedIn = createSelector(
    selectUser,
    (state: User) => state.email != null
);

export const isGuest = createSelector(
    selectUser,
    (state: User) => state.account_status === 4
);

export interface LoggedOutEvent {
    wasLoggedIn: boolean;
    wasJustLoggedOut: boolean;
    wasGuest: boolean;
}

export const notifyLoggedOut = pipe(
    select(selectUser),
    scan((loggedOutEvent: LoggedOutEvent, user: User): LoggedOutEvent => {
        const isCurrentlyLoggedIn: boolean = user.email != null;
        const isCurrentlyGuest: boolean = user.account_status === 4;

        if (!loggedOutEvent.wasLoggedIn && isCurrentlyLoggedIn) {
            // the case the user just logged in
            return {
                wasLoggedIn: true,
                wasJustLoggedOut: false,
                wasGuest: isCurrentlyGuest
            };
        }
        else if (loggedOutEvent.wasLoggedIn && !isCurrentlyLoggedIn) {
            // the case that will fire the change from logged in to logged out
            return {
                wasLoggedIn: false,
                wasJustLoggedOut: true,
                wasGuest: loggedOutEvent.wasGuest
            };
        }
        // the two remaining cases when both past status is equal current status (nothing changes)
        return {
            wasLoggedIn: loggedOutEvent.wasLoggedIn && isCurrentlyLoggedIn,
            wasJustLoggedOut: false,
            wasGuest: isCurrentlyGuest
        };
    }, {
        wasLoggedIn: false,
        wasJustLoggedOut: false,
        wasGuest: false
    }),
    filter((loggedOutEvent: LoggedOutEvent) => {
        return loggedOutEvent.wasJustLoggedOut;
    })
);

interface LoggedInEvent {
    wasLoggedOut: boolean;
    wasJustLoggedIn: boolean;
}

export const notifyLoggedIn = pipe(
    select(isLoggedIn),
    scan((loggedInEvent: LoggedInEvent, isCurrentlyLoggedIn: boolean): LoggedInEvent => {
        if (!loggedInEvent.wasLoggedOut && isCurrentlyLoggedIn) {
            return { wasLoggedOut: false, wasJustLoggedIn: false };
        }
        else if ((loggedInEvent.wasLoggedOut && !isCurrentlyLoggedIn) || (!loggedInEvent.wasLoggedOut && !isCurrentlyLoggedIn)) {
            return { wasLoggedOut: true, wasJustLoggedIn: false };
        }
        return { wasLoggedOut: false, wasJustLoggedIn: loggedInEvent.wasLoggedOut && isCurrentlyLoggedIn };
    }, { wasLoggedOut: false, wasJustLoggedIn: false }),
    filter((loggedInEvent: LoggedInEvent) => {
        return loggedInEvent.wasJustLoggedIn;
    }),
    map(() => {
        return;
    })
);
