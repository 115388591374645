import {AppState} from '../../index';
import {adapter} from './staticPageNews.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {CicgNews} from '../../../api';

export const staticPageNewsKey = 'staticPageNews';

export const selectStaticPageNewsState =
    createFeatureSelector<AppState, EntityState<CicgNews>>(staticPageNewsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllStaticPageNews = createSelector(
    selectStaticPageNewsState,
    (state: EntityState<CicgNews>) => selectAll(state)
);

export const selectStaticPageNewsById = createSelector(
    selectStaticPageNewsState,
    (state: EntityState<CicgNews>, id: string) =>
        selectAll(state).find(staticPageNews => staticPageNews.nid === id)
);
