<app-header titleKey="PRACTICAL_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/tourism"></app-header>

<ion-content class="practical_info_content_iframe practical_info_page"
             scroll-events>
    <div class="tourism-detail-page page-header"
         *ngIf="(attraction$ | async) != null">
        <div *appShowWhen="[isDesktop]" [ngClass]="(attraction$ | async)?.banners && (attraction$ | async)?.banners.length > 0 ?
            'header-image header-image--desktop' : 'header-image default_bg header-image--desktop'"
             [ngStyle]="{'background-image': ((attraction$ | async)?.banners && (attraction$ | async)?.banners.length > 0 ? 'url(' + (attraction$ | async)?.banners[0] + ')' : '' )}">
        </div>
        <div *appShowWhen="[isMobile]" [ngClass]="(attraction$ | async)?.banners && (attraction$ | async)?.banners.length > 0 ?
            'header-image header-image--mobile' : 'header-image no-header'"
             [ngStyle]="{'background-image': ((attraction$ | async)?.banners && (attraction$ | async)?.banners.length > 0 ? 'url(' + (attraction$ | async)?.banners[0] + ')' : '' )}">
        </div>

        <div [ngClass]="(attraction$ | async)?.banners && (attraction$ | async)?.banners.length > 0 ? 'main-content' : 'main-content main-no-header'">
            <div class="ion-padding">
                <!-- title -->
                <ion-item lines="full" class="ion-margin-bottom">
                    <ion-label class="ion-text-wrap">
                        <h1 class="ion-text-capitalize">
                            {{(attraction$ | async)?.name}}
                        </h1>
                    </ion-label>
                </ion-item>
                <!-- description -->
                <ion-item class="ion-margin-bottom">
                    <ion-label class="ion-text-wrap">
                        <div appLinkParser [innerHTML]="(attraction$ | async)?.description"></div>
                    </ion-label>
                </ion-item>

                <!-- schedule -->
                <ion-item class="ion-text-wrap">
                    <ion-label class="ion-text-wrap">
                        <h3 *ngIf="(attraction$ | async).hours">
                            {{ "OPENING_TIMES" | translate}}
                        </h3>
                        <div appLinkParser [innerHTML]="(attraction$ | async).hours">
                        </div>
                        <br *ngIf="(attraction$ | async).hours"/>
                        <h3 *ngIf="(attraction$ | async).prices.length > 0">
                            {{ "PRICES" | translate}}
                        </h3>
                        <div>
                            <p *ngFor="let price of (attraction$ | async).prices; index as i;">
                                {{price.type + ": " + price.amount + " CHF"}}
                            </p>
                        </div>

                    </ion-label>
                </ion-item>

                <!-- image slider -->

                <div *ngIf="(attraction$ | async).banners.length > 1">
                    <ion-item lines="none">
                        <ion-label>
                            <h3>
                                {{ "PHOTOS" | translate}}
                            </h3>
                        </ion-label>
                    </ion-item>
                    <div class="slide-container-weekly-menu-card ion-margin-start ion-margin-end">
                        <ion-fab *ngIf="!isAndroidOriOS" vertical="center" horizontal="start" (click)="slideBack()"
                                 class="arrow--back">
                            <ion-fab-button size="small">
                                <ion-icon name="ios-arrow-back"></ion-icon>
                            </ion-fab-button>
                        </ion-fab>
                        <ion-fab *ngIf="!isAndroidOriOS" vertical="center" horizontal="end" (click)="slideForward()"
                                 class="arrow--forward">
                            <ion-fab-button size="small">
                                <ion-icon name="ios-arrow-forward"></ion-icon>
                            </ion-fab-button>
                        </ion-fab>

                        <ion-slides class="slides-weekly-menu-card"
                                    #attractionSlider
                                    [options]="{spaceBetween: 3, speed: 100, slidesPerView: isAndroidOriOS ? 1.23: 2.42}"
                                    [pager]="false">
                            <ion-slide *ngFor="let image of (attraction$ | async).banners; let i = index;">
                                <ion-img [src]="image" ionImgViewer
                                     [alt]="(attraction$ | async)?.name + ' image'"
                                     [title]="(attraction$ | async)?.name"
                                     [text]="(attraction$ | async)?.name"
                                     [slideOptions]="{zoom: { enabled: true }}"
                                     scheme="light">
                                </ion-img>
                            </ion-slide>
                        </ion-slides>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noTourismDataLoading>
        <div class="tourism-detail-page page-header">
            <div *appShowWhen="[isDesktop]" class="header-image default_bg header-image--desktop">
            </div>
            <div *appShowWhen="[isMobile]" class="header-image no-header">
            </div>

            <div class="main-content main-no-header">
                <div class="ion-padding">
                    <!-- title -->
                    <ion-item lines="full" class="ion-margin-bottom">
                        <ion-label class="ion-text-wrap">
                            <ion-skeleton-text animated style="width: 50%; line-height: 18px;"></ion-skeleton-text>
                        </ion-label>
                    </ion-item>
                    <!-- description -->
                    <ion-item class="ion-margin-bottom">
                        <ion-label class="ion-text-wrap">
                            <div>
                                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                                <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
                                <ion-skeleton-text animated style="width: 95%"></ion-skeleton-text>
                            </div>
                        </ion-label>
                    </ion-item>

                    <!-- schedule -->
                    <ion-item class="ion-text-wrap">
                        <ion-label class="ion-text-wrap">
                            <ion-skeleton-text animated style="width: 30%; line-height: 12px;"></ion-skeleton-text>
                            <div>
                                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                                <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
                                <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
                            </div>
                        </ion-label>
                    </ion-item>
                </div>
            </div>
        </div>
    </app-loading-no-data>
</ion-content>
