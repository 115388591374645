import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {StaticPage, StaticPagesService} from '../../api';
import {upsertStaticPage} from '../../store/reducers/staticPages/staticPage.actions';
import {OrderedStaticPage, selectStaticPageBySlug} from '../../store/reducers/staticPages/staticPage.selectors';
import {AppState} from '../../store';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {tap} from 'rxjs/operators';

export class GetStaticPageBySlug extends NetworkBoundResource<StaticPage, StaticPage> {
    private rank: number;

    constructor(private store: Store<AppState>,
                private staticPagesService: StaticPagesService,
                private slug: string) {
        super();
        this.rank = -1;
    }

    protected loadFromDatabase(): Observable<StaticPage> {
        return this.store.pipe(
                select(selectStaticPageBySlug, this.slug),
                tap((staticPage: OrderedStaticPage) => {
                    if (staticPage != null) {
                        this.rank = staticPage.rank;
                    }
                })
            );
    }

    protected saveNetworkResult(staticPage: StaticPage) {
        const orderedStaticPage: OrderedStaticPage = {...staticPage, rank: this.rank};
        this.store.dispatch(upsertStaticPage( { staticPage: orderedStaticPage }));
    }

    protected shouldFetch(staticPage: StaticPage): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: staticPage == null,
            shouldFetch: staticPage == null,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
                resourceId: this.constructor.name + '-' + this.slug // rate limiter per page slug
            }
        };
    }

    protected startNetworkCall(): Observable<StaticPage> {
        return this.staticPagesService.listStaticPages_1(this.slug);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        this.cleanUpOnNetworkFailure();
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('STATIC_PAGES_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.slug);
    }
}
