import { Component, OnInit } from '@angular/core';
import {IS_DESKTOP} from '../../common/ui/directives/show.when';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
})
export class EventsPage implements OnInit {

  public isDesktop: boolean;

  constructor(public platform: Platform) { }

  ngOnInit() {
    this.isDesktop = IS_DESKTOP.isPlatform(this.platform);
  }

}
