import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {EventsPage} from './events.page';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponentModule} from '../../common/ui/app-header/header.component.module';
import {UiStateManagementModule} from '../../common/ui/state-management/ui.state.management.module';
import {EventsRoutingModule} from './events-routing.module';
import {EventDetailPageModule} from './event-detail/event-detail.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild(),
        HeaderComponentModule,
        UiStateManagementModule,
        EventDetailPageModule,
        EventsRoutingModule
    ],
    declarations: [EventsPage]
})
export class EventsPageModule {}
