import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Question, Answer } from '../../api';

/**
 * Generated class for the SurveyStarsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'survey-stars',
  templateUrl: './survey-stars.html',
  styleUrls: ['./survey-stars.scss'],
})
export class SurveyStarsComponent {

  static getCheckedUrl() {
    return './assets/imgs/survey_rating_star_checked.svg';
  }

  static getUncheckedUrl() {
    return './assets/imgs/survey_rating_star.svg';
  }

  @Input() public question: Question;
  @Output() public answerChange: EventEmitter<Answer> = new EventEmitter();

  public maxStars = 5;
  public pickedNumber = 0;
  public checked: boolean[] = [];
  public flagged = false;
  public currentIndex: number;
  public fromConstructor: boolean;

  constructor() {
    this.currentIndex = this.pickedNumber;
    this.fromConstructor = true;
    for (let i = 0; i < this.maxStars; ++i) {
      this.checked.push(false);
    }
    this.onStarChecked(this.pickedNumber, this.fromConstructor);
  }

  public getStarUrl(checked: boolean) {
    return checked ?
      SurveyStarsComponent.getCheckedUrl() :
        SurveyStarsComponent.getUncheckedUrl();
  }

  public onStarChecked(index: number, fromConstructor: boolean) {

    if (fromConstructor && index === 0) {
      for (let i = 0; i < this.maxStars; ++i) {
        this.checked[i] = false;
      }
    } else {
      for (let i = 0; i < this.maxStars; ++i) {
        this.checked[i] = i <= index;
      }
    }

    if (!fromConstructor) {
      const newAnwser: Answer = {
        question_id: this.question.id,
        value: index + 1
      };
      this.currentIndex = index;
      this.fromConstructor = false;
      this.answerChange.emit(newAnwser);
      this.flagged = false;
    }
  }

  public flagNA() {
    if (this.flagged) {
      for (let i = 0; i < this.maxStars; ++i) {
        this.checked[i] = false;
      }
    } else {
      if (this.fromConstructor && this.currentIndex === 0) {
        for (let i = 0; i < this.maxStars; ++i) {
          this.checked[i] = false;
        }
      } else {
        for (let i = 0; i < this.maxStars; ++i) {
          this.checked[i] = i <= this.currentIndex;
        }
      }
    }

    const newAnwser: Answer = {
      question_id: this.question.id,
      value: this.flagged ? 0 : this.currentIndex + 1
    };
    this.answerChange.emit(newAnwser);
  }

}
