import {Observable} from 'rxjs';
import {PublicSurvey, SurveysService} from '../../../api';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class GetPublicSurvey extends JustNetworkResource<PublicSurvey, PublicSurvey> {
    constructor(private surveysService: SurveysService,
                private lang: string) {
        super(true);
    }

    protected startNetworkCall(): Observable<PublicSurvey> {
        return this.surveysService.getPublicSurvey(this.lang);
    }

    protected onNetworkSuccess(publicSurvey: PublicSurvey): PublicSurvey {
        return publicSurvey;
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('GENERIC_ERROR', false);
    }
}
