import {NgModule} from '@angular/core';
import {PasswordForgottenPageModule} from './password-forgotten/password-forgotten.module';
import {PasswordRoutingModule} from './password-routing.module';
import {PasswordResetPageModule} from './password-reset/password-reset.module';
import {PasswordRegisterPageModule} from './password-register/password-register.module';

@NgModule({
  imports: [
    PasswordForgottenPageModule,
    PasswordRegisterPageModule,
    PasswordResetPageModule,
    PasswordRoutingModule,
  ]
})
export class PasswordModule {}
