import {AppState} from '../../index';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {adapter, EventSession} from './event-session.reducer';

export const eventSessionsKey = 'eventSessions';

export const selectEventSessionsState =
    createFeatureSelector<AppState, EntityState<EventSession>>(eventSessionsKey);

// get the selectors
const selectAll = adapter.getSelectors().selectAll;

export const selectEventSessionsForAnEvent = createSelector(
    selectEventSessionsState,
    (state: EntityState<EventSession>, eventId: number) => selectAll(state).filter((eventSession: EventSession) => {
        return eventSession.eventId === eventId;
    })
);

export const selectEventSessionById = createSelector(
    selectEventSessionsState,
    (state: EntityState<EventSession>, id: number) =>
        selectAll(state).find((eventSession: EventSession) => eventSession.id === id)
);
