<!-- Generated template for the SurveyStarsComponent component -->
<ion-grid>
  <ion-row *ngIf="question" class="ion-align-items-start">
    <h4>{{ question.title }}</h4>
    <h5>{{ question.description }}</h5>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="8" sizeLg="5" sizeXl="4"><img [alt]="'rating-star-' + ind" *ngFor="let star of checked; index as ind" [src]="getStarUrl(star)" (click)="onStarChecked(ind, false)" class="rating-star" /></ion-col>
    <ion-col size="4" sizeLg="7" sizeXl="8">
      <ion-item class="checkbox-container" lines="none">
        <ion-label>{{ 'N_A' | translate }}</ion-label>
        <ion-checkbox slot="start" class="no-margin" [(ngModel)]="flagged" [ngModelOptions]="{standalone: true}" (ionChange)="flagNA()"></ion-checkbox>
      </ion-item>
    </ion-col>
    <ion-input type="text" class="ion-hide"></ion-input>
  </ion-row>
</ion-grid>
