import {NgModule} from '@angular/core';
import {PublicSurveyWelcomePageModule} from './public-survey-welcome/public-survey-welcome.module';
import {PublicSurveyQuestionsPageModule} from './public-survey-questions/public-survey-questions.module';
import {PublicSurveyRoutingModule} from './public-survey-routing.module';


@NgModule({
  imports: [
      PublicSurveyWelcomePageModule,
      PublicSurveyQuestionsPageModule,
      PublicSurveyRoutingModule
  ],
  exports: [PublicSurveyRoutingModule]
})

export class PublicSurveyModule {}
