import {Observable} from 'rxjs';

import {fromPromise} from 'rxjs/internal-compatibility';
import {Mutex} from '../utils/mutex';
import {Preferences} from '@capacitor/preferences'

// we use the pure ionic storage to being able to use the Rate Limiter without the need
// of using a full Redux store which can be overkilling in apps in which we do not need it.

export enum CACHE_TIMES {
    THEN_MINUTES= 10 * 60,
    FIVE_MINUTES= 5 * 60,
    THREE_MINUTES= 3 * 60,
    TWO_MINUTES= 2 * 60,
    ONE_MINUTE = 60,
    THIRTY_SECONDS = 30,
    TWENTY_SECONDS = 20,
    THEN_SECONDS = 10,
}

export interface RateLimiterOptions {
    readonly resourceId: string;
    readonly maxCacheTimeInSeconds: number;
    // list of resource Ids depending on the current resource id
    // that we have to reset to refresh them when they are needed next.
    // e.g., if this rate limiter call depends on a force refresh
    readonly rateLimitDependantResourceIds?: string[];
}

export class RateLimiter {
    private static instance: RateLimiter;

    private static async resetLastFetchTime(resourceId: string): Promise<void> {
        return await Preferences.set({key: resourceId, value: new Date().getTime().toString()});
    }

    public static getInstance(): RateLimiter {
        if (RateLimiter.instance == null) {
            RateLimiter.instance = new RateLimiter();
        }
        return RateLimiter.instance;
    }

    private readonly mutex: Mutex;

    private constructor() {
        this.mutex = new Mutex();
    }

    public removeLimit(resourceId: string) {
        // we remove the key so that next time we attempt the API call we allow it
        Preferences.remove({key: resourceId}).then();
    }

    public resetLastFetchTime(resourceId: string): Observable<void> {
        // we reset the time for the resource to now
        return fromPromise(RateLimiter.resetLastFetchTime(resourceId));
    }

    public shouldFetchAndResetLastFetchTime(rateLimiterOptions: RateLimiterOptions): Observable<boolean> {
        return fromPromise(this.internalShouldFetchAndResetLastFetchTime(rateLimiterOptions));
    }

    public async removeAllLimits(): Promise<void> {
        const keys: string[] = (await Preferences.keys()).keys;
        keys.forEach((key: string) => {
            if (key !== 'NSIS_APP_STATE') {
                this.removeLimit(key);
            }
        });
    }

    public removeDependantRateLimits(rateLimiterOptions: RateLimiterOptions) {
        if (rateLimiterOptions.rateLimitDependantResourceIds) {
            for (const resourceId of rateLimiterOptions.rateLimitDependantResourceIds) {
                this.removeLimit(resourceId);
            }
        }
    }

    private async internalShouldFetchAndResetLastFetchTime(rateLimiterOptions: RateLimiterOptions): Promise<boolean> {
        let mustFetch = true;

        // this part of the code should be atomic despite the different await/async
        const release = await this.mutex.acquire();
        try {
            const keys: string[] = (await Preferences.keys()).keys;
            if (keys.indexOf(rateLimiterOptions.resourceId) > -1) {
                // key exists so we check the time to decide if we can fetch and reset it if we can fetch
                const lastFetchString: string = (await Preferences.get({key: rateLimiterOptions.resourceId})).value;
                // tslint:disable-next-line:radix
                const lastFetchTimestamp: number = parseInt(lastFetchString);
                const now: number = new Date().getTime();
                mustFetch = now - lastFetchTimestamp > (rateLimiterOptions.maxCacheTimeInSeconds * 1000); // transform to milliseconds
                if (mustFetch) {
                    await RateLimiter.resetLastFetchTime(rateLimiterOptions.resourceId);
                    this.removeDependantRateLimits(rateLimiterOptions);
                }
            }
            else {
                await RateLimiter.resetLastFetchTime(rateLimiterOptions.resourceId);
            }
        } finally {
            release();
        }

        return mustFetch;
    }
}
