import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotificationsPage} from './notifications.page';
import {NotificationDetailPage} from './notification-detail/notification-detail.page';

const routes: Routes = [
    {
        path: 'notifications',
        component: NotificationsPage
    },
    {
        path: 'notifications/:id',
        component: NotificationDetailPage
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class NotificationsRoutingModule { }
