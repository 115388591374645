import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {CffDeparture} from '../../../api';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {shareReplay, switchMap} from 'rxjs/operators';
import {Browser} from '@capacitor/browser';
import {IonRefresher, Platform} from '@ionic/angular';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-cff-api',
    templateUrl: './cff-api.page.html',
    styleUrls: ['./cff-api.page.scss'],
})
export class CffApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public cffDepartures$: Observable<CffDeparture[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noCffDataLoading', {static: false}) noCffDataLoading: LoadingNoDataComponent;

    public isAndroidOriOS: boolean;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform) {
        this.retry = new BehaviorSubject<boolean>(true);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit() {
        this.setUpCffDeparturesObservable();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    public async openLink() {
        await Browser.open(
            {
                url: 'https://cff.ch',
                windowName: '_system'
            }
        );
    }

    private setUpCffDeparturesObservable() {
        this.cffDepartures$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap(() => {
                    return this.staticPagesRepository.getCffApiPage();
                }),
                completeIonRefresher(this.ionRefresher),
                manageLoadingNoData(this.noCffDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
