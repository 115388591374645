import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastController} from '@ionic/angular';
import {Event, User} from '../../../../../api';
import {UserRepository} from '../../../../../repositories/user/user.repository';
import {Router} from '@angular/router';
import {getToastOptionError} from '../../../../../pages/common/constants';

@Component({
    selector: 'app-profile-button',
    templateUrl: './profile-button.component.html',
    styleUrls: ['./profile-button.component.scss'],
})
export class ProfileButtonComponent implements OnInit {

    @Input() public event: Event;
    public currentUser$: User;
    private toast;

    constructor(private translateService: TranslateService,
                private userRepository: UserRepository,
                private toastController: ToastController,
                private router: Router) {
    }

    public ngOnInit() {
        this.userRepository.getCurrentUser().subscribe(user => {
          this.currentUser$ = user;
        });
    }

    public async openProfile(){
        if (this.event && this.event.roles.indexOf('participant') > -1) {
            await this.router.navigate(['event', this.event.id, 'profile', 'me']);
        }
        else {
            const message = this.translateService.instant('NO_PARTICIPANT_TOAST');
            const toastOptions = getToastOptionError(message);
            this.toast = await this.toastController.create(toastOptions);
            await this.toast.present();
        }
    }

}
