<app-header titleKey="TPG_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>

    <div id="tpg-api-page" class="practical_info_page tpg_departure">
        <ion-list class="tpg_departure"
                  *ngIf="tpgDepartures$ &&
                  (tpgDepartures$ | async)?.length > 0">
            <ion-item id="{{'tpg-departure-list-item-' + i}}"
                      class="no-margin-left"
                      *ngFor="let tpgDeparture of (tpgDepartures$ | async); index as i;">
                <ion-avatar slot="start" class="with-fixed-size">
                    <div class="item-icon-image"
                         [ngClass]="'line-' + tpgDeparture.line">
                        <div class="initials-container ion-text-center">
                            <div class="initials">
                                <span [ngClass]="isAndroidOriOS ? 'span-sub' : ''">
                                    {{tpgDeparture.line ? tpgDeparture.line : '0'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </ion-avatar>
                <ion-label>
                    <div class="ion-align-items-center subtitle">
                        <ion-text class="vertical-align-middle">
                            {{'STOP_NAME_LABEL' | translate}} {{tpgDeparture.stop_name}}
                        </ion-text>
                    </div>
                    <h1 id="{{'tpg-departure-list-item-' + i + '-label'}}"
                        class="title">
                        <ion-icon class="vertical-align-middle" name="md-arrow-forward">
                        </ion-icon>
                        {{tpgDeparture.destination}}
                    </h1>
                </ion-label>
                <div slot="end" class="time-info">
                    <h2 *ngIf="tpgDeparture.eta > 59" class="eta">>{{tpgDeparture.eta}}'</h2>
                    <h2 *ngIf="tpgDeparture.eta < 60" class="eta">{{tpgDeparture.eta}}'</h2>
                </div>
            </ion-item>
            <ion-item class="no-margin-left" (click)="openLink()"  lines="none">
                <h5 class="item-label">
                    {{'MORE_INFO_TEXT' | translate}}
                    <span class="url-link">https://tpg.ch</span>
                </h5>
            </ion-item>
        </ion-list>

        <!--  LOADING TEMPLATE  -->
        <app-loading-no-data #noTpgDataLoading>
            <app-loading-list-no-data [numberOfTextRows]="2"></app-loading-list-no-data>
        </app-loading-no-data>

        <span id="practical-information-empty-list"
              *ngIf="tpgDepartures$ != null &&
               (tpgDepartures$ | async)?.length === 0">
            {{'API_NO_RESULTS' | translate}}
        </span>
    </div>
</ion-content>
