import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {PasswordResetPage} from './password-reset.page';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderComponentModule
  ],
  declarations: [PasswordResetPage]
})
export class PasswordResetPageModule {}
