import {Action, createReducer, on} from '@ngrx/store';
import * as StaticPageActions from '../staticPages/staticPage.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {OrderedStaticPage} from './staticPage.selectors';

export function selectSlug(staticPage: OrderedStaticPage): string {
    return staticPage.slug;
}

export const adapter: EntityAdapter<OrderedStaticPage> = createEntityAdapter<OrderedStaticPage>({
    selectId: selectSlug,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<OrderedStaticPage> = adapter.getInitialState();

const staticPagesReducer = createReducer(
  initialState,
  on(StaticPageActions.upsertStaticPages, (state, { staticPages }) => adapter.upsertMany(staticPages, state)),
  on(StaticPageActions.upsertStaticPage, (state, { staticPage }) => adapter.upsertOne(staticPage, state)),
  on(StaticPageActions.clearStaticPages, (state) => adapter.removeAll(state)),
  on(StaticPageActions.deleteStaticPageByPredicate, (state, { predicate }) => adapter.removeMany(predicate, state))
);

export function reducer(state: EntityState<OrderedStaticPage> | undefined, action: Action) {
    return staticPagesReducer(state, action);
}
