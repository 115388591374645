import {AppState} from '../../index';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {
    EventParticipant, getCurrentEventParticipantByEventId,
    getEventParticipantAgendaState,
    getEventParticipantById,
    participantsAdapterForAll,
    participantsAdapterForCurrentUser
} from './event-participant.reducer';
import {
    adapter as sessionsAdapter,
    EventSession,
    initialState as sessionsInitialState
} from '../eventSessions/event-session.reducer';

export const eventParticipantsKey = 'eventParticipants';
export const eventParticipantsForCurrentUserKey = 'eventParticipantsCurrentUser';

export const selectEventParticipantsState =
    createFeatureSelector<AppState, EntityState<EventParticipant>>(eventParticipantsKey);
export const selectCurrentUserEventParticipantsState =
    createFeatureSelector<AppState, EntityState<EventParticipant>>(eventParticipantsForCurrentUserKey);

// get the selectors
const selectAllForAll = participantsAdapterForAll.getSelectors().selectAll;
const selectAllForCurrentUser = participantsAdapterForCurrentUser.getSelectors().selectAll;

export const selectEventParticipantsForAnEvent = createSelector(
    selectEventParticipantsState,
    (state: EntityState<EventParticipant>, eventId: number) =>
        selectAllForAll(state).filter((eventParticipant: EventParticipant) => {
            return eventParticipant.eventId === eventId;
        })
);

export const selectEventParticipantById = createSelector(
    selectEventParticipantsState,
    (state: EntityState<EventParticipant>, props: { id: number, eventId?: number}) =>
        getEventParticipantById(state, props.id)
);

export const selectEventParticipantTalksState = createSelector(
    selectEventParticipantById,
    (eventParticipant: EventParticipant) => {
        if (!eventParticipant || !eventParticipant.talks) {
            return sessionsInitialState;
        }
        return eventParticipant.talks;
    }
);

export const selectEventParticipantTalks = createSelector(
    selectEventParticipantTalksState,
    (participantTalks: EntityState<EventSession>, props) => {
        return sessionsAdapter.getSelectors().selectAll(participantTalks).filter(
            (eventSession: EventSession) => eventSessionFilter(eventSession, props.eventId)
        );
    }
);

export const selectEventParticipantAgendaState = createSelector(
    selectEventParticipantById,
    (eventParticipant: EventParticipant) => {
        return getEventParticipantAgendaState(eventParticipant);
    }
);

export const selectEventParticipantAgenda = createSelector(
    selectEventParticipantAgendaState,
    (participantAgenda: EntityState<EventSession>, props) =>
        getAgenda(participantAgenda, props.eventId)
);

export const selectCurrentUserEventParticipantByEventId = createSelector(
    selectCurrentUserEventParticipantsState,
    (state: EntityState<EventParticipant>, eventId: number) =>
        getCurrentEventParticipantByEventId(state, eventId)
);

export const selectCurrentUserEventParticipantAgendaState = createSelector(
    selectCurrentUserEventParticipantByEventId,
    (eventParticipant: EventParticipant) => {
        return getEventParticipantAgendaState(eventParticipant);
    }
);

export const selectCurrentUserEventParticipantAgenda = createSelector(
    selectCurrentUserEventParticipantAgendaState,
    (participantAgenda: EntityState<EventSession>, eventId: number) =>
        getAgenda(participantAgenda, eventId)
);

function getAgenda(participantAgenda: EntityState<EventSession>, eventId: number) {
    return sessionsAdapter.getSelectors().selectAll(participantAgenda).filter(
        (eventSession: EventSession) => eventSessionFilter(eventSession, eventId)
    );
}

function eventSessionFilter(eventSession: EventSession, eventId: number) {
    return eventSession.eventId === eventId;
}
