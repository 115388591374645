import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {EmptyNotificationCardComponent} from './empty-notification-card.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [EmptyNotificationCardComponent],
  exports: [EmptyNotificationCardComponent]
})
export class EmptyNotificationCardComponentModule {}
