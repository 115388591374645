import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventsPage} from './events.page';
import {AuthGuard} from '../../guards/auth.guard';

const routes: Routes = [
    {
        path: 'events',
        component: EventsPage,
        children: [
            {
                path: 'upcoming',
                loadChildren: './upcoming-events/upcoming-events.module#UpcomingEventsPageModule'
            },
            {
                path: 'archived',
                loadChildren: './archived-events/archived-events.module#ArchivedEventsPageModule'
            },
            {
                path: '',
                redirectTo: '/events/upcoming',
                pathMatch: 'full'
            }
        ],
        canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class EventsRoutingModule { }
