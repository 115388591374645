import {Resource} from './resource';
import {from, Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from './userFriendlyError';
import {generateRandomHexString} from '../utils/functions';
import {Network} from '@capacitor/network';


export abstract class NetworkOperationResult<ResultType, RequestType> {
    private static async isNetworkAvailable(): Promise<boolean> {
        const status = await Network.getStatus();
        return status.connected;
    }

    private readonly identifier: string;

    protected constructor(private isLoadingBlocking: boolean = false,
                          private loadingTextKey: string = null) {
        this.identifier = this.constructor.name + '_' + generateRandomHexString(4);
    }

    // check if we can fetch from network give its status
    // based on some rules (for the moment just network availability)
    protected networkAllowsFetch(): Observable<boolean> {
        return from(NetworkOperationResult.isNetworkAvailable());
    }

    // if the conversion is not 1 to 1 this method will create an error
    // so than the dev must implement it properly
    protected onNetworkSuccess(dataFromNetwork: RequestType): ResultType {
        return dataFromNetwork as unknown as ResultType;
    }

    // re-implement this method in the subclass if you want to change the way error are handled
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        // we offer the possibility to clean some status of subclasses independently from the error status
        this.cleanUpOnNetworkFailure();
        // different actions per error type
        if (error.status === 401) {
            return this.on401NetworkFailure(error);
        }
        else if (error.status === 403) {
            return this.on403NetworkFailure(error);
        }
        else if (error.status === 404) {
            return this.on404NetworkFailure(error);
        }
        else {
            return this.onOtherNetworkFailure(error);
        }
    }

    // re-implement this method in the subclass if you want to transform the error to something else
    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return error;
    }

    // re-implement this method in the subclass if you want to transform the 401 error to something else
    protected on401NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('401_GENERIC_ERROR', false, true, error);
    }

    // re-implement this method in the subclass if you want to transform the 403 error to something else
    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('403_GENERIC_ERROR', false, true, error);
    }

    // re-implement this method in the subclass if you want to transform the 404 error to something else
    protected on404NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('404_GENERIC_ERROR', false, true, error);
    }

    // re-implement this method in the subclass if you want to do clean up operation for any kind of errors (like reset rate limiters)
    protected cleanUpOnNetworkFailure(): void {
    }

    // depending on the kind of loading we need or not to block the UI
    protected generateLoadingResource(data?: ResultType): Resource<ResultType> {
        if (this.isLoadingBlocking) {
            return Resource.loadingBlocking(this.identifier, this.loadingTextKey, data);
        }
        else {
            return Resource.loading(this.identifier, this.loadingTextKey, data);
        }
    }

    // depending on the kind of loading we have to generate the success resource
    protected generateSuccessResource(data?: ResultType): Resource<ResultType> {
        if (this.isLoadingBlocking) {
            return Resource.successBlocking(this.identifier, data);
        }
        else {
            return Resource.success(this.identifier, data);
        }
    }
}
