import {Status} from './status';
import {concat, EMPTY, Observable, of} from 'rxjs';

export interface LoadingConfig {
    isBlocking: boolean;
    loadingTextKey?: string;
}

export class Resource<T> {
    private static setUpLoadingConfig(loadingConfig: LoadingConfig,
                                      loadingTextKey?: string): LoadingConfig {
        if (loadingTextKey) {
            loadingConfig.loadingTextKey = loadingTextKey;
        }
        return loadingConfig;
    }

    static error<T>(identifier: string, error: Error, data?: T): Resource<T> {
        return new Resource(identifier, Status.ERROR, null, data, error);
    }

    static loading<T>(identifier: string, loadingTextKey?: string, data?: T): Resource<T> {
        const loadingConfig = this.setUpLoadingConfig({ isBlocking: false }, loadingTextKey);
        return new Resource(identifier, Status.LOADING, loadingConfig, data);
    }

    static loadingBlocking<T>(identifier: string, loadingTextKey?: string, data?: T): Resource<T> {
        const loadingConfig = this.setUpLoadingConfig({ isBlocking: true }, loadingTextKey);
        return new Resource(identifier, Status.LOADING, loadingConfig, data);
    }

    static success<T>(identifier: string, data?: T): Resource<T> {
        return new Resource(identifier, Status.SUCCESS, { isBlocking: false }, data);
    }

    static successBlocking<T>(identifier: string, data?: T): Resource<T> {
        return new Resource(identifier, Status.SUCCESS, { isBlocking: true }, data);
    }

    static fromResource<T>(resource: Resource<T>, newIdentifier: string, blocking: boolean = false): Resource<T> {
        switch (resource.status) {
            case Status.LOADING:
                if (resource.loadingConfig.isBlocking || blocking) {
                    return Resource.loadingBlocking(newIdentifier, resource.loadingConfig.loadingTextKey, resource.data);
                }
                else {
                    return Resource.loading(newIdentifier, null, resource.data);
                }
            case Status.ERROR:
                return Resource.error(newIdentifier, resource.err, resource.data);
            case Status.SUCCESS:
                if (resource.loadingConfig.isBlocking || blocking) {
                    return Resource.successBlocking(newIdentifier, resource.data);
                }
                else {
                    return Resource.success(newIdentifier, resource.data);
                }
        }
    }

    static noDataTransform<T1, T2>(resource: Resource<T1>): Resource<T2> {
        switch (resource.status) {
            case Status.LOADING:
                if (resource.loadingConfig.isBlocking) {
                    return Resource.loadingBlocking(resource.identifier, resource.loadingConfig.loadingTextKey);
                }
                else {
                    return Resource.loading(resource.identifier);
                }
            case Status.ERROR:
                return Resource.error(resource.identifier, resource.err);
            case Status.SUCCESS:
                if (resource.loadingConfig.isBlocking) {
                    return Resource.successBlocking(resource.identifier);
                }
                else {
                    return Resource.success(resource.identifier);
                }
        }
    }

    static simulateResourceCycleWithEmptyData(): Observable<Resource<any>> {
        return concat(of(Resource.loading('empty')), of(Resource.success('empty')), EMPTY);
    }

    public readonly identifier: string;
    public readonly status: Status;
    public readonly loadingConfig?: LoadingConfig;
    public readonly data?: T;
    public readonly err?: Error;

    private constructor(identifier: string,
                        status: Status,
                        loadingConfig?: LoadingConfig,
                        data?: T,
                        err?: Error) {
        this.identifier = identifier;
        this.status = status;
        this.loadingConfig = loadingConfig;
        this.data = data;
        this.err = err;
    }
}
