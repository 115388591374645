<div class="notif-div">
    <ion-badge id="notification-badge-{{i}}" class="new-notification-badge"
               *ngIf="isNew">{{'NEW_LABEL'| translate}}</ion-badge>
    <ion-card class="item" (click)="onItemClick(notif)">
        <ion-item lines="full">
            <ion-avatar slot="start" class="item-icon-square">
                <img *ngIf="notif.event_logo; else initial"
                     [src]="notif.id === -1 ?
                        notif.event_logo :
                        environment.storage_base_path + notif.event_logo">
                <ng-template #initial>
                    <div class="item-icon-image">
                        <div class="initials-container ion-text-center">
                            <div class="initials">
                                <span>
                                    {{notif.event_title ? notif.event_title.substr(0, 2) : 'EV'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ion-avatar>
            <ion-label>
                <h1 class="ion-text-wrap">{{notif.event_title}}</h1>
                <p>{{date}}, {{time}}</p>
            </ion-label>
        </ion-item>
        <ion-item lines="none">
            <h1 [ngClass]="isNew ? 'notif-title bold-title' : 'notif-title'">{{notif.object}}</h1>
        </ion-item>
        <ion-item lines="none">
            <div appLinkParser class="notif-text" [innerHtml]="text"></div>
        </ion-item>
        <ion-item lines="none" class="ion-text-end">
            <ion-label>
                <p class="read-more">
                    {{"NEWS_READ_MORE" | translate}}
                </p>
            </ion-label>
        </ion-item>


        <!--<ion-grid>
            <ion-row>
                <ion-col size="7">
                    <h5 id="notification-list-item-{{i}}-event-author">{{notif.event_title}}</h5>
                </ion-col>
                <ion-col size="5" class="ion-text-right">
                    <h5>{{date}},</h5>
                    <h5>{{time}}</h5>
                </ion-col>
                <ion-col size="12">
                    <hr>
                    <h2 [ngClass]="isNew ? 'notif-title bold-title' : 'notif-title'"
                        id="notification-list-item-{{i}}-title">{{notif.object}}</h2>
                    <br/>
                    <div class="notif-text" id="notification-list-item-{{i}}-short-text" link-parser
                         [innerHtml]="text"></div>
                </ion-col>
                <ion-col size="12" class="ion-text-right">
                    <p class="read-more" id="notification-list-item-{{i}}-read-more">
                        {{"NEWS_READ_MORE" | translate}}
                    </p>
                </ion-col>
            </ion-row>
        </ion-grid>-->
    </ion-card>
</div>
