import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Platform} from '@ionic/angular';

export interface IsPlatform {
  isPlatform(platform: Platform): boolean;
}

export const IS_MOBILE: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return platform.is('mobile') && !platform.is('desktop');
  }
};

export const IS_MOBILE_APP: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return platform.is('hybrid');
  }
};

export const IS_WEB_APP: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return !platform.is('hybrid');
  }
};

export const IS_WEB_AND_IOS_APP: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return !platform.is('hybrid') && platform.is('ios');
  }
};

export const IS_MOBILE_WEB_APP: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return platform.is('mobile') && !platform.is('hybrid');
  }
};

export const IS_DESKTOP: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return platform.is('desktop') && !platform.is('hybrid');
  }
};

export const IS_ANDROID: IsPlatform = {
  isPlatform(platform: Platform): boolean {
    return platform.is('android');
  }
};

/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[appShowWhen]'})
export class ShowWhenDirective {
  private hasView = false;
  private platformConditions: IsPlatform[] = [];
  private ifCondition = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public platform: Platform) { }

  @Input()
  set appShowWhen(conditions: IsPlatform[]) {
    this.platformConditions = conditions;
    this.updateView();
  }

  @Input()
  set appShowWhenIf(condition: boolean) {
    this.ifCondition = condition;
    this.updateView();
  }

  private updateView() {
    const shouldShow = this.shouldShowPlatform(this.platformConditions) && this.ifCondition;
    this.shouldShow(shouldShow);
  }

  private shouldShowPlatform(conditions: IsPlatform[]): boolean {
    let fulfilled = true;

    for (const condition of conditions) {
      fulfilled = fulfilled && condition.isPlatform(this.platform);
    }

    return fulfilled;
  }

  private shouldShow(shouldShow: boolean) {
    if (!shouldShow && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    } else if (shouldShow && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
