import {Resource} from '../repository/resource';
import {ResourceStatusService} from '../ui/state-management/resource.status.service';
import {filter, map, pluck, skipWhile, switchMap, take, tap} from 'rxjs/operators';
import {concat, EMPTY, forkJoin, Observable, of, OperatorFunction} from 'rxjs';
import {Status} from '../repository/status';
import {IonRefresher, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {getToastOptionSuccess} from '../../pages/common/constants';
import {LoadingNoDataComponent} from '../ui/state-management/loading-no-data/loading-no-data.component';


export function consume<T>(consumer: ResourceStatusService,
                           customFilter: (resource: Resource<T>) => boolean = null): OperatorFunction<Resource<T>, T> {
    return input$ => input$.pipe(
        tap((resource: Resource<T>) => {
            consumer.consumeResource(resource);
        }),
        filter((resource: Resource<T>) => {
            if (customFilter) {
                return customFilter(resource);
            }
            return resource.data != null;
        }),
        pluck('data')
    );
}

export function consumeOnlySuccess<T>(consumer: ResourceStatusService): OperatorFunction<Resource<T>, T> {
    return input$ => input$.pipe(
        consume(consumer, (resource: Resource<T>) => {
            return resource.data != null && resource.status === Status.SUCCESS;
        })
    );
}

export function manageLoadingNoData<T>(loadingNoDataComponent: LoadingNoDataComponent): OperatorFunction<Resource<T>, Resource<T>> {
    return input$ => input$.pipe(
        tap((resource: Resource<T>) => {
            if (resource.status === Status.LOADING) {
                if (resource.data == null) {
                    loadingNoDataComponent.show();
                }
                else if (resource.data instanceof Array && resource.data.length === 0) {
                    loadingNoDataComponent.show();
                }
                else {
                    loadingNoDataComponent.hide();
                }
            }
            else if (resource.status === Status.SUCCESS ||
                resource.status === Status.ERROR) {
                loadingNoDataComponent.hide();
            }
        }),
    );
}

export function completeIonRefresher<T>(ionRefresher: IonRefresher): OperatorFunction<Resource<T>, Resource<T>> {
    return input$ => input$.pipe(
        tap((resource: Resource<T>) => {
            if (resource.status === Status.SUCCESS || resource.status === Status.ERROR) {
                ionRefresher.complete().then();
            }
        }),
    );
}

export function completeOnResourceSuccessOrError<T>(): OperatorFunction<Resource<T>, Resource<T>> {
    return input$ => input$.pipe(
        switchMap((resource: Resource<any>) => {
                switch (resource.status) {
                    case Status.SUCCESS:
                    case Status.ERROR:
                        return concat(of(resource), EMPTY);
                    default:
                        return of(resource);
                }
            }
        )
    );
}

export function takeFirstSuccessOrErrorAndComplete<T>(): OperatorFunction<Resource<T>, Resource<T>> {
    return input$ => input$.pipe(
        skipWhile((resource: Resource<T>) => {
            return resource.status === Status.LOADING;
        }),
        take(1),
    );
}

export function forkJoinFromArrayResourceToResources<IT, RT>(
    singleOperation: (singleData: IT) => Observable<Resource<RT>>
    // we take a function to execute to every element of the resource with array
): OperatorFunction<Resource<IT[]>, Resource<RT>[]> {
    return input$ => input$.pipe(
        takeFirstSuccessOrErrorAndComplete(), // we avoid to get loading status
        switchMap((resourceWithArray: Resource<IT[]>) => {
            if (resourceWithArray.status === Status.SUCCESS && resourceWithArray.data) { // only if is success and there is data we continue
                const arrayData: IT[] = resourceWithArray.data;
                const resources$: Observable<Resource<RT>>[] =
                    arrayData.map((singleData: IT) => {
                            return singleOperation(singleData).pipe( // we execute the operation to get all the single resources
                                takeFirstSuccessOrErrorAndComplete() // we avoid to get loading status
                            );
                        }
                    );

                // if the resources observable is empty we have to put a success observable resource
                // to continue the fork join
                if (resources$.length === 0) {
                    resources$.push(of(Resource.noDataTransform(resourceWithArray)));
                }

                return forkJoin(resources$); // we return all the observables of the resources from the resource array ready to fire
            }
            else {
                const resource$: Observable<Resource<RT>> = of(Resource.error('forkJoinFromArrayResourceToResources', new Error()));
                return forkJoin([resource$]); // we return the error
            }
        }),
    );
}

export function mapSeveralResourcesToOneSuccessOrError<T>(resourceIdentifier: string,
                                                          isBlocking: boolean): OperatorFunction<Resource<T>[], Resource<T>> {
    return input$ => input$.pipe(
        map((resources: Resource<T>[]) => {
            const errorResource: Resource<T> = resources.find((resource: Resource<T>) => {
                return resource.status === Status.ERROR;
            });
            if (errorResource) {
                return Resource.error(resourceIdentifier, new Error());
            }
            else {
                if (isBlocking) {
                    return Resource.successBlocking(resourceIdentifier);
                }
                else {
                    return Resource.success(resourceIdentifier);
                }
            }
        }),
    );
}

export function showPositiveToast<T>(messageKey: string,
                                     translateService: TranslateService,
                                     toastController: ToastController,
                                     duration: number = 8000): OperatorFunction<T, T> {
    return input$ => input$.pipe(
        tap(async () => {
            // show positive toast
            const message = translateService.instant(messageKey);
            const toastOptions = getToastOptionSuccess(message);
            toastOptions.duration = duration;
            const toast = await toastController.create(toastOptions);
            await toast.present();
        })
    );
}
