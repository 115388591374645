import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {DailyMenu} from '../../../api';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {IonRefresher, IonSlides, Platform} from '@ionic/angular';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import DateTimeFormat = Intl.DateTimeFormat;
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-restaurant-api',
    templateUrl: './restaurant-api.page.html',
    styleUrls: ['./restaurant-api.page.scss'],
})
export class RestaurantApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public dailyMenus$: Observable<DailyMenu[]>;
    public todayMenu$: Observable<DailyMenu>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    public isAndroidOriOS: boolean;
    public dateFormatter: DateTimeFormat;
    public dayToday = 0;

    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('dailyMenuSlider', {static: false}) slides: IonSlides;
    @ViewChild('noMenuDataLoading', {static: false}) noMenuDataLoading: LoadingNoDataComponent;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform,
                public translate: TranslateService) {
        this.retry = new BehaviorSubject<boolean>(true);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
        this.dateFormatter = new Intl.DateTimeFormat(this.translate.currentLang,
            {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'});
        this.dayToday = (moment().tz('Europe/Zurich').weekday()) - 1;
    }

    ngAfterViewInit(): void {
        this.setUpDailyMenuObservable();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    public async slideBack() {
        await this.slides.slidePrev();
    }

    public async slideForward() {
        await this.slides.slideNext();
    }

    private setUpDailyMenuObservable() {
        this.dailyMenus$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap(() => {
                    return this.staticPagesRepository.getRestaurantApiPage();
                }),
                completeIonRefresher(this.ionRefresher),
                manageLoadingNoData(this.noMenuDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );

        this.todayMenu$ = this.dailyMenus$.pipe(
            map((dailyMenus: DailyMenu[]) => {
                return dailyMenus[this.dayToday];
            }),
            shareReplay(1)
        );
    }
}
