import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {IonRefresher} from '@ionic/angular';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {UserRepository} from '../../repositories/user/user.repository';
import {NotificationsRepository} from '../../repositories/notifications/notifications.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {switchMap} from 'rxjs/operators';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../common/rxjs/operators';
import {Notification} from '../../api';
import {LoadingNoDataComponent} from '../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.page.html',
    styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements AfterViewInit {
    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public notifications$: Observable<Notification[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noNotificationsDataLoading', {static: false}) noNotificationsDataLoading: LoadingNoDataComponent;

    constructor(public userRepository: UserRepository,
                private notificationsRepository: NotificationsRepository,
                private resourceStatusService: ResourceStatusService) {
        this.retry = new BehaviorSubject<boolean>(false);
        this.triggerRetry$ = this.retry.asObservable();
    }

    ngAfterViewInit(): void {
        this.setUpNotificationsObserver();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    private setUpNotificationsObserver() {
        this.notifications$ =
            merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
                .pipe(
                    switchMap((refresh: boolean) => {
                        return this.notificationsRepository.getNotifications(refresh);
                    }),
                    manageLoadingNoData(this.noNotificationsDataLoading),
                    completeIonRefresher(this.ionRefresher),
                    consume(this.resourceStatusService)
                );
    }
}
