<app-header titleKey="ROOMS_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>
    <div id="rooms-api-page" class="practical_info_page">
        <ion-list id="room-list"
                  *ngIf="rooms$ && (rooms$ | async)?.length > 0">
            <ion-item id="{{'room-list-item-' + i}}"
                      *ngFor="let room of (rooms$ | async); index as i;"
                      (click)="openRoom(room)" detail>
                <ion-avatar slot="start">
                    <img *ngIf="room.place.name === 'CICG'; else ccv" src="../assets/imgs/room_building_1.jpg">
                    <ng-template #ccv>
                        <img src="../assets/imgs/room_building_2.jpg">
                    </ng-template>
                </ion-avatar>
                <ion-label>
                    <ion-text id="{{'room-list-item-' + i + '-floor'}}" class="subtitle">
                        {{translate.currentLang == 'fr' ? room.floor_fr : room.floor}}
                        | {{translate.currentLang == 'fr' ? room.room_type.name_fr : room.room_type.name_en}}
                    </ion-text>
                    <h1 id="{{'room-list-item-' + i + '-name'}}" class="title">
                        {{translate.currentLang == 'fr' ? room._fr : room.name}}
                    </h1>
                </ion-label>
            </ion-item>
        </ion-list>

        <!--  LOADING TEMPLATE  -->
        <app-loading-no-data #noRoomsDataLoading>
            <app-loading-list-no-data [numberOfTextRows]="2"></app-loading-list-no-data>
        </app-loading-no-data>

        <span id="practical-information-empty-list"
              *ngIf="rooms$ && (rooms$ | async)?.length === 0">
          {{'API_NO_RESULTS' | translate}}
        </span>
    </div>
</ion-content>
