import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';

export class BrandedTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<any> {
        const $normalLangFile = this.http.get(`${this.prefix}${lang}${this.suffix}`);
        const $brandedLangFile = this.http.get(`${this.prefix}${lang}-branded${this.suffix}`);
        return zip($normalLangFile, $brandedLangFile).pipe(
            map(([normalLangJson, brandedLangJson]) => {
                return {...normalLangJson, ...brandedLangJson};
            })
        );
    }
}
