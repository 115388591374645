import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {PracticalInformationListPage} from './practical-information-list.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {PracticalInformationListBySlugPage} from './practical-information-list-by-slug.page';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderComponentModule,
        UiStateManagementModule,
    ],
  declarations: [
    PracticalInformationListPage,
    PracticalInformationListBySlugPage
  ]
})
export class PracticalInformationListPageModule {}
