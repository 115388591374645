import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AgendasService, Session} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {selectEventParticipantAgenda} from '../../../store/reducers/eventParticipants/event-participant.selectors';
import {EventSession} from '../../../store/reducers/eventSessions/event-session.reducer';
import {updateEventParticipantAgenda} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';

export class GetEventParticipantAgendaById extends NetworkBoundResource<Session[], Session[]> {

    constructor(private store: Store<AppState>,
                private agendasService: AgendasService,
                private eventId: number,
                private participantId: number) {
        super();
    }

    protected loadFromDatabase(): Observable<Session[]> {
        return this.store.pipe(select(selectEventParticipantAgenda, { id: this.participantId, eventId: this.eventId }));
    }

    protected shouldFetch(dataFromDatabase: Session[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.TWO_MINUTES,
                resourceId: this.constructor.name + '-' + this.eventId + '-' + this.participantId
            }
        };
    }

    protected startNetworkCall(): Observable<Session[]> {
        return this.agendasService.getParticipantAgenda(this.eventId, this.participantId);
    }

    protected saveNetworkResult(sessions: Session[]) {
        const eventSessions: EventSession[] = sessions.map((session: Session): EventSession => {
            return {...session, eventId: this.eventId};
        });
        this.store.dispatch(updateEventParticipantAgenda({ participantId: this.participantId, eventSessions }));
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_SESSIONS_FOR_AGENDA_PROFILE_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId + '-' + this.participantId);
    }
}
