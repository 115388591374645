<ion-card>
    <ion-grid>
        <ion-row>
            <ion-col size="3">
                <img [src]=fileIcon class="file-item-icon" alt=""/>
            </ion-col>
            <ion-col size="9" class="ion-text-left">
                <ion-row class="title-row">
                    <h4 class="file-title ">{{file.title}}</h4>
                </ion-row>
                <hr>
                <ion-row>
                    <h5 class="ion-no-margin">{{extractedType}}</h5>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card>
