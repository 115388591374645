import {LinkParserDirective} from './link-parser/link-parser';
import {NgModule} from '@angular/core';
import {ParallaxHeaderDirective} from './parallax-header/parallax-header';

@NgModule({
  declarations: [
    LinkParserDirective,
    ParallaxHeaderDirective
  ],
  imports: [],
  exports: [
    LinkParserDirective,
    ParallaxHeaderDirective
  ]
})
export class AppDirectivesModule {}
