import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ApiPagesService, Room} from '../../api';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {selectAllStaticPageRooms} from '../../store/reducers/staticPageRooms/staticPageRooms.selectors';
import {upsertStaticPageRooms} from '../../store/reducers/staticPageRooms/staticPageRooms.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetRoomsApiPage extends NetworkBoundResource<Room[], Room[]> {
    constructor(private store: Store<AppState>,
                private apiPagesService: ApiPagesService,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Room[]> {
        return this.store.pipe(select(selectAllStaticPageRooms));
    }

    protected saveNetworkResult(rooms: Room[]) {
        this.store.dispatch(upsertStaticPageRooms({ staticPageRooms: rooms }));
    }

    protected shouldFetch(dataFromDatabase: Room[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
                resourceId: this.constructor.name
            }
        };
    }

    protected startNetworkCall(): Observable<Room[]> {
        return this.apiPagesService.roomsApiPage();
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        this.cleanUpOnNetworkFailure();
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('STATIC_PAGE_ROOMS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name);
    }
}
