import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {LoadingConfig, Resource} from '../../repository/resource';
import {Status} from '../../repository/status';

@Injectable({
    providedIn: 'root'
})
export class ResourceStatusService {
    // Inputs
    public retry: Subject<boolean>;

    // Outputs
    public loadingSuccessStatus$: Observable<Status>;
    public loadingBlockingSuccessStatus$: Observable<LoadingConfig | Status.SUCCESS | Status.ERROR>;
    public error$: Observable<Error>;
    public triggerRetry$: Observable<boolean>;

    // Private
    private loadingSuccessSubject: Subject<Status>;
    private loadingBlockingSuccessSubject: Subject<LoadingConfig | Status.SUCCESS | Status.ERROR>;
    private errorPublisher: Subject<Error>;

    private inLoading: string[];
    private inLoadingBlocking: string[];

    private isInLoading: boolean;
    private isInLoadingBlocking: boolean;

    constructor() {
        this.loadingSuccessSubject = new Subject<Status>();
        this.loadingSuccessStatus$ = this.loadingSuccessSubject.asObservable();

        this.loadingBlockingSuccessSubject = new Subject<LoadingConfig | Status.SUCCESS | Status.ERROR>();
        this.loadingBlockingSuccessStatus$ = this.loadingBlockingSuccessSubject.asObservable();

        this.errorPublisher = new Subject<Error>();
        this.error$ = this.errorPublisher.asObservable();

        this.retry = new Subject<boolean>();
        this.triggerRetry$ = this.retry.asObservable();

        this.inLoading = [];
        this.inLoadingBlocking = [];

        this.isInLoading = false;
        this.isInLoadingBlocking = false;
    }

    // unfortunately we can't determine the type of the resource at runtime so we should provide the
    // class name as parameter.
    // forceBlocking overrides any Loading status from the repository forcing the UI to block the loading
    // for specific exceptional situations.
    public consumeResource<T>(resource: Resource<T>) {
        switch (resource.status) {
            case Status.LOADING:
                if (resource.loadingConfig.isBlocking) {
                    this.manageLoadingBlocking(resource);
                }
                else {
                    this.manageLoading(resource.identifier);
                }
                break;
            case Status.SUCCESS:
                if (resource.loadingConfig.isBlocking) {
                    this.manageSuccessBlocking(resource.identifier);
                }
                else {
                    this.manageSuccess(resource.identifier);
                }
                break;
            case Status.ERROR:
                this.manageError(resource);
                break;
        }
    }

    private manageLoading(resourceGroupName: string) {
        if (!this.inLoading.includes(resourceGroupName)) {
            this.inLoading.push(resourceGroupName);
        }

        if (!this.isInLoading) { // we notify to be in loading only once
            this.isInLoading = true;
            this.loadingSuccessSubject.next(Status.LOADING);
        }
    }

    private manageLoadingBlocking<T>(resource: Resource<T>) {
        if (!this.inLoadingBlocking.includes(resource.identifier)) {
            this.inLoadingBlocking.push(resource.identifier);
        }

        if (!this.isInLoadingBlocking) { // we notify to be in loading blocking only once
            this.isInLoadingBlocking = true;
            this.loadingBlockingSuccessSubject.next(resource.loadingConfig);
        }
    }

    private manageSuccess(resourceGroupName: string) {
        const indexInLoading = this.inLoading.indexOf(resourceGroupName);
        if (indexInLoading > -1) {
            this.inLoading.splice(indexInLoading, 1);
        }

        if (this.inLoading.length === 0) {
            this.isInLoading = false;
            this.loadingSuccessSubject.next(Status.SUCCESS);
        }
    }

    private manageSuccessBlocking(resourceGroupName: string) {
        const indexInLoadingBlocking = this.inLoadingBlocking.indexOf(resourceGroupName);
        if (indexInLoadingBlocking > -1) {
            this.inLoadingBlocking.splice(indexInLoadingBlocking, 1);
        }

        if (this.inLoadingBlocking.length === 0) {
            this.isInLoadingBlocking = false;
            this.loadingBlockingSuccessSubject.next(Status.SUCCESS);
        }
    }

    private manageError<T>(resource: Resource<T>) {
        this.inLoading = [];
        this.inLoadingBlocking = [];
        this.isInLoading = false;
        this.isInLoadingBlocking = false;
        this.loadingSuccessSubject.next(Status.ERROR);
        this.loadingBlockingSuccessSubject.next(Status.ERROR);
        this.errorPublisher.next(resource.err);
    }
}
