import {
    ResourceFetchOptions,
    NetworkBoundResource
} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Notification, NotificationsService} from '../../api';
import {selectNotificationById} from '../../store/reducers/notifications/notification.selectors';
import {AppState} from '../../store';
import {upsertNotification} from '../../store/reducers/notifications/notification.actions';
import {CACHE_TIMES, RateLimiterOptions} from '../../common/repository/rateLimiter';
import {HttpErrorResponse} from '@angular/common/http';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

export class GetNotificationById extends NetworkBoundResource<Notification, Notification> {
    constructor(private store: Store<AppState>,
                private notificationsService: NotificationsService,
                private id: number) {
        super();
    }

    protected loadFromDatabase(): Observable<Notification> {
        return this.store.pipe(select(selectNotificationById, this.id));
    }

    protected saveNetworkResult(notification: Notification) {
        this.store.dispatch(upsertNotification( { notification }));
    }

    protected shouldFetch(dataFromDatabase: Notification): ResourceFetchOptions {
        // if we establish new rules we should be carefull that we do not try to retrieve
        // the local welcome notification with -1
        // be aware of the special behaviour of this resource
        const rateLimiterOptions: RateLimiterOptions = {
            maxCacheTimeInSeconds: CACHE_TIMES.FIVE_MINUTES,
            resourceId: this.constructor.name + '-' + this.id
        };

        if (dataFromDatabase) {
            if (dataFromDatabase.id === -1) {
                // we have the default notification, we should not fetch anything ever
                return {
                    shouldErrorIfNoNetwork: false,
                    shouldFetch: false
                };
            }
            else {
                // we should fetch if rate limiter says so
                return {
                    shouldErrorIfNoNetwork: false,
                    shouldFetch: false,
                    rateLimiterOptions
                };
            }
        }
        else {
            // there is no data at all so we should fetch
            return {
                shouldErrorIfNoNetwork: true,
                shouldFetch: true
            };
        }
    }

    protected startNetworkCall(): Observable<Notification> {
        return this.notificationsService.getNotification(this.id);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('NOTIFICATION_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        if (this.id !== -1) {
            super.removeRateLimiterLimit(this.constructor.name + '-' + this.id);
        }
    }
}
