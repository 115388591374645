import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ParamMap, Router, UrlSegment} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {Resource} from '../../../common/repository/resource';
import {Room, StaticPage} from '../../../api';
import {Observable} from 'rxjs';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {consume} from '../../../common/rxjs/operators';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.page.html',
    styleUrls: ['./iframe.page.scss'],
})
export class IframePage implements OnInit {

    // OUTPUTS
    public url$: Observable<SafeResourceUrl>;
    public title = '';
    public onLoading: boolean;

    constructor(
        private staticPagesRepository: StaticPagesRepository,
        private resourceStatusService: ResourceStatusService,
        private translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router) {
        this.onLoading = true;
    }

    ngOnInit() {
        this.setUpIFrameURLObserver();
        // we start the loading to wait for the page to be ready
        this.enableLoading();
    }

    public onLoad() {
        this.stopLoading();
    }

    public async onError() {
        this.stopLoading();
        await this.router.navigate(['/practical-information/list']);
    }

    private setUpIFrameURLObserver() {
        this.url$ = this.route.url
            .pipe(
                switchMap((url: UrlSegment[]) => {
                    const paths: string[] = url.map(path => path.path); // we extract the path of each segment
                    if (paths.includes('rooms')) {
                        // we are in the case of the room
                        return this.route.paramMap
                            .pipe(
                                switchMap((params: ParamMap) => {
                                    // tslint:disable-next-line:radix
                                    const roomId = parseInt(params.get('id'));
                                    return this.staticPagesRepository.getRoomApiPageById(roomId);
                                }),
                                consume(this.resourceStatusService),
                                map((room: Room) => {
                                    if (this.translate.currentLang === 'fr') {
                                        this.title = room._fr;
                                        return this.domSanitizer.bypassSecurityTrustResourceUrl(room.url_fr);
                                    }
                                    else {
                                        this.title = room.name;
                                        return this.domSanitizer.bypassSecurityTrustResourceUrl(room.url_en);
                                    }
                                })
                            );
                    }
                    else {
                        // we are in the case of the static page
                        return this.route.paramMap
                            .pipe(
                                switchMap((params: ParamMap) => {
                                    const slug = params.get('slug');
                                    return this.staticPagesRepository.getStaticPageBySlug(slug);
                                }),
                                consume(this.resourceStatusService),
                                map((staticPage: StaticPage) => {
                                    if (this.translate.currentLang === 'fr') {
                                        this.title = staticPage.title_fr;
                                        return this.domSanitizer.bypassSecurityTrustResourceUrl(staticPage.url_fr);
                                    }
                                    else {
                                        this.title = staticPage.title;
                                        return this.domSanitizer.bypassSecurityTrustResourceUrl(staticPage.url);
                                    }
                                })
                            );
                    }
                })
            );
    }

    private enableLoading() {
        this.resourceStatusService.consumeResource(Resource.loading('IframePage'));
        this.onLoading = true;
    }

    private stopLoading() {
        this.resourceStatusService.consumeResource(Resource.success('IframePage'));
        this.onLoading = false;
    }
}
