<app-header titleKey="PUBLIC_SURVEY_TITLE" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="public-survey/welcome"></app-header>

<ion-content *ngIf="survey"
             id="public-survey-questions-page" class="scroll-desktop"
             [forceOverscroll]="isDesktop.isPlatform(platform)">

    <div #surveyContainer slot="fixed" style="height: 100%; overflow-y: auto">

        <ion-grid style="height: 100%">
            <!-- First question row -->
            <ion-row align-items-center style="height: 100%">
                <ion-card class="highlighted-question" *ngIf="survey.sections[0] as firstSection">
                    <ion-card-header>
                        <h2>{{ firstSection.title }}</h2>
                    </ion-card-header>
                    <ion-card-content *ngIf="firstSection.questions[0] as firstQuestion">
                        <survey-question *ngIf="firstQuestion.type === 1" [question]="firstQuestion" (answerChange)="onAnswerChange($event)" [eventLang]="event?.lang"></survey-question>
                        <survey-stars *ngIf="firstQuestion.type === 0" [question]="firstQuestion" (answerChange)="onAnswerChange($event)"></survey-stars>
                    </ion-card-content>
                </ion-card>
            </ion-row>

            <ion-row id="otherQuestions">
                <ion-col>
                    <ion-card *ngFor="let section of survey.sections; index as sectionIndex"
                              [ngClass]="sectionIndex === survey.sections.length-1 ? 'survey-card' : ''"
                              id="section-{{(sectionIndex + 1)}}"
                    >
                        <div *ngIf="getSectionQuestionsWithoutQuestions(section, [getSurveyFirstQuestion()]) as sectionQuestions">
                            <div *ngIf="sectionQuestions.length > 0">
                                <ion-card-header>
                                    <h2>{{ section.title }}</h2>
                                </ion-card-header>
                                <ion-card-content>
                                    <ion-list>
                                        <div *ngFor="let question of sectionQuestions; index as questionIndex" id="question-{{(sectionIndex + 1)}}-{{(questionIndex + 1)}}">
                                            <survey-question *ngIf="question.type === 1" [question]="question" (answerChange)="onAnswerChange($event)" [eventLang]="event?.lang"></survey-question>
                                            <survey-stars *ngIf="question.type === 0" [question]="question" (answerChange)="onAnswerChange($event)"></survey-stars>
                                            <hr *ngIf="questionIndex < sectionQuestions.length">
                                        </div>
                                    </ion-list>
                                </ion-card-content>
                            </div>
                        </div>
                    </ion-card>

                    <div class="ion-align-items-center ion-text-center">
                        <ion-button (click)="onSubmitSurvey()" class="survey-submit-button">{{ "SURVEY_SUBMIT" | translate }}</ion-button>
                    </div>
                </ion-col>
            </ion-row>

        </ion-grid>
    </div>

</ion-content>
