import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {finalize, share, tap} from 'rxjs/operators';
import {EventsRepository} from '../repositories/events/events.repository';
import {Resource} from '../common/repository/resource';
import {Participant} from '../api';
import {takeFirstSuccessOrErrorAndComplete} from '../common/rxjs/operators';
import {ResourceStatusService} from '../common/ui/state-management/resource.status.service';
import {Status} from '../common/repository/status';
import {UserFriendlyError} from '../common/repository/userFriendlyError';

@Injectable({
    providedIn: 'root'
})
export class ParticipantGuard implements CanActivate {

    constructor(private eventsRepository: EventsRepository,
                private resourceStatusService: ResourceStatusService,
                private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // tslint:disable-next-line:radix
        const eventId = parseInt(next.paramMap.get('id'));
        // tslint:disable-next-line:radix
        const participantId = parseInt(next.paramMap.get('participantId'));

        // We use a subject here to understand when the guard system of angular unsubscribes from
        // our observable so that we can finalize the flow ourselves if needed and also guarantee
        // our guard execution is done to the end and the loading and error info do not end up in a strange state
        const guardSubject: Subject<boolean | UrlTree> = new Subject();
        const guardObservable: Observable<boolean | UrlTree> = guardSubject.asObservable().pipe(
            finalize(() => {
            }),
            share()
        );

        this.eventsRepository.getParticipantByIdForEvent(eventId, participantId, true).pipe(
            tap((participantResource: Resource<Participant>) => {
                // we show all the status
                this.resourceStatusService.consumeResource(participantResource);
            }),
            takeFirstSuccessOrErrorAndComplete()
        ).subscribe((participantResource: Resource<Participant>) => {
            if (participantResource.status === Status.ERROR) {
                // we block only if the error is a 403 not authorized
                if (participantResource.err instanceof UserFriendlyError) {
                    const error: UserFriendlyError = participantResource.err;
                    if (error.isForbiddenError()) {
                        // we block and redirect back to the event participants tab
                        guardSubject.next(this.router.createUrlTree(['event', eventId, 'participants']));
                    }
                }
                else {
                    // we show the error
                    // we let the user continue to the participant profile
                    guardSubject.next(true);
                }
            }
            else {
                // we let the user continue to the participant profile
                guardSubject.next(true);
            }
        });

        return guardObservable;
    }
}
