import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Participant, ParticipantsService} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {EventParticipant} from '../../../store/reducers/eventParticipants/event-participant.reducer';
import {selectEventParticipantsForAnEvent} from '../../../store/reducers/eventParticipants/event-participant.selectors';
import {upsertEventParticipants} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';
import {GetEventParticipantAgendaById} from '../agenda/getEventParticipantAgendaById';
import {GetEventSessionById} from '../sessions/getEventSessionById';

export class GetEventParticipants extends NetworkBoundResource<Participant[], Participant[]> {
    constructor(private store: Store<AppState>,
                private participantService: ParticipantsService,
                private eventId: number,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Participant[]> {
        return this.store.pipe(select(selectEventParticipantsForAnEvent, this.eventId));
    }

    protected saveNetworkResult(participants: Participant[]) {
        const eventParticipants: EventParticipant[] = participants.map((participant: Participant): EventParticipant => {
            return {...participant, eventId: this.eventId};
        });
        this.store.dispatch(upsertEventParticipants({ eventParticipants }));
    }

    protected shouldFetch(dataFromDatabase: Participant[]): ResourceFetchOptions {
        const dependentResources: string [] = [];
        for (const participant of dataFromDatabase) {
            dependentResources.push(GetEventParticipantAgendaById.name + '-' + this.eventId + '-' + participant.id);
        }

        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.eventId,
                rateLimitDependantResourceIds: dependentResources,
            }
        };
    }

    protected startNetworkCall(): Observable<Participant[]> {
        return this.participantService.listParticipants(this.eventId);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_PARTICIPANTS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId);
    }
}
