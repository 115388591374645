import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {StaticPagesRepository} from '../../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {RoomDetail} from '../../../../api';
import {IS_DESKTOP, IS_MOBILE} from '../../../../common/ui/directives/show.when';
import {shareReplay, switchMap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {consume, manageLoadingNoData} from '../../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-room-api-details',
    templateUrl: './room-api-details.page.html',
    styleUrls: ['./room-api-details.page.scss'],
})
export class RoomApiDetailsPage implements AfterViewInit {
    // OUTPUTS
    public roomDetail$: Observable<RoomDetail>;

    // VARIABLES
    @ViewChild('noRoomDataLoading', {static: false}) noRoomDataLoading: LoadingNoDataComponent;
    public readonly isDesktop = IS_DESKTOP;
    public readonly isMobile = IS_MOBILE;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private route: ActivatedRoute,
                public sanitizer: DomSanitizer) { }

    ngAfterViewInit() {
        this.setUpRoomDetailObservable();
    }

    private setUpRoomDetailObservable() {
        this.roomDetail$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    // tslint:disable-next-line:radix
                    const externalId = parseInt(params.get('extId'));
                    return this.staticPagesRepository.getRoomDetailApiPageByExternalId(externalId);
                }),
                manageLoadingNoData(this.noRoomDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
