/**
 * FIPOI API
 * This API exposes the functionalities of FIPOI extranet to implement client side operations.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: team@mobilethinking.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Session} from '../model/session';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class SessionsService {

    protected basePath = 'https://eventmanager.cicg.ch/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get a session of an event by their id
     *
     * @param event_id Id of an event
     * @param session_id Id of a session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSession(event_id: number, session_id: number, observe?: 'body', reportProgress?: boolean): Observable<Session>;
    public getSession(event_id: number, session_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Session>>;
    public getSession(event_id: number, session_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Session>>;
    public getSession(event_id: number, session_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (event_id === null || event_id === undefined) {
            throw new Error('Required parameter event_id was null or undefined when calling getSession.');
        }

        if (session_id === null || session_id === undefined) {
            throw new Error('Required parameter session_id was null or undefined when calling getSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (fipoi_user_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/_*',
            'image/_*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Session>(`${this.basePath}/events/${encodeURIComponent(String(event_id))}/sessions/${encodeURIComponent(String(session_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all the sessions of an event identified by its id
     *
     * @param event_id Id of an event
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSessions(event_id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Session>>;
    public listSessions(event_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Session>>>;
    public listSessions(event_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Session>>>;
    public listSessions(event_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (event_id === null || event_id === undefined) {
            throw new Error('Required parameter event_id was null or undefined when calling listSessions.');
        }

        let headers = this.defaultHeaders;

        // authentication (fipoi_user_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/_*',
            'image/_*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Session>>(`${this.basePath}/events/${encodeURIComponent(String(event_id))}/sessions/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
