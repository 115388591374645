import {AfterViewInit, Directive, ElementRef, OnChanges} from '@angular/core';

/**
 * Generated class for the LinkParserDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[appLinkParser]' // Attribute selector
})
export class LinkParserDirective implements AfterViewInit, OnChanges {
    private static parseLink(alink) {
      const href = alink.href;
      alink.setAttribute('onclick', 'window.open(\'' + href + '\', \'_system\', \'location=yes\');');
      alink.removeAttribute('href');
      const oldClass = alink.getAttribute('class');
      if (oldClass && oldClass.indexOf('button') !== -1){
        alink.setAttribute('class', oldClass + ' fake-button');
        alink.parentElement.style.textAlign = 'center';
      }
      else {
        alink.setAttribute('class', oldClass + ' url-link');
      }
    }

    constructor(public element: ElementRef) {}

    ngAfterViewInit() {
      this.parseLinks();
    }

    ngOnChanges() {
        this.parseLinks();
    }

    private parseLinks() {
      const links = this.element.nativeElement.querySelectorAll('a');
      links.forEach( alink => {
        LinkParserDirective.parseLink(alink);
      });
    }
}
