import {createAction, props} from '@ngrx/store';
import {StaticPage} from '../../../api';
import {Predicate} from '@ngrx/entity';
import {OrderedStaticPage} from './staticPage.selectors';

export const upsertStaticPages = createAction('[StaticPages] Upsert static pages', props<{ staticPages: OrderedStaticPage[] }>());
export const upsertStaticPage = createAction('[StaticPages] Upsert one static page', props<{ staticPage: OrderedStaticPage }>());
export const clearStaticPages = createAction('[StaticPages] Clear static pages');
export const deleteStaticPageByPredicate =
  createAction('[StaticPages] Delete one static page by predicate', props<{ predicate: Predicate<StaticPage> }>());
