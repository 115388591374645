import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResourceStatusService} from '../resource.status.service';
import {Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {Status} from '../../../repository/status';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Mutex} from '../../../utils/mutex';
import {LoadingConfig} from '../../../repository/resource';

@Component({
    selector: 'app-loading-blocking',
    templateUrl: './loading-blocking.component.html',
    styleUrls: ['./loading-blocking.component.scss'],
})
export class LoadingBlockingComponent implements OnInit, OnDestroy {
    private loadingBlockingSubscription: Subscription;

    private readonly mutex: Mutex;
    private loadingOverlay;

    constructor(private resourceStatusService: ResourceStatusService,
                private loadingController: LoadingController,
                private translate: TranslateService) {
        this.loadingBlockingSubscription = Subscription.EMPTY;
        this.mutex = new Mutex();
    }

    ngOnInit() {
        this.loadingBlockingSubscription = this.resourceStatusService.loadingBlockingSuccessStatus$.pipe(
            delay(0),
        ).subscribe(async (status: LoadingConfig | Status.SUCCESS | Status.ERROR) => {
            const release = await this.mutex.acquire();
            try {
                if ((status as LoadingConfig).isBlocking) {
                    await this.presentBlockingLoading((status as LoadingConfig));
                }
                else if (status === Status.ERROR || status === Status.SUCCESS) {
                    await this.dismissBlockingLoading();
                }
            }
            finally {
                release();
            }
        });
    }

    ngOnDestroy(): void {
        this.loadingBlockingSubscription.unsubscribe();
    }

    async presentBlockingLoading(loadingConfig: LoadingConfig) {
        const loadingMessage = loadingConfig.loadingTextKey ?
            this.translate.instant(loadingConfig.loadingTextKey) : this.translate.instant('LOADING');
        this.loadingOverlay = await this.loadingController.create({
            spinner: 'circular',
            message: loadingMessage,
            translucent: false,
            cssClass: 'loading-modal'
        });
        await this.loadingOverlay.present();
    }

    async dismissBlockingLoading() {
        if (this.loadingOverlay != null) {
            await this.loadingOverlay.dismiss();
            this.loadingOverlay = null;
        }
    }
}
