import {createAction, props} from '@ngrx/store';
import {Event} from '../../../api';

export const upsertEventsInAllEvents = createAction('[All Events] Upsert events', props<{ events: Event[] }>());
export const upsertEventsInPublishedEvents = createAction('[Published Events] Upsert events', props<{ events: Event[] }>());
export const upsertEventsInArchivedEvents = createAction('[Archived Events] Upsert events', props<{ events: Event[] }>());

export const upsertAnEventInAllEvents = createAction('[All Events] Upsert one event', props<{ event: Event }>());

export const clearEventsInAllEvents = createAction('[All Events] Clear events');
export const clearEventsInPublishedEvents = createAction('[Published Events] Clear events');
export const clearEventsInArchivedEvents = createAction('[Archived Events] Clear events');
export const clearEventsInAnyEvents = createAction('[Any Events] Clear events');
