import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {News, NewsService} from '../../api';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {EventNews} from '../../store/reducers/eventNews/event-news.reducer';
import {selectAllEventNewsForAnEvent} from '../../store/reducers/eventNews/event-news.selectors';
import {clearAllEventNewsForAnEvent, upsertManyEventNews} from '../../store/reducers/eventNews/event-news.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetEventNews extends NetworkBoundResource<News[], News[]> {
    constructor(private store: Store<AppState>,
                private newsService: NewsService,
                private eventId: number,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<News[]> {
        return this.store.pipe(select(selectAllEventNewsForAnEvent, this.eventId));
    }

    protected saveNetworkResult(news: News[]) {
        // we first delete all the news
        this.store.dispatch(clearAllEventNewsForAnEvent({ eventId: this.eventId} ));
        // then we add them all
        const eventNews: EventNews[] = news.map((newsToMap: News): EventNews => {
            return {...newsToMap, eventId: this.eventId};
        });
        this.store.dispatch(upsertManyEventNews({ eventNews }));
    }

    protected shouldFetch(dataFromDatabase: News[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THIRTY_SECONDS,
                resourceId: this.constructor.name + '-' + this.eventId
            }
        };
    }

    protected startNetworkCall(): Observable<News[]> {
        return this.newsService.listNews(this.eventId);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_NEWS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId);
    }
}
