import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PublicSurveyQuestionsPage } from './public-survey-questions.page';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../../common/ui/directives/directives.module';
import {AppDirectivesModule} from '../../../directives/app.directives.module';
import {SurveyQuestionComponentModule} from '../../../components/survey-question/survey-question.module';
import {SurveyStarsComponentModule} from '../../../components/survey-stars/survey-stars.module';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        DirectivesModule,
        AppDirectivesModule,
        SurveyQuestionComponentModule,
        SurveyStarsComponentModule,
        HeaderComponentModule
    ],
    declarations: [PublicSurveyQuestionsPage]
})
export class PublicSurveyQuestionsPageModule {}
