import {Observable} from 'rxjs';
import {ApiPagesService, CffDeparture} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class GetCffApiPage extends JustNetworkResource<CffDeparture[], CffDeparture[]> {
    constructor(private apiPagesService: ApiPagesService) {
        super();
    }

    protected startNetworkCall(): Observable<CffDeparture[]> {
        return this.apiPagesService.cffApiPage();
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('API_ERROR_LABEL', true);
    }
}
