import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Notification, NotificationsService} from '../../api';
import {selectAllNotifications} from '../../store/reducers/notifications/notification.selectors';
import {upsertNotifications} from '../../store/reducers/notifications/notification.actions';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetNotifications extends NetworkBoundResource<Notification[], Notification[]> {
    constructor(private store: Store<AppState>,
                private notificationsService: NotificationsService,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Notification[]> {
        return this.store.pipe(select(selectAllNotifications));
    }

    protected saveNetworkResult(notifications: Notification[]) {
        this.store.dispatch(upsertNotifications({ notifications }));
    }

    protected shouldFetch(dataFromDatabase: Notification[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THIRTY_SECONDS,
                resourceId: this.constructor.name
            }
        };
    }

    protected startNetworkCall(): Observable<Notification[]> {
        return this.notificationsService.listNotifications();
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('NOTIFICATIONS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name);
    }
}
