import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {IS_DESKTOP, IS_MOBILE} from '../../../common/ui/directives/show.when';
import {Platform, ToastController} from '@ionic/angular';
import {Answer, AnswerCollection, Event, Question, Section, Survey} from '../../../api';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {EventsRepository} from '../../../repositories/events/events.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Resource} from '../../../common/repository/resource';
import {Status} from '../../../common/repository/status';
import {getToastOptionSuccess} from '../../common/constants';
import {delay, shareReplay} from 'rxjs/operators';

@Component({
    selector: 'public-survey-questions',
    templateUrl: './public-survey-questions.page.html',
    styleUrls: ['./public-survey-questions.page.scss'],
})
export class PublicSurveyQuestionsPage {

    @ViewChild('surveyContainer', { static: false }) surveyContainer: ElementRef;

    public eventId: number;
    public event: Event;
    public survey: Survey;
    public answers: Array<Answer>;

    public readonly isDesktop = IS_DESKTOP;
    public readonly isMobile = IS_MOBILE;

    constructor(private resourceStatusService: ResourceStatusService,
                private eventsRepository: EventsRepository,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private toastController: ToastController,
                private translateService: TranslateService,
                public platform: Platform,
                @Inject(ENV) public environment: Env) {
    }

    ionViewWillEnter(): void {
        this.setUpSurvey();
    }

    private setUpSurvey() {

        this.eventsRepository.getPublicSurvey(this.translateService.currentLang).pipe(
            delay(0),
            shareReplay(1)
        ).subscribe(async (surveyResource: Resource<Survey>) => {
            this.resourceStatusService.consumeResource(surveyResource);
            if (surveyResource.status === Status.ERROR){
                await this.router.navigate(['public-survey/welcome']);
            } else if (surveyResource.status === Status.SUCCESS) {
                surveyResource.data.sections = surveyResource.data.sections.filter((section, index, arr) => {
                    return section.questions.length > 0;
                });
                this.survey  = surveyResource.data;
                this.generateDefaultResponse();
            }
        });
    }

    // will only generate default response for star rating survey
    private generateDefaultResponse() {
        const sections = this.survey.sections;

        const concat = [].concat.apply([], sections.map((section: any) => {
            return section.questions
                .filter((question: any) => (question.type === 0))
                .map((question: any) => ({ question_id: question.id, value: 0}));
        }));
        this.answers = concat;
    }

    public onAnswerChange(triggeredAnswer: Answer) {

        const index = this.answers.map((answer) => {
            return answer.question_id;
        }).indexOf(triggeredAnswer.question_id);

        // if the first question is completed, then scroll to the other questions
        if (index === 0) {
            const nextQuestionsContainer = this.surveyContainer.nativeElement.querySelector('#otherQuestions');

            if (nextQuestionsContainer) {
                nextQuestionsContainer.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        }

        if (triggeredAnswer.value === 0) {
            this.answers.splice(index, 1);
        } else {
            if (index === -1) {
                this.answers.push(triggeredAnswer);
            } else {
                this.answers[index] = triggeredAnswer;
            }
        }

    }

    public onSubmitSurvey() {
        const answerCollection: AnswerCollection = {};
        answerCollection.answers = [];
        this.answers.forEach((answer, index) => {
            if (answer.value !== 0){
                answerCollection.answers.push(answer);
            }
        });
        this.eventsRepository.savePublicSurvey(answerCollection).subscribe(
            async (res: Resource<any>) => {
                this.resourceStatusService.consumeResource(res);
                if (res.status === Status.SUCCESS) {
                    const message = this.translateService.instant('SUBMIT_SUCCESS');
                    const toast = await this.toastController.create(getToastOptionSuccess(message));
                    await toast.present();
                    await this.router.navigate(['public-survey/welcome']);
                }
            });
    }

    public getSurveyFirstQuestion() {
        const firstSection = this.survey.sections.length > 0 ? this.survey.sections[0] : null;

        if (firstSection) {
            return firstSection.questions.length > 0 ? firstSection.questions[0] : null;
        }
    }

    public getSectionQuestionsWithoutQuestions(section: Section, questions: Array<Question>) {
        return section.questions.filter(q => !questions.some(question => question.id === q.id));
    }
}
