import {Notification} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as NotificationActions from './notification.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export function selectId(notification: Notification): number {
    return notification.id;
}

export function sortById(a: Notification, b: Notification): number {
    return b.id - a.id;
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
    selectId,
    sortComparer: sortById, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<Notification> = adapter.getInitialState();

const notificationsReducer = createReducer(
  initialState,
  on(NotificationActions.upsertNotifications, (state, { notifications }) => adapter.upsertMany(notifications, state)),
  on(NotificationActions.upsertNotification, (state, { notification }) => adapter.upsertOne(notification, state)),
  on(NotificationActions.clearNotifications, (state) => adapter.removeAll(state)),
  on(NotificationActions.deleteNotificationByPredicate, (state, { predicate }) => adapter.removeMany(predicate, state))
);

export function reducer(state: EntityState<Notification> | undefined, action: Action) {
    return notificationsReducer(state, action);
}
