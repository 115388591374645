import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { init as initSentryCapacitor, Replay } from '@sentry/capacitor';


if (environment.production) {
    initSentryCapacitor({
        dsn: 'https://967c593604d306012ab1f167ef7ce5c9@sentry.mobilethinking.ch/16',
        integrations: [
            new Replay(),
        ],
        enableNative: true,
        debug: environment.sentryEnv === 'staging',
        environment: environment.appName + '@' + environment.sentryEnv,
        release: '4.4.0',
        dist: environment.appName + '@' + environment.sentryEnv,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
      });

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

// added as instructed here https://capacitor.ionicframework.com/docs/pwa-elements/
defineCustomElements(window).then(() => {
    if (!environment.production) {
        console.log('Loaded Ionic PWA elements.');
    }
});
