<ng-container *ngIf="(pageContext$ | async) as ctx">
    <ng-container *ngIf="ctx.event as event">
        <app-header [titleText]="event.title"
                    [menuIconEnabled]="false"
                    [backArrowEnabled]="true"
                    defaultBackHref="events"
                    [adminIconEnabled]="true"
                    [profileIconEnabled]="true"
                    [event]="event" >
        </app-header>

        <div class="event-header-container" *ngIf="isDesktop">
            <div (click)="openLink(event.virtual_universe_link)"
                 [ngClass]="(event.cover_url === '/storage/' ||
                    event.cover_url === null ||
                    event.cover_url === '' ? 'event-header default_bg' : 'event-header')"
                 [ngStyle]="{'background-image': (event.cover_url === '/storage/' ||
                    event.cover_url === null ||
                    event.cover_url === '' ? '' : 'url(' + environment.storage_base_path + event.cover_url + ')')}">
            </div>
            <ion-badge *ngIf="event.virtual_universe_link" (click)="openLink(event.virtual_universe_link)" class="virtual-universe-badge">
                <ion-icon class="virtual-universe-badge-icon" src="./assets/imgs/virtual_universe.svg"></ion-icon>
                {{ 'VIRTUAL_UNIVERSE' | translate }}
            </ion-badge>
            <ion-badge class="card-ongoing-badge ion-margin"
                       *ngIf="ctx.isCurrentEvent && event.is_published">
                <img class="badge-icon" src="./assets/imgs/notification_clock.svg"/>
                {{'ONGOING_EVENT'| translate}}
            </ion-badge>
            <ion-badge class="card-ongoing-badge ion-margin card-ongoing-badge__unpublished"
                       *ngIf="!event.is_published">
                <img class="badge-icon" src="./assets/imgs/notification_clock.svg"/>
                {{'UNPUBLISHED_EVENT'| translate}}
            </ion-badge>
        </div>

        <ion-tabs class="event-tabs">
            <ion-tab-bar *ngIf="isDesktop" slot="top">
                <ion-tab-button tab="information" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_INFO'| translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="news" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_NEWS' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="sessions" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_PROGRAM' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button *ngIf="event.event_type_id !== 2"
                                tab="participants"
                                layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_PARTICIPANTS' | translate}}</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
            <ion-tab-bar *ngIf="!isDesktop" slot="bottom">
                <ion-tab-button tab="information" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_INFO'| translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="news" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_NEWS' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="sessions" layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_PROGRAM' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button *ngIf="event.event_type_id !== 2"
                                tab="participants"
                                layout="icon-hide">
                    <ion-label>{{'EVENT_TAB_PARTICIPANTS' | translate}}</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ng-container>
</ng-container>
