import {AppState} from '../../index';
import {adapter} from './notification.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {Notification} from '../../../api';

export const notificationsKey = 'notifications';

export const selectNotificationsState =
    createFeatureSelector<AppState, EntityState<Notification>>(notificationsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllNotifications = createSelector(
    selectNotificationsState,
    (state: EntityState<Notification>) => selectAll(state)
);

export const selectNotificationById = createSelector(
    selectAllNotifications,
    (state: Notification[], id: number) =>
        state.find(notification => notification.id === id)
);

export const selectUnreadNotifications = createSelector(
    selectAllNotifications,
    (state: Notification[]) =>
        state.filter(notification => !notification.read)
);

export const selectNumberOfUnreadNotifications = createSelector(
    selectUnreadNotifications,
    (state: Notification[]) => state.length
);

export const selectWelcomeNotification = createSelector(
    (state: AppState) => selectNotificationById(state, -1),
    (state: Notification) => state
);
