import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {AttractionDetail} from '../../../api';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {IonRefresher, Platform} from '@ionic/angular';
import {Browser} from '@capacitor/browser';
import {shareReplay, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-tourism-api',
    templateUrl: './tourism-api.page.html',
    styleUrls: ['./tourism-api.page.scss'],
})
export class TourismApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public attractions$: Observable<AttractionDetail[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noTourismDataLoading', {static: false}) noTourismDataLoading: LoadingNoDataComponent;

    public isAndroidOriOS: boolean;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform,
                private router: Router) {
        this.retry = new BehaviorSubject<boolean>(true);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit(): void {
        this.setUpAttractionObservable();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    public async openLink() {
        await Browser.open(
            {
                url: 'https://www.geneve.com',
                windowName: '_system'
            }
        );
    }

    public async openAttraction(attraction: AttractionDetail) {
        await this.router.navigate(['practical-information/tourism', attraction.id]);
    }

    private setUpAttractionObservable() {
        this.attractions$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap(() => {
                    return this.staticPagesRepository.getAttractionDetailsApiPage();
                }),
                completeIonRefresher(this.ionRefresher),
                manageLoadingNoData(this.noTourismDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
