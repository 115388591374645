import {Injectable} from '@angular/core';
import {concat, Observable, of, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {Resource} from '../../common/repository/resource';
import {
    AgendasService,
    AnswerCollection,
    Event,
    EventsService,
    InviteDataRequest,
    InviteToken,
    News,
    NewsService,
    Participant,
    ParticipantProfileUpdate,
    ParticipantSetting,
    ParticipantsService,
    ProfilesService,
    PublicSurvey,
    Session,
    SessionsService,
    SettingsService,
    Survey,
    SurveysService,
    TalksService,
    User,
    UserEmail
} from '../../api';
import {GetEventById} from './getEventById';
import {AppState} from '../../store';
import {clearEventsInAnyEvents} from '../../store/reducers/events/event.actions';
import {GetArchivedEvents} from './getArchivedEvents';
import {GetPublishedEvents} from './getPublishedEvents';
import {GetEventNews} from './getEventNews';
import {GetAnEventNewsById} from './getAnEventNewsById';
import {ParticipateWithEventCodeAnon} from './participateWithEventCodeAnon';
import {ParticipateWithEventCodeAuth} from './participateWithEventCodeAuth';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {AddEventSessionToCurrentUserEventParticipantAgenda} from './agenda/addEventSessionToCurrentUserEventParticipantAgenda';
import {RemoveEventSessionFromCurrentUserEventParticipantAgenda} from './agenda/removeEventSessionFromCurrentUserEventParticipantAgenda';
import {GetEventParticipants} from './participants/getEventParticipants';
import {GetEventParticipantByCurrentUser} from './participants/getEventParticipantByCurrentUser';
import {GetEventParticipantById} from './participants/getEventParticipantById';
import {GetEventSessions} from './sessions/getEventSessions';
import {GetEventParticipantTalks} from './sessions/getEventParticipantTalks';
import {GetEventParticipantAgendaById} from './agenda/getEventParticipantAgendaById';
import {GetEventParticipantAgendaByCurrentUser} from './agenda/getEventParticipantAgendaByCurrentUser';
import {clearAllEventNewsInAllEvents} from '../../store/reducers/eventNews/event-news.actions';
import {
    clearCurrentUserEventParticipants,
    clearEventParticipantsInAllEvents
} from '../../store/reducers/eventParticipants/event-participant.actions';
import {clearEventSessionsInAllEvents} from '../../store/reducers/eventSessions/event-session.actions';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {SendContactMessageToEventParticipant} from './participants/sendContactMessageToEventParticipant';
import {GetEventSessionById} from './sessions/getEventSessionById';
import {clearCurrentUserEventSettingsForAllEvents} from '../../store/reducers/eventSettings/event-setting.actions';
import {GetEventParticipantSettingsByEventId} from './setting/getEventParticipantSettingsByEventId';
import {UpdateEventParticipantSettingsByEventId} from './setting/updateEventParticipantSettingsByEventId';
import {UpdateEventParticipantForCurrentUser} from './participants/updateEventParticipantForCurrentUser';
import {DeleteEventParticipantPictureForCurrentUser} from './participants/deleteEventParticipantPictureForCurrentUser';
import {UpdateEventParticipantPictureForCurrentUser} from './participants/updateEventParticipantPictureForCurrentUser';
import {ParticipantInviteByToken} from './participants/ParticipantInviteByToken';
import {ValidateInviteByToken} from './participants/ValidateInviteByToken';
import {GetEventSurvey} from './survey/getEventSurvey';
import {SaveEventSurvey} from './survey/saveEventSurvey';
import {GetPublicSurvey} from './survey/getPublicSurvey';
import {SavePublicSurvey} from './survey/savePublicSurvey';
import {Status} from '../../common/repository/status';
import {takeFirstSuccessOrErrorAndComplete} from '../../common/rxjs/operators';
import {UserRepository} from '../user/user.repository';
import {AuthSessionRepository} from '../auth/authSession.repository';
import {generateRandomPassword} from '../../common/utils/functions';

export class CurrentUserIsNotParticipantError implements Error {
    public message: string;
    public name = 'CurrentUserIsNotParticipantError';

    constructor(event: Event) {
        this.message = 'The current user is not a participant to the event ' + event.id;
    }
}

@Injectable({
    providedIn: 'root'
})
export class EventsRepository {
    public static isCurrentUserEventParticipant(event: Event): boolean {
        return event.roles.indexOf('participant') > -1;
    }

    private static getNoParticipantErrorResourceObservable<T>(): Observable<Resource<T>> {
        const userFriendlyError = UserFriendlyError.displayableAsToast('NO_PARTICIPANT_TOAST', false);
        return of(Resource.error('getNoParticipantErrorResourceObservable', userFriendlyError));
    }

    constructor(private store: Store<AppState>,
                private eventsService: EventsService,
                private newsService: NewsService,
                private participantsService: ParticipantsService,
                private profileService: ProfilesService,
                private sessionService: SessionsService,
                private talksService: TalksService,
                private agendasService: AgendasService,
                private settingsService: SettingsService,
                private surveysService: SurveysService,
                private userRepository: UserRepository,
                private authSessionRepository: AuthSessionRepository) {}

    // EVENTS

    // getAllEvents(refresh: boolean): Observable<Resource<Event[]>> {
    //     const getAllEvents = new GetAllEvents(this.store, this.eventsService, refresh);
    //     return getAllEvents.fetch();
    // }

    getPublishedEvents(refresh: boolean = false): Observable<Resource<Event[]>> {
        const getPublishedEvents = new GetPublishedEvents(this.store, this.eventsService, refresh);
        return getPublishedEvents.fetch();
    }

    getArchivedEvents(): Observable<Resource<Event[]>> {
        const getArchivedEvents = new GetArchivedEvents(this.store, this.eventsService);
        return getArchivedEvents.fetch();
    }

    getEventById(id: number): Observable<Resource<Event>> {
        const getEventById = new GetEventById(this.store, this.eventsService, id);
        return getEventById.fetch();
    }

    getEventByCode(code: string): Observable<Resource<number>> {
        return concat(of(Resource.loadingBlocking<number>('getEventByCode')), this.userRepository.isLoggedIn().pipe(
            take(1), // we take only one to avoid that changes restart the chain
            switchMap((logged: boolean): Observable<Resource<number>> => {
                if (logged) {
                    return this.participateWithCodeAuth(code);
                }
                else {
                    return this.participateWithCodeAnon(code).pipe(
                        takeFirstSuccessOrErrorAndComplete(),
                        switchMap((resourceInviteToken: Resource<InviteToken>): Observable<Resource<number>> => {
                            if (resourceInviteToken.status === Status.SUCCESS) {
                                // tslint:disable-next-line:radix
                                const eventId: number = parseInt(resourceInviteToken.data.event_id);
                                const password = generateRandomPassword(13);
                                const newData: InviteDataRequest = {
                                    password,
                                    password_confirmation: password
                                };
                                return this.participantInviteByToken(resourceInviteToken.data.invite_token, newData).pipe(
                                    takeFirstSuccessOrErrorAndComplete(),
                                    switchMap((resourceEmail: Resource<string>): Observable<Resource<boolean>> => {
                                        if (resourceEmail.status === Status.SUCCESS) {
                                            return this.authSessionRepository.login(resourceEmail.data, password);
                                        }
                                        else {
                                            return of(Resource.noDataTransform(resourceEmail));
                                        }
                                    }),
                                    takeFirstSuccessOrErrorAndComplete(),
                                    switchMap((loginResultResource: Resource<boolean>): Observable<Resource<User>> => {
                                        if (loginResultResource.status === Status.SUCCESS) {
                                            return this.userRepository.loadUser();
                                        }
                                        else {
                                            return of(Resource.noDataTransform(loginResultResource));
                                        }
                                    }),
                                    takeFirstSuccessOrErrorAndComplete(),
                                    switchMap((userResource: Resource<User>): Observable<Resource<number>> => {
                                        if (userResource.status === Status.SUCCESS) {
                                            return of(Resource.successBlocking('getEventByCode', eventId));
                                        }
                                        else {
                                            return of(Resource.noDataTransform(userResource));
                                        }
                                    })
                                );
                            }
                            else {
                                return of(Resource.noDataTransform(resourceInviteToken));
                            }
                        }),
                    );
                }
            }),
            takeFirstSuccessOrErrorAndComplete(),
            switchMap((eventIdResource: Resource<number>): Observable<Resource<number>> => {
                if (eventIdResource.status === Status.SUCCESS) {
                    return this.getPublishedEvents(true).pipe(
                        takeFirstSuccessOrErrorAndComplete(),
                        switchMap((eventsResource: Resource<Event[]>): Observable<Resource<number>> => {
                            if (eventsResource.status === Status.SUCCESS) {
                                return of(Resource.fromResource(eventIdResource, 'getEventByCode'));
                            }
                            else {
                                return of(Resource.noDataTransform(Resource.fromResource(eventsResource, 'getEventByCode')));
                            }
                        })
                    );
                }
                else {
                    return of(Resource.fromResource(eventIdResource, 'getEventByCode'));
                }
            })
        ));
    }

    clearEvents(): void {
        return this.store.dispatch(clearEventsInAnyEvents());
    }

    // EVENT NEWS

    getNewsForEvent(eventId: number, refresh: boolean = false): Observable<Resource<News[]>> {
        const getEventNews = new GetEventNews(this.store, this.newsService, eventId, refresh);
        return getEventNews.fetch();
    }

    getNewsByIdForEvent(eventId: number, newsId: number): Observable<Resource<News>> {
        const getAnEventNewsById = new GetAnEventNewsById(this.store, this.newsService, eventId, newsId);
        return getAnEventNewsById.fetch();
    }

    clearNewsForAllEvents(): void {
        return this.store.dispatch(clearAllEventNewsInAllEvents());
    }

    // EVENT PARTICIPANTS

    getParticipantsForEvent(eventId: number, refresh: boolean = false): Observable<Resource<Participant[]>> {
        const getEventParticipants = new GetEventParticipants(this.store, this.participantsService, eventId, refresh);
        return getEventParticipants.fetch();
    }

    getParticipantByIdForEvent(eventId: number,
                               participantId: number,
                               forceRefresh: boolean = false): Observable<Resource<Participant>> {
        const getEventParticipantById = new GetEventParticipantById(this.store,
            this.participantsService, eventId, participantId, forceRefresh);
        return getEventParticipantById.fetch();
    }

    clearParticipantsForAllEvents(): void {
        return this.store.dispatch(clearEventParticipantsInAllEvents());
    }

    sendContactMessageToParticipant(eventId: number, participantId: number, message: string): Observable<Resource<any>> {
        const sendContactMessageToEventParticipant = new SendContactMessageToEventParticipant(this.participantsService,
            eventId, participantId, message);
        return sendContactMessageToEventParticipant.call();
    }

    // EVENT SESSIONS

    getSessionsForEvent(eventId: number, forceRefresh: boolean = false): Observable<Resource<Session[]>> {
        const getEventSessions = new GetEventSessions(this.store, this.sessionService, eventId, forceRefresh);
        return getEventSessions.fetch();
    }

    getSessionByIdForEvent(eventId: number, sessionId: number): Observable<Resource<Session>> {
        const getEventSessionById = new GetEventSessionById(this.store, this.sessionService, eventId, sessionId);
        return getEventSessionById.fetch();
    }

    clearSessionsForAllEvents(): void {
        return this.store.dispatch(clearEventSessionsInAllEvents());
    }

    // EVENT PARTICIPANTS + SESSIONS/AGENDA

    getParticipantTalks(eventId: number,
                        participant: Participant): Observable<Resource<Session[]>> {
        if (participant.is_speaker) {
            const getEventSessionsByParticipantIdAsSpeaker =
                new GetEventParticipantTalks(this.store, this.talksService, eventId, participant.id);
            return getEventSessionsByParticipantIdAsSpeaker.fetch();
        }
        else {
            return of(Resource.success(GetEventParticipantTalks.name, []));
        }
    }

    getParticipantAgenda(eventId: number,
                         participant: Participant): Observable<Resource<Session[]>> {
        if (participant.is_agenda_visible) {
            const getEventParticipantAgendaById = new GetEventParticipantAgendaById(this.store,
                this.agendasService, eventId, participant.id);
            return getEventParticipantAgendaById.fetch();
        }
        else {
            return of(Resource.success(GetEventParticipantAgendaById.name, []));
        }
    }

    // EVENT PARTICIPANT FOR CURRENT USER

    updateParticipantForCurrentUser(eventId: number,
                                    profileUpdate: ParticipantProfileUpdate): Observable<Resource<any>> {
        const updateEventParticipantForCurrentUser =
            new UpdateEventParticipantForCurrentUser(this.profileService, eventId, profileUpdate);
        return updateEventParticipantForCurrentUser.call();
    }

    updateParticipantPictureForCurrentUser(eventId: number, picture: Blob): Observable<Resource<any>> {
        const updateParticipantPictureForCurrentUser =
            new UpdateEventParticipantPictureForCurrentUser(this.profileService, eventId, picture);
        return updateParticipantPictureForCurrentUser.call();
    }

    deleteParticipantPictureForCurrentUser(eventId: number): Observable<Resource<any>> {
        const deleteEventParticipantPictureForCurrentUser =
            new DeleteEventParticipantPictureForCurrentUser(this.profileService, eventId);
        return deleteEventParticipantPictureForCurrentUser.call();
    }

    // All the following methods must ensure that the current user is participant to the event.
    // They can either throw an error or do an alternative action to provide the data declared by the observable
    // that they return. Please never return null data in Resource success unexpectedly.

    getParticipantForCurrentUserForEvent(event: Event, forceRefresh: boolean = false): Observable<Resource<Participant>> {
        if (EventsRepository.isCurrentUserEventParticipant(event)) {
            const getEventParticipantByCurrentUser = new GetEventParticipantByCurrentUser(this.store,
                this.profileService, event.id, forceRefresh);
            return getEventParticipantByCurrentUser.fetch();
        }
        else {
            return throwError(new CurrentUserIsNotParticipantError(event));
        }
    }

    getParticipantsForEventWithoutCurrentUserParticipant(event: Event, refresh: boolean): Observable<Resource<Participant[]>> {
        return this.getParticipantForCurrentUserForEvent(event).pipe(
            switchMap((currentUserParticipantResource: Resource<Participant>) => {
                if (currentUserParticipantResource.data == null) {
                    return of(
                        Resource.fromResource(
                            Resource.noDataTransform<Participant, Participant[]>(currentUserParticipantResource), 'participants')
                    );
                }
                else {
                    return this.getParticipantsForEvent(event.id, refresh).pipe(
                        map((participantsResource: Resource<Participant[]>) => {
                            if (participantsResource.data != null) {
                                const index: number = participantsResource.data.findIndex((participant: Participant) => {
                                    return participant.id === currentUserParticipantResource.data.id;
                                });
                                if (index > -1) {
                                    participantsResource.data.splice(index, 1);
                                }
                            }
                            return Resource.fromResource(participantsResource, 'participants');
                        })
                    );
                }
            }),
            catchError((error: Error) => {
                if (error instanceof CurrentUserIsNotParticipantError) {
                    // we are not participant to the event so we simply get all the participants without removing
                    // the current one
                    return this.getParticipantsForEvent(event.id, refresh);
                }
                throw error;
            })
        );
    }

    getParticipantAgendaForCurrentUserThrowsIfNotParticipant(event: Event): Observable<Resource<Session[]>> {
        if (EventsRepository.isCurrentUserEventParticipant(event)) {
            const getEventParticipantAgendaByCurrentUser =
                new GetEventParticipantAgendaByCurrentUser(this.store, this.agendasService, event.id);
            return getEventParticipantAgendaByCurrentUser.fetch();
        }
        else {
            return throwError(new CurrentUserIsNotParticipantError(event));
        }
    }

    getParticipantAgendaForCurrentUser(event: Event): Observable<Resource<Session[]>> {
        return this.getParticipantAgendaForCurrentUserThrowsIfNotParticipant(event).pipe(
            catchError((error: Error) => {
                if (error instanceof CurrentUserIsNotParticipantError) {
                    return of(Resource.success<Session[]>('getParticipantAgendaForCurrentUser', []));
                }
                return of(Resource.error<Session[]>('getParticipantAgendaForCurrentUser', error));
            })
        );
    }

    addSessionToParticipantAgendaForCurrentUser(event: Event, session: Session): Observable<Resource<Session>> {
        if (EventsRepository.isCurrentUserEventParticipant(event)) {
            const addEventSessionToCurrentUserEventParticipantAgenda =
                new AddEventSessionToCurrentUserEventParticipantAgenda(this.store,
                    this.agendasService, event.id, session);
            return addEventSessionToCurrentUserEventParticipantAgenda.call();
        }
        else {
            return EventsRepository.getNoParticipantErrorResourceObservable();
        }
    }

    removeSessionFromParticipantAgendaForCurrentUser(event: Event, session: Session): Observable<Resource<Session>> {
        if (EventsRepository.isCurrentUserEventParticipant(event)) {
            const removeEventSessionFromCurrentUserEventParticipantAgenda =
                new RemoveEventSessionFromCurrentUserEventParticipantAgenda(this.store,
                    this.agendasService, event.id, session);
            return removeEventSessionFromCurrentUserEventParticipantAgenda.call();
        }
        else {
            return EventsRepository.getNoParticipantErrorResourceObservable();
        }
    }

    clearParticipantsForCurrentUser(): void {
        this.store.dispatch(clearCurrentUserEventParticipants());
    }

    // CURRENT USER EVENT SETTINGS

    getEventSettingsForCurrentUser(event: Event): Observable<Resource<ParticipantSetting>> {
        if (EventsRepository.isCurrentUserEventParticipant(event)) {
            const getEventParticipantSettingsByEventId =
                new GetEventParticipantSettingsByEventId(this.store, this.settingsService, event.id);
            return getEventParticipantSettingsByEventId.fetch();
        }
        else {
            return throwError(new CurrentUserIsNotParticipantError(event));
        }
    }

    updateEventSettingsForCurrentUser(eventId: number, participantSetting: ParticipantSetting):
        Observable<Resource<ParticipantSetting>> {
        const settingsObject = {
            is_visible: !!participantSetting.is_visible,
            is_agenda_visible: !!participantSetting.is_agenda_visible,
            is_contactable: !!participantSetting.is_contactable,
            is_push_notification_enabled: !!participantSetting.is_push_notification_enabled,
            is_email_notification_enabled: !!participantSetting.is_email_notification_enabled
        };
        const updateEventParticipantSettingsByEventId =
            new UpdateEventParticipantSettingsByEventId(this.store, this.settingsService, eventId, settingsObject);
        return updateEventParticipantSettingsByEventId.call();
    }

    clearAllEventSettingsForCurrentUser(): void {
        this.store.dispatch(clearCurrentUserEventSettingsForAllEvents());
    }

    private participateWithCodeAnon(eventCode: string): Observable<Resource<InviteToken>>{
        const participateWithEventCodeAnon = new ParticipateWithEventCodeAnon(this.participantsService, eventCode);
        return participateWithEventCodeAnon.call();
    }

    private participateWithCodeAuth(eventCode: string): Observable<Resource<number>> {
        const participateWithEventCodeAuth = new ParticipateWithEventCodeAuth(this.participantsService, eventCode);
        return participateWithEventCodeAuth.call();
    }

    participantInviteByToken(inviteId: string, newData: InviteDataRequest): Observable<Resource<string>> {
        const participantInviteByToken = new ParticipantInviteByToken(this.participantsService, inviteId, newData);
        return participantInviteByToken.call();
    }

    validateInviteByToken(inviteId: string): Observable<Resource<UserEmail>> {
        const validateInviteByToken = new ValidateInviteByToken(this.participantsService, inviteId);
        return validateInviteByToken.call();
    }

    getEventSurvey(eventId: number): Observable<Resource<Survey>> {
        const getEventSurvey = new GetEventSurvey(this.surveysService, eventId);
        return getEventSurvey.call();
    }

    saveEventSurvey(eventId: number, surveyAnswers: AnswerCollection): Observable<Resource<any>> {
        const saveEventSurvey = new SaveEventSurvey(this.surveysService, eventId, surveyAnswers);
        return saveEventSurvey.call();
    }

    getPublicSurvey(lang: string): Observable<Resource<PublicSurvey>> {
        const getPublicSurvey = new GetPublicSurvey(this.surveysService, lang);
        return getPublicSurvey.call();
    }

    savePublicSurvey(surveyAnswers: AnswerCollection): Observable<Resource<any>> {
        const savePublicSurvey = new SavePublicSurvey(this.surveysService, surveyAnswers);
        return savePublicSurvey.call();
    }

}
