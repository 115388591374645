import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserRepository} from '../../../repositories/user/user.repository';
import {Resource} from '../../../common/repository/resource';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {consumeOnlySuccess} from '../../../common/rxjs/operators';
import {getToastOptionSuccess} from '../../common/constants';

@Component({
    selector: 'app-password-forgotten',
    templateUrl: './password-forgotten.page.html',
    styleUrls: ['./password-forgotten.page.scss'],
})
export class PasswordForgottenPage implements AfterViewInit, OnDestroy {
    public passwordRecoveryForm: FormGroup;
    private requestResetPasswordSubscription: Subscription;

    constructor(private userRepository: UserRepository,
                private toastController: ToastController,
                private resourceStatusService: ResourceStatusService,
                private translateService: TranslateService,
                private router: Router) {
        this.passwordRecoveryForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
        this.requestResetPasswordSubscription = Subscription.EMPTY;
    }

    ngAfterViewInit(): void {
    //     this.resourceStatusService.consumeResource(Resource.success(), 'resetLink');
    }

    ngOnDestroy(): void {
        this.requestResetPasswordSubscription.unsubscribe();
    }

    public recoverPassword() {
        this.requestResetPasswordSubscription =
            this.userRepository.requestResetPassword(this.passwordRecoveryForm.value.email).pipe(
                consumeOnlySuccess(this.resourceStatusService)
            ).subscribe(async () => {
                const message = getToastOptionSuccess(this.translateService.instant('EMAIL_SENT'));
                const toast = await this.toastController.create(message);
                await toast.present();
                await this.router.navigate(['/home']);
            });
    }

    public submit() {
        if (this.passwordRecoveryForm.valid) {
            this.recoverPassword();
        }
    }

}
