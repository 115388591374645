import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {MenuEventCardComponent} from './menu-event-card.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [MenuEventCardComponent],
  exports: [MenuEventCardComponent]
})
export class MenuEventCardComponentModule {}
