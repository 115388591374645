import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import { SurveyStarsComponent } from './survey-stars';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
@NgModule({
  declarations: [
    SurveyStarsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  exports: [
    SurveyStarsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SurveyStarsComponentModule {}
