import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {NewsApiPage} from './news-api.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {AppDirectivesModule} from '../../../directives/app.directives.module';
import {NewsApiDetailsPage} from './news-api-details/news-api-details.page';
import {DirectivesModule} from '../../../common/ui/directives/directives.module';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HeaderComponentModule,
        IonicModule,
        TranslateModule,
        AppDirectivesModule,
        DirectivesModule,
        UiStateManagementModule
    ],
  declarations: [NewsApiPage, NewsApiDetailsPage]
})
export class NewsApiPageModule {}
