import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Resource} from '../../common/repository/resource';
import {
    ApiPagesService,
    AttractionDetail,
    CffDeparture,
    CicgNews,
    DailyMenu,
    Room,
    RoomDetail,
    StaticPage,
    StaticPagesService,
    TpgDeparture
} from '../../api';
import {GetStaticPages} from './getStaticPages';
import {GetStaticPageBySlug} from './getStaticPageBySlug';
import {AppState} from '../../store';
import {GetTpgApiPage} from './getTpgApiPage';
import {GetCffApiPage} from './getCffApiPage';
import {GetRestaurantApiPage} from './getRestaurantApiPage';
import {UserRepository} from '../user/user.repository';
import {switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {GetNewsApiPage} from './getNewsApiPage';
import {GetRoomsApiPage} from './getRoomsApiPage';
import {GetRoomDetailApiPage} from './getRoomDetailApiPage';
import {GetAttractionDetailsApiPage} from './getAttractionDetailsApiPage';
import {GetAttractionDetailApiPage} from './getAttractionDetailApiPage';
import {GetNewsDetailApiPage} from './getNewsDetailApiPage';
import {GetRoomApiPage} from './getRoomApiPage';

@Injectable({
    providedIn: 'root'
})
export class StaticPagesRepository {

    constructor(private store: Store<AppState>,
                private staticPagesService: StaticPagesService,
                private apiPagesService: ApiPagesService,
                private userRepository: UserRepository,
                private translate: TranslateService) {}

    getStaticPages(refresh: boolean = false): Observable<Resource<StaticPage[]>> {
        const getStaticPages = new GetStaticPages(this.store, this.staticPagesService, refresh);
        return getStaticPages.fetch();
    }

    getStaticPageBySlug(slug: string): Observable<Resource<StaticPage>> {
        const getStaticPageBySlug = new GetStaticPageBySlug(this.store, this.staticPagesService, slug);
        return getStaticPageBySlug.fetch();
    }

    getTpgApiPage(): Observable<Resource<TpgDeparture[]>> {
        const getTpgApiPage = new GetTpgApiPage(this.apiPagesService);
        return getTpgApiPage.call();
    }

    getCffApiPage(): Observable<Resource<CffDeparture[]>> {
        const getCffApiPage = new GetCffApiPage(this.apiPagesService);
        return getCffApiPage.call();
    }

    getRestaurantApiPage(): Observable<Resource<DailyMenu[]>> {
        // get lang of the user first
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getRestaurantApiPage = new GetRestaurantApiPage(this.apiPagesService, currentLang);
                    return getRestaurantApiPage.call();
                })
            );
    }

    getNewsApiPage(refresh: boolean): Observable<Resource<CicgNews[]>> {
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getNewsApiPage = new GetNewsApiPage(this.store, this.apiPagesService, currentLang, refresh);
                    return getNewsApiPage.fetch();
                })
            );
    }

    getNewsApiPageById(id: string): Observable<Resource<CicgNews>> {
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getNewsDetailApiPage = new GetNewsDetailApiPage(this.store, this.apiPagesService, id, currentLang);
                    return getNewsDetailApiPage.fetch();
                })
            );
    }

    getRoomsApiPage(refresh: boolean): Observable<Resource<Room[]>> {
        const getRoomsApiPage = new GetRoomsApiPage(this.store, this.apiPagesService, refresh);
        return getRoomsApiPage.fetch();
    }

    getRoomApiPageById(id: number): Observable<Resource<Room>> {
        const getRoomApiPage = new GetRoomApiPage(this.store, this.apiPagesService, id);
        return getRoomApiPage.fetch();
    }

    getRoomDetailApiPageByExternalId(externalId: number): Observable<Resource<RoomDetail>> {
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getRoomDetailApiPage = new GetRoomDetailApiPage(this.apiPagesService, currentLang, externalId);
                    return getRoomDetailApiPage.call();
                })
            );
    }

    getAttractionDetailsApiPage(): Observable<Resource<AttractionDetail[]>> {
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getAttractionDetailsApiPage = new GetAttractionDetailsApiPage(this.apiPagesService, currentLang);
                    return getAttractionDetailsApiPage.call();
                })
            );
    }

    getAttractionDetailApiPageById(id: number): Observable<Resource<AttractionDetail>> {
        return this.userRepository.getCurrentUserLang()
            .pipe(
                switchMap((lang: string) => {
                    const currentLang = lang || this.translate.currentLang;
                    const getAttractionDetailApiPage = new GetAttractionDetailApiPage(this.apiPagesService, currentLang, id);
                    return getAttractionDetailApiPage.call();
                })
            );
    }
}
