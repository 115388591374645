import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ShowWhenDirective} from './show.when';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [ShowWhenDirective],
  exports: [
    ShowWhenDirective
  ]
})
export class DirectivesModule {}
