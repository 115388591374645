import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './pages/home/home.module#HomePageModule'
    },
    {
        path: 'settings',
        loadChildren: './pages/settings/settings.module#SettingsPageModule'
    },
    {
        path: 'about',
        loadChildren: './pages/about/about.module#AboutPageModule'
    },
    { path: '**',
        redirectTo: '/home'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            {
                preloadingStrategy: PreloadAllModules,
                enableTracing: false, // <-- debugging purposes only
                useHash: true,
                paramsInheritanceStrategy: 'always'
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
