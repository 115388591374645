import {Session} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as EventSessionsActions from './event-session.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface EventSession extends Session {
    eventId: number;
}

export function selectId(eventSession: EventSession): number {
    return eventSession.id;
}

export const adapter: EntityAdapter<EventSession> = createEntityAdapter<EventSession>({
    selectId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<EventSession> = adapter.getInitialState();

const eventSessionsReducer = createReducer(
    initialState,
    on(EventSessionsActions.upsertEventSessions, (state, { eventSessions }) =>
        adapter.upsertMany(eventSessions, state)),
    on(EventSessionsActions.upsertEventSession, (state, { eventSession }) =>
        adapter.upsertOne(eventSession, state)),
    on(EventSessionsActions.clearEventSessionsForAnEvent, (state, { eventId }) =>
        adapter.removeMany((eventSession: EventSession) => {
            return eventSession.eventId === eventId;
        }, state)),
    on(EventSessionsActions.clearEventSessionsInAllEvents, (state) => adapter.removeAll(state))
);

export function reducer(state: EntityState<EventSession> | undefined, action: Action) {
    return eventSessionsReducer(state, action);
}
