import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {Room} from '../../../api';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {IonRefresher, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {shareReplay, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {executeOpenRoom} from '../../common/commons';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-rooms-api',
    templateUrl: './rooms-api.page.html',
    styleUrls: ['./rooms-api.page.scss'],
})
export class RoomsApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public rooms$: Observable<Room[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noRoomsDataLoading', {static: false}) noRoomsDataLoading: LoadingNoDataComponent;

    public isAndroidOriOS: boolean;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform,
                private router: Router,
                public translate: TranslateService) {
        this.retry = new BehaviorSubject<boolean>(false);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit(): void {
        this.setUpRoomsObservable();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    public async openRoom(room: Room) {
        await executeOpenRoom(room, this.router, this.translate);
    }

    private setUpRoomsObservable() {
        this.rooms$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap((refresh: boolean) => {
                    return this.staticPagesRepository.getRoomsApiPage(refresh);
                }),
                completeIonRefresher(this.ionRefresher),
                manageLoadingNoData(this.noRoomsDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
