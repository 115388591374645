import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {NotificationDetailPage} from './notification-detail.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {FileCarrouselComponentModule} from '../../../components/file-carrousel/file-carrousel.module';
import {TranslateModule} from '@ngx-translate/core';
import {AppDirectivesModule} from '../../../directives/app.directives.module';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderComponentModule,
        FileCarrouselComponentModule,
        TranslateModule,
        AppDirectivesModule,
        UiStateManagementModule
    ],
  declarations: [NotificationDetailPage]
})
export class NotificationDetailPageModule {}
