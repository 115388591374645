import {Observable, OperatorFunction} from 'rxjs';
import {Resource} from '../../../../common/repository/resource';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Event, Session} from '../../../../api';
import {EventsRepository} from '../../../../repositories/events/events.repository';
import * as moment from 'moment-timezone';

export function prepareEventResource(route: ActivatedRoute,
                                     eventsRepository: EventsRepository): OperatorFunction<any, Resource<Event>> {
    return input$ => input$.pipe(
        switchMap(() => {
            return route.paramMap;
        }),
        switchMap((params: ParamMap): Observable<Resource<Event>> => {
            // tslint:disable-next-line:radix
            const id = parseInt(params.get('id'));
            return eventsRepository.getEventById(id);
        })
    );
}

export function isCurrentEvent(): OperatorFunction<Event, boolean> {
    return input$ => input$.pipe(
        map((event: Event) => {
            return isCurrent(event);
        })
    );
}

export function isCurrent(event: Event): boolean {
    const eventStartDate = moment(event.public_start_date);
    const eventEndDate = moment(event.public_end_date + ' 23:59:59');
    return moment().tz('Europe/Zurich')
        .isBetween(eventStartDate, eventEndDate, null, '[]');
}

export function agendaSessionsToIds(): OperatorFunction<Session[], number[]> {
    return input$ => input$.pipe(
        map((agendaSessions: Session[]) => {
            return agendaSessions.map((agendaSession: Session) => {
                return agendaSession.id;
            });
        }),
    );
}
