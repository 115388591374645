<app-header titleKey="CICG_NEWS_LABEL" [titleInterpolation]="{client_name: environment.appName}" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>

    <div id="news-api-page" class="practical_info_page">
        <div *ngIf="cicgNews$ && (cicgNews$ | async)?.length > 0">
            <ion-card id="cicg-news-{{i}}"
                      class="news-card ion-no-padding ion-margin-bottom"
                      *ngFor="let cicgNews of (cicgNews$ | async); let i = index"
                      (click)="openNews(cicgNews)">
                <div [ngClass]="!cicgNews.image || 0 === cicgNews.image.length ? 'no-img-news' : 'img-news'"
                     [ngStyle]="{'background-image': (!cicgNews.image || 0 === cicgNews.image.length ? '' : 'url(' + cicgNews.image + ')')}">
                </div>
                <ion-card-content class="news-card-content ion-text-wrap ion-padding-top">
                    <ion-text color="primary">
                        <h4 *ngIf="cicgNews.date && cicgNews.date.length > 2">
                            {{ cicgNews.date }}
                        </h4>
                    </ion-text>
                    <h1>{{cicgNews.title}}</h1>
                    <hr class="ion-margin-top ion-margin-bottom">
                    <p appLinkParser [innerHTML]="cicgNews.body"></p>
                    <ion-item lines="none" class="ion-text-end">
                        <ion-label>
                            <ion-text color="primary">
                                <h4 class="ion-text-end">
                                    {{"NEWS_READ_MORE" | translate}}
                                </h4>
                            </ion-text>
                        </ion-label>
                    </ion-item>

                </ion-card-content>
            </ion-card>
        </div>

        <!--  LOADING TEMPLATE  -->
        <app-loading-no-data #noNewsDataLoading>
            <ion-card class="news-card ion-no-padding ion-margin-bottom">
                <div class="empty-no-img-news">
                    <ion-skeleton-text class="empty-full-cover" animated></ion-skeleton-text>
                </div>
                <ion-card-content class="news-card-content ion-text-wrap ion-padding-top">
                    <ion-text color="primary">
                        <ion-skeleton-text animated style="width: 30%; line-height: 16px;"></ion-skeleton-text>
                    </ion-text>
                    <ion-skeleton-text animated style="width: 50%; line-height: 18px;"></ion-skeleton-text>
                    <hr class="ion-margin-top ion-margin-bottom">
                    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 95%"></ion-skeleton-text>
                </ion-card-content>
            </ion-card>
        </app-loading-no-data>

        <span id="practical-information-empty-list"
              *ngIf="cicgNews$ && (cicgNews$ | async)?.length === 0">
            {{'API_NO_RESULTS' | translate}}
        </span>
    </div>
</ion-content>
