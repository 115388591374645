<div class="notif-div">
    <ion-card class="item">
        <ion-item lines="full">
            <ion-thumbnail slot="start">
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
                <h1>
                    <ion-skeleton-text animated style="width: 80%; line-height: 18px;"></ion-skeleton-text>
                </h1>
                <p>
                    <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                </p>
            </ion-label>
        </ion-item>
        <ion-item lines="none">
            <ion-skeleton-text animated style="width: 70%; line-height: 24px;"></ion-skeleton-text>
        </ion-item>
        <ion-item lines="none">
            <div class="notif-text">
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 95%"></ion-skeleton-text>
            </div>
        </ion-item>
    </ion-card>
</div>

