import {Component, Inject} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.page.html',
    styleUrls: ['./splash.page.scss'],
})
export class SplashPage {

    constructor(private modalController: ModalController,
                @Inject(ENV) public environment: Env) { }

    async ionViewDidEnter() {
        setTimeout(async () => {
            await SplashScreen.hide();
            setTimeout(async () => {
                await this.modalController.dismiss();
            }, 2000);
        }, 1000);
    }

}
