import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {RoomsApiPage} from './rooms-api.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {RoomApiDetailsPage} from './room-api-details/room-api-details.page';
import {DirectivesModule} from '../../../common/ui/directives/directives.module';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderComponentModule,
        TranslateModule,
        DirectivesModule,
        NgxIonicImageViewerModule,
        UiStateManagementModule
    ],
  declarations: [RoomsApiPage, RoomApiDetailsPage]
})
export class RoomsApiPageModule {}
