<app-header titleKey="REGISTER_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true" defaultBackHref="home"></app-header>

<ion-content>
    <div class="ion-text-center ion-align-items-center" id="recover-password-container">
        <p class="user-element" id="email-label">{{'EMAIL_LABEL' | translate: {email: email} }}</p>
        <hr>
        <form [formGroup]="passwordRegisterForm">
            <ion-item class="ion-margin-bottom">
                <ion-label id="password-label" position="floating">{{'PASSWORD_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required
                           id="password-input"
                           type="password"
                           formControlName="password"
                           autocomplete="new-password"
                           clearOnEdit="false"
                           (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item lines="none" id="pwd-length-error" *ngIf="passwordRegisterForm.controls.password.hasError('minlength') && passwordRegisterForm.controls.password.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_LENGTH_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-item lines="none" id="pwd-pattern-error" *ngIf="passwordRegisterForm.controls.password.hasError('pattern') && passwordRegisterForm.controls.password.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_FORMAT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-item class="ion-margin-bottom">
                <ion-label id="confirmPassword-label" position="floating">{{'PASSWORD_VERIFY_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required
                           id="confirmPassword-input"
                           type="password"
                           formControlName="password_confirmation"
                           autocomplete="new-password"
                           clearOnEdit="false"
                           (keyup.enter)="submit()">
                </ion-input>
            </ion-item>
            <ion-item lines="none" id="pwd-not-matching" *ngIf="passwordRegisterForm.value.password !== passwordRegisterForm.value.password_confirmation && passwordRegisterForm.controls.password_confirmation.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_VERIFY_INPUT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-grid>
                <ion-row>
                    <ion-col size="1" class="ion-text-left">
                        <ion-checkbox [(ngModel)]="tandc" [ngModelOptions]="{standalone: true}" required></ion-checkbox>
                    </ion-col>
                    <ion-col size="11" class="ion-text-left">
                        <ion-text class="no-ellipsis">{{'TERMS_AND_CONDITIONS_TEXT' | translate}} <span class="url-link" (click)="clickedTerms()">{{ 'TERMS_AND_CONDITIONS' | translate }}</span> {{ 'AND' | translate }} <span class="url-link" (click)="clickedPrivacy()">{{ 'PRIVACY_POLICY' | translate }}</span></ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-button id="registration-button"
                        (click)="register()" [disabled]="!passwordRegisterForm.valid || passwordRegisterForm.value.password !== passwordRegisterForm.value.password_confirmation || !tandc">
                {{"REGISTRATION_BUTTON" | translate}}
            </ion-button>
        </form>
    </div>
</ion-content>
