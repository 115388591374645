import {Component, Inject, OnDestroy} from '@angular/core';
import {Resource} from '../../../common/repository/resource';
import {UserRepository} from '../../../repositories/user/user.repository';
import {ToastController} from '@ionic/angular';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AuthSessionRepository} from '../../../repositories/auth/authSession.repository';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Browser} from '@capacitor/browser';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {switchMap, take, tap} from 'rxjs/operators';
import {EventsRepository} from '../../../repositories/events/events.repository';
import {InviteDataRequest, UserEmail} from '../../../api';
import {consumeOnlySuccess} from '../../../common/rxjs/operators';
import {Status} from '../../../common/repository/status';
import {Subscription} from 'rxjs';
import {getToastOptionError, getToastOptionSuccess} from '../../common/constants';
import {Network} from '@capacitor/network';

@Component({
    selector: 'app-password-register',
    templateUrl: './password-register.page.html',
    styleUrls: ['./password-register.page.scss'],
})
export class PasswordRegisterPage implements OnDestroy {

    public passwordRegisterForm: FormGroup;
    private invitationId: string;
    public tandc = false;
    private readonly cguPath: string;
    private readonly pcaPath: string;
    public email: string;

    private subscription: Subscription;
    private loginSubscription: Subscription;

    constructor(private userRepository: UserRepository,
                private eventRepository: EventsRepository,
                private toastController: ToastController,
                private authSessionRepository: AuthSessionRepository,
                private resourceStatusService: ResourceStatusService,
                private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                @Inject(ENV) public environment: Env) {

        this.cguPath = this.translateService.currentLang === 'fr' ? environment.cguPathFr : environment.cguPathEn;
        this.pcaPath = this.translateService.currentLang === 'fr' ? environment.pcaPathFr : environment.pcaPathEn;

        this.passwordRegisterForm = new FormGroup({
            password: new FormControl('', [Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
            password_confirmation: new FormControl('', [Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])
        });

        this.subscription = Subscription.EMPTY;
        this.loginSubscription = Subscription.EMPTY;
    }

    ionViewWillEnter() {
        this.subscription = this.route.paramMap.pipe(
            take(1),
            switchMap((params: ParamMap) => {
                this.invitationId = params.get('invitationId');
                return this.eventRepository.validateInviteByToken(this.invitationId);
            })
        ).subscribe(async (resourceUserEmail: Resource<UserEmail>) => {
            this.resourceStatusService.consumeResource(resourceUserEmail);
            if (resourceUserEmail.status === Status.SUCCESS) {
                this.email = resourceUserEmail.data.email;
            }
            else if (resourceUserEmail.status === Status.ERROR) {
                await this.router.navigate(['home']);
            }
        });
    }

    ionViewWillLeave(){
        this.subscription.unsubscribe();
        this.loginSubscription.unsubscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.loginSubscription.unsubscribe();
    }

    public async clickedTerms() {
        const offline = await this.checkIfOffline();
        if (!offline) {
            const url = this.cguPath;
            await Browser.open(
                {
                    url,
                    windowName: '_system'
                }
            );
        }
    }

    public async clickedPrivacy() {
        const offline = await this.checkIfOffline();
        if (!offline) {
            const url = this.pcaPath;
            await Browser.open(
                {
                    url,
                    windowName: '_system'
                }
            );
        }
    }

    public async register() {
        if (this.tandc) {
            const newData: InviteDataRequest = {};
            newData.password = this.passwordRegisterForm.value.password;
            newData.password_confirmation = this.passwordRegisterForm.value.password_confirmation;
            this.loginSubscription = this.eventRepository.participantInviteByToken(this.invitationId, newData).pipe(
                consumeOnlySuccess(this.resourceStatusService),
                switchMap((email: string) => {
                    return this.authSessionRepository.login(email, this.passwordRegisterForm.value.password);
                }),
                consumeOnlySuccess(this.resourceStatusService),
                tap(() => {
                    // we clean app user and published events in case there was an anonymous user and event added by code
                    this.userRepository.clearUser();
                }),
                switchMap(() => {
                    return this.userRepository.loadUser();
                }),
                consumeOnlySuccess(this.resourceStatusService),
            ).subscribe(async () => {
                const message = this.translateService.instant('PASSWORD_SET_SUCCESS');
                const toast = await this.toastController.create(getToastOptionSuccess(message));
                await toast.present();
                await this.router.navigate(['home']);
            });
        }
        else {
            const message = this.translateService.instant('ACCEPT_TERMS') + '. ' + this.translateService.instant('CHECKBOX_ERROR_TOAST');
            const toast = await this.toastController.create(getToastOptionError(message));
            await toast.present();
        }
    }

    private async checkIfOffline(): Promise<boolean> {
        const networkStatus = await Network.getStatus();
        if (!networkStatus.connected) {
            const message = this.translateService.instant('NO_INTERNET_CONNECTION');
            const toast = await this.toastController.create(getToastOptionError(message));
            await toast.present();
            return true;
        }
        return false;
    }

    public async submit() {
        if (this.passwordRegisterForm.valid) {
            this.register();
        }
    }

}
