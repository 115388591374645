import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserRepository} from '../repositories/user/user.repository';
import {switchMap} from 'rxjs/operators';
import {ToastController} from '@ionic/angular';
import {fromPromise} from 'rxjs/internal-compatibility';
import {getToastOptionError} from '../pages/common/constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LoggedGuard implements CanActivate {

    constructor(private userRepository: UserRepository,
                private translateService: TranslateService,
                private toastController: ToastController,
                private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.userRepository.isLoggedIn().pipe(
            switchMap((auth: boolean) => {
                return this.userRepository.isGuest().pipe(
                    switchMap((isGuest: boolean) => {
                        if (auth && !isGuest) {
                            return fromPromise(this.showMessage(state).then(() => {
                                return this.router.createUrlTree(['home']);
                            }));
                        }
                        else {
                            return of(true);
                        }
                    })
                );
            })
        );
    }

    public async showMessage(state: RouterStateSnapshot) {
        if (state.url.indexOf('password-reset') > 0) {
            const message = this.translateService.instant('INVITE_ALREADY_LOGED');
            const toast = await this.toastController.create(getToastOptionError(message));
            await toast.present();
        } else if (state.url.indexOf('password-register') > 0) {
            const message = this.translateService.instant('INVITE_ALREADY_LOGED');
            const toast = await this.toastController.create(getToastOptionError(message));
            await toast.present();
        } else {
            const message = this.translateService.instant('INVITE_ALREADY_LOGED');
            const toast = await this.toastController.create(getToastOptionError(message));
            await toast.present();
        }
    }

}
