export function getToastOptionInfo(message: string) {
    return {
        message,
        color: 'secondary',
        keyboardClose: true,
        translucent: false,
        duration: 5000
    };
}

export function getToastOptionSuccess(message: string) {
    return {
        message,
        color: 'success',
        keyboardClose: true,
        translucent: false,
        duration: 5000
    };
}

export function getToastOptionError(message: string) {
    return {
        message,
        color: 'danger',
        keyboardClose: true,
        duration: 5000
    };
}
