import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {TranslateService} from '@ngx-translate/core';
import {StaticPage} from '../../../api';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Platform} from '@ionic/angular';
import {PracticalInformationClickService} from '../practical-information-click.service';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-practical-information-list',
    templateUrl: './practical-information-list.page.html',
    styleUrls: ['./practical-information-list.page.scss'],
})
export class PracticalInformationListPage implements AfterViewInit {

    // OUTPUTS
    public staticPages$: Observable<StaticPage[]>;
    public isAndroidOriOS = false;
    public isMainList = true;
    public title: string = null;

    @ViewChild('noStaticPagesDataLoading', {static: false}) noStaticPagesDataLoading: LoadingNoDataComponent;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private practicalInformationClickService: PracticalInformationClickService,
                public translate: TranslateService,
                private platform: Platform,
                @Inject(ENV) public environment: Env) {
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit() {
        this.setUpStaticPagesObserver();
    }

    public async staticPageClick(staticPage: StaticPage) {
        await this.practicalInformationClickService.clickOnStaticPage(staticPage);
    }

    private setUpStaticPagesObserver() {
        this.staticPages$ = this.staticPagesRepository.getStaticPages()
            .pipe(
                manageLoadingNoData(this.noStaticPagesDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
