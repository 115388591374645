import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {AttractionDetail} from '../../../../api';
import {IS_DESKTOP, IS_MOBILE} from '../../../../common/ui/directives/show.when';
import {StaticPagesRepository} from '../../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {shareReplay, switchMap} from 'rxjs/operators';
import {IonSlides, Platform} from '@ionic/angular';
import {consume, manageLoadingNoData} from '../../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-tourism-api-details',
    templateUrl: './tourism-api-details.page.html',
    styleUrls: ['./tourism-api-details.page.scss'],
})
export class TourismApiDetailsPage implements AfterViewInit {

    // OUTPUTS
    public attraction$: Observable<AttractionDetail>;

    // VARIABLES
    @ViewChild('noTourismDataLoading', {static: false}) noTourismDataLoading: LoadingNoDataComponent;

    public readonly isDesktop = IS_DESKTOP;
    public readonly isMobile = IS_MOBILE;
    public isAndroidOriOS: boolean;

    @ViewChild('attractionSlider', {static: false}) slides: IonSlides;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private route: ActivatedRoute,
                public sanitizer: DomSanitizer,
                private platform: Platform) {
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit() {
        this.setUpAttractionObservable();
    }

    public async slideBack() {
        await this.slides.slidePrev();
    }

    public async slideForward() {
        await this.slides.slideNext();
    }

    private setUpAttractionObservable() {
        this.attraction$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    // tslint:disable-next-line:radix
                    const id = parseInt(params.get('id'));
                    return this.staticPagesRepository.getAttractionDetailApiPageById(id);
                }),
                manageLoadingNoData(this.noTourismDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
