import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {RestaurantApiPage} from './restaurant-api.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HeaderComponentModule,
        IonicModule,
        TranslateModule,
        UiStateManagementModule,
    ],
  declarations: [RestaurantApiPage]
})
export class RestaurantApiPageModule {}
