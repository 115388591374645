import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import {Media} from '../../../api';

const DOM_PREFIX = 'file-carousel-file-';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.html',
  styleUrls: ['./file-item.scss'],
})
export class FileItemComponent implements OnInit, AfterViewInit {

  @Input() file: Media;
  @Input() index: number;
  public extractedType: string;
  public fileIcon = '';
  private imgExts: Array<string> = ['PNG', 'JPG', 'JPEG', 'ICO', 'SVG', 'BMP', 'GIF'];
  private mediaExts: Array<string> = ['MP3', 'MP4', 'WAV', 'MKV', 'AAC', 'OGG', 'WEBM', 'MPG', 'MPEG'];

  constructor(private el: ElementRef) { }

  ngOnInit() {
    const splitted = this.file.title.split('.'),
      foundType = splitted[splitted.length - 1];
    this.extractedType = foundType.toUpperCase();
    this.getFileIcon();
  }

  getId() {
    return DOM_PREFIX + this.index;
  }

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('id', this.getId());
  }

  getFileIcon() {
    if (this.imgExts.indexOf(this.extractedType) !== -1) {
      this.fileIcon = './assets/imgs/file_picture.svg';
    }
    else if (this.mediaExts.indexOf(this.extractedType) !== -1) {
      this.fileIcon = './assets/imgs/file_sound.svg';
    }
    else {
      this.fileIcon = './assets/imgs/file_file.svg';
    }
  }

}
