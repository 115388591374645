import {Observable} from 'rxjs';
import {UserPushToken, UsersService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class SendFirebaseTokenToServer extends JustNetworkResource<any, any> {
    constructor(private usersService: UsersService,
                private fcmToken: UserPushToken) {
        super(true);
    }

    startNetworkCall(): Observable<any> {
        return this.usersService.updatePushToken(this.fcmToken);
    }

    onNetworkSuccess(dataFromNetwork: any): any {
        return dataFromNetwork;
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('NOTIFICATIONS_REGISTRATION_FAILURE', false);
    }
}
