import {Observable} from 'rxjs';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {ProfilesService} from '../../../api';

export class UpdateEventParticipantPictureForCurrentUser extends JustNetworkResource<any, any> {
    constructor(private profilesService: ProfilesService,
                private eventId: number,
                private picture: Blob) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.profilesService.updateMyProfilePicture(this.eventId, this.picture);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('ERROR_PICTURE_UPLOAD', false);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }
}
