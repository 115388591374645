import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AgendasService, Session, SessionId} from '../../../api';
import {AppState} from '../../../store';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {removeCurrentUserEventParticipantAgendaSession} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {HttpErrorResponse} from '@angular/common/http';

export class RemoveEventSessionFromCurrentUserEventParticipantAgenda extends JustNetworkResource<Session, any> {
    constructor(private store: Store<AppState>,
                private agendasService: AgendasService,
                private eventId: number,
                private session: Session) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        const sessionId: SessionId = { session_id: this.session.id };
        return this.agendasService.removeSessionFromMyAgenda(this.eventId, sessionId);
    }

    protected onNetworkSuccess(anything: any): Session {
        return this.removeSessionFromAgenda();
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        if (error.status === 405) {
            return UserFriendlyError.displayableAsToast('EVENT_SESSIONS_SPEAKER_REMOVE_AGENDA_ERROR', false, true);
        }

        return UserFriendlyError.displayableAsToast('REMOVE_AGENDA_ERROR', false, true);
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        // if the server answers with an error saying that the session is already removed from the agenda
        // we add remove anyway from the store so that we put client and server are back in sync
        // "message": "Session not found in the agenda",
        const errorMessage: string = error.error.message;
        if (errorMessage && errorMessage === 'Session not found in the agenda') {
            this.removeSessionFromAgenda(); // to sync the client with the server
            return UserFriendlyError.displayableAsToast('ALREADY_REMOVED_FROM_AGENDA_ERROR', false, true);
        }
        else {
            return UserFriendlyError.displayableAsToast('REMOVE_AGENDA_ERROR', false, true);
        }
    }

    private removeSessionFromAgenda(): Session {
        this.store.dispatch(removeCurrentUserEventParticipantAgendaSession({
            eventId: this.eventId, eventSessionId: this.session.id
        }));
        return this.session;
    }
}
