import {Action, createReducer} from '@ngrx/store';

export const initialState = false;

const hydratedSessionReducer = createReducer(
  initialState
);

export function reducer(state: boolean | undefined, action: Action) {
    return hydratedSessionReducer(state, action);
}
