<div id="cgu-container">
    <p>{{'NEW_TERMS_AND_CONDITIONS_TEXT' | translate}} <span class="url-link" (click)="clickedTerms()">{{ 'TERMS_AND_CONDITIONS' | translate }}</span> {{ 'AND' | translate }} <span class="url-link" (click)="clickedPrivacy()">{{ 'PRIVACY_POLICY' | translate }}</span></p>
    <hr>
    <ion-item lines="none">
        <ion-checkbox slot="start" [(ngModel)]="tandc" [ngModelOptions]="{standalone: true}" required></ion-checkbox>
        <ion-label>{{ 'I_ACCEPT' | translate }}</ion-label>
    </ion-item>
    <div class="ion-text-center ion-align-items-center">
        <ion-button [disabled]="!tandc" id="accept-button" type="submit" (click)="acceptCguPca()">
            {{'ACCEPT_BUTTON' | translate}}
        </ion-button>
    </div>
</div>

