import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {IS_DESKTOP, IS_MOBILE} from '../../../../common/ui/directives/show.when';
import {Observable} from 'rxjs';
import {CicgNews} from '../../../../api';
import {StaticPagesRepository} from '../../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {shareReplay, switchMap} from 'rxjs/operators';
import {consume, manageLoadingNoData} from '../../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-news-api-details',
    templateUrl: './news-api-details.page.html',
    styleUrls: ['./news-api-details.page.scss'],
})
export class NewsApiDetailsPage implements AfterViewInit {

    // OUTPUTS
    public cicgNews$: Observable<CicgNews>;

    // VARIABLES
    @ViewChild('noNewsDataLoading', {static: false}) noNewsDataLoading: LoadingNoDataComponent;

    public readonly isDesktop = IS_DESKTOP;
    public readonly isMobile = IS_MOBILE;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private route: ActivatedRoute) { }

    ngAfterViewInit() {
        this.setUpCicgNewsObservable();
    }

    private setUpCicgNewsObservable() {
        this.cicgNews$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    const id = params.get('id');
                    return this.staticPagesRepository.getNewsApiPageById(id);
                }),
                manageLoadingNoData(this.noNewsDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
