import {Observable} from 'rxjs';
import {NewPasswordDataRequest, User, UsersService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class ChangePassword extends JustNetworkResource<any, any> {
    constructor(private userService: UsersService,
                private newPasswordData: NewPasswordDataRequest) {
        super(true);
    }

    protected startNetworkCall(): Observable<User> {
        return this.userService.userUpdatePassword(this.newPasswordData);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
      return UserFriendlyError.displayableAsToast('UPDATE_PASSWORD_KO_TOAST', false);
    }
}
