<ion-content id="splash" no_padding>

    <div id="container">
        <div id="center">
            <h5>{{ 'PUBLIC_SURVEY_WELCOME_TEXT' | translate }}</h5>
            <ion-button color="primary" (click)="takeSurvey()">{{ 'SURVEY_BUTTON_TITLE' | translate}}</ion-button>
        </div>
    </div>

</ion-content>
