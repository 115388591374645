import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileItemComponentModule} from './file-item/file-item.module';
import {FileCarrouselComponent} from './file-carrousel';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [
    FileCarrouselComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        FileItemComponentModule
    ],
  exports: [ FileCarrouselComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FileCarrouselComponentModule {}
