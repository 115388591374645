import {Injectable} from '@angular/core';
import {DownloadService, Media} from '../../api';
import {Observable, OperatorFunction, ReplaySubject} from 'rxjs';
import {Resource} from '../../common/repository/resource';
import {GetFile} from './getFile';
import {switchMap} from 'rxjs/operators';
import {Status} from '../../common/repository/status';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {Platform} from '@ionic/angular';
import {Directory, Filesystem, WriteFileResult} from '@capacitor/filesystem';
import {FileOpener, FileOpenerOptions} from '@capacitor-community/file-opener';

@Injectable({
    providedIn: 'root'
})
export class FilesRepository {

    private static onOpenFileError(fileOpenedSubject: ReplaySubject<Resource<void>>,
                                   fileResource: Resource<Blob>) {
        const error = UserFriendlyError.displayableAsToast('DOWNLOAD_FILE_ERROR', false);
        fileOpenedSubject.next(Resource.error(fileResource.identifier, error));
        fileOpenedSubject.complete();
    }

    constructor(private downloadService: DownloadService, private platform: Platform) {}

    // RJX operator (could be put in common folder for other projects, if we put the file repo as well)
    public processAndOpenFile(media: Media): OperatorFunction<Resource<Blob>, Resource<void>> {
        // we use a subject to being able to adapt to the async reading of the file
        const fileOpenedSubject: ReplaySubject<Resource<void>> = new ReplaySubject<Resource<void>>();

        return input$ => input$.pipe(
            switchMap((fileResource: Resource<Blob>) => {
                    if (fileResource.status === Status.SUCCESS) {
                        // we do all the operation to open the file
                        this.openFile(fileOpenedSubject, fileResource, media);
                    }
                    else if (fileResource.status === Status.ERROR) {
                        fileOpenedSubject.next(Resource.noDataTransform(fileResource));
                        // the data we returned is an error so we simply complete here
                        fileOpenedSubject.complete();
                    }
                    else {
                        // we simply return the data as it is
                        fileOpenedSubject.next(Resource.noDataTransform(fileResource));
                    }

                    // we return the replay subject so that we can follow the file operations
                    return fileOpenedSubject;
                }
            )
        );
    }

    private openFile(fileOpenedSubject: ReplaySubject<Resource<void>>, fileResource: Resource<Blob>, media: Media) {
        const file: Blob = fileResource.data;

        // Native
        if (this.platform.is('cordova')) {
            const reader = new FileReader();
            // The magic always begins after the Blob is successfully loaded
            reader.onload = () => {
                // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
                const b64String: string = reader.result.toString();

                Filesystem.writeFile({
                    path: '/' + media.title,
                    data: b64String,
                    directory: Directory.Data,
                }).then((writeResult: WriteFileResult) => {
                    const options: FileOpenerOptions = {
                        filePath: writeResult.uri,
                        contentType: file.type
                    };
                    return FileOpener.open(options);
                }).then(() => {
                    fileOpenedSubject.next(Resource.noDataTransform(fileResource));
                    fileOpenedSubject.complete();
                }).catch(() => {
                    FilesRepository.onOpenFileError(fileOpenedSubject, fileResource);
                });
            };

            reader.onerror = () => {
                FilesRepository.onOpenFileError(fileOpenedSubject, fileResource);
            };

            // Since everything is set up, let’s read the Blob and store the result as Data URI
            reader.readAsDataURL(file);
        }
        else {
            // Web
            // Create an object URL for the blob object
            const url = URL.createObjectURL(file);

            // Create a new anchor element
            const a = document.createElement('a');

            // Set the href and download attributes for the anchor element
            a.href = url;
            a.download = media.title || 'download';

            // Click handler that releases the object URL after the element has been clicked
            // This is required for one-off downloads of the blob content
            const clickHandler = () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    removeEventListener('click', clickHandler);
                    fileOpenedSubject.next(Resource.noDataTransform(fileResource));
                    fileOpenedSubject.complete();
                }, 150);
            };

            // Add the click event listener on the anchor element
            a.addEventListener('click', clickHandler, false);
            a.click();
        }
    }

    public downloadFile(eventId: number, media: Media): Observable<Resource<Blob>> {
        const getFile = new GetFile(this.downloadService, eventId, media);
        return getFile.call();
    }

    public downloadAndOpenFile(eventId: number, media: Media): Observable<Resource<void>> {
        return this.downloadFile(eventId, media).pipe(
            this.processAndOpenFile(media)
        );
    }
}
