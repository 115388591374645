import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Media} from '../../api';

export interface FileCarouselEvent {
    media: Media;
    eventId: number;
}

@Component({
    selector: 'app-file-carousel',
    templateUrl: './file-carrousel.html',
    styleUrls: ['./file-carrousel.scss'],

})
export class FileCarrouselComponent implements OnInit, OnChanges {

    @Input() public files: Media[];
    @Input() public eventId: number;

    @Output() public itemClick: EventEmitter<FileCarouselEvent> = new EventEmitter();

    public readonly isDesktop: boolean;
    public threePagers: any = 0;

    constructor(private platform: Platform) {
        this.isDesktop = this.platform.is('desktop');
    }

    ngOnInit() {
        this.setUpThreePagers();
    }

    ngOnChanges(): void {
        this.setUpThreePagers();
    }

    public onItemClick(remoteFile: Media) {
        if (this.eventId) {
            this.itemClick.emit({media: remoteFile, eventId: this.eventId});
        }
    }

    private setUpThreePagers() {
        if (this.files && this.isDesktop) {
            this.threePagers = new Array(Math.ceil(this.files.length / 3)).fill(1);
        }
    }
}
