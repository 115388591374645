import {ActionReducerMap} from '@ngrx/store';
import {reducer as authSessionReducer} from './reducers/auth/authSession.reducer';
import {reducer as staticPagesReducer} from './reducers/staticPages/staticPage.reducer';
import {reducer as staticPageNewsReducer} from './reducers/staticPageNews/staticPageNews.reducer';
import {reducer as staticPageRoomsReducer} from './reducers/staticPageRooms/staticPageRooms.reducer';
import {reducer as userReducer} from './reducers/user/user.reducer';
import {
    allReducer as allEventsReducer,
    archivedReducer as archivedEventsReducer,
    publishedReducer as publishedEventsReducer
} from './reducers/events/event.reducer';
import {reducer as notificationsReducer} from './reducers/notifications/notification.reducer';
import {EventNews, reducer as eventNewsReducer} from './reducers/eventNews/event-news.reducer';
import {
    EventParticipant,
    reducerForAll as eventParticipantsReducer,
    reducerForCurrentUser as eventParticipantsForCurrentUserForReducer
} from './reducers/eventParticipants/event-participant.reducer';
import {reducer as hydratedReducer} from './reducers/hydrate/hydrate.reducer';
import {EventSession, reducer as eventSessionsReducer} from './reducers/eventSessions/event-session.reducer';
import {EntityState} from '@ngrx/entity';
import {AuthSession} from '../api/model/authSession';
import {CicgNews, Event, Notification, Room, User} from '../api';
import {EventParticipantSetting, reducer as eventSettingsReducer} from './reducers/eventSettings/event-setting.reducer';
import {OrderedStaticPage} from './reducers/staticPages/staticPage.selectors';

export interface AppState {
    staticPages: EntityState<OrderedStaticPage>;
    staticPageNews: EntityState<CicgNews>;
    staticPageRooms: EntityState<Room>;
    authSession: AuthSession;
    user: User;
    allEvents: EntityState<Event>;
    publishedEvents: EntityState<Event>;
    archivedEvents: EntityState<Event>;
    notifications: EntityState<Notification>;
    eventNews: EntityState<EventNews>;
    eventParticipants: EntityState<EventParticipant>;
    eventParticipantsCurrentUser: EntityState<EventParticipant>;
    eventSessions: EntityState<EventSession>;
    eventSettings: EntityState<EventParticipantSetting>;
    hydrated: boolean;
}

export const reducers: ActionReducerMap<AppState> = {
    staticPages: staticPagesReducer,
    staticPageNews: staticPageNewsReducer,
    staticPageRooms: staticPageRoomsReducer,
    authSession: authSessionReducer,
    user: userReducer,
    allEvents: allEventsReducer,
    publishedEvents: publishedEventsReducer,
    archivedEvents: archivedEventsReducer,
    notifications: notificationsReducer,
    eventNews: eventNewsReducer,
    eventParticipants: eventParticipantsReducer,
    eventParticipantsCurrentUser: eventParticipantsForCurrentUserForReducer,
    eventSessions: eventSessionsReducer,
    eventSettings: eventSettingsReducer,
    hydrated: hydratedReducer,
};
