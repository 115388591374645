import {RouterModule, Routes} from '@angular/router';
import {PracticalInformationListPage} from './list/practical-information-list.page';
import {PracticalInformationListBySlugPage} from './list/practical-information-list-by-slug.page';
import {NgModule} from '@angular/core';
import {TpgApiPage} from './tpg-api/tpg-api.page';
import {IframePage} from './iframe/iframe.page';
import {CffApiPage} from './cff-api/cff-api.page';
import {RestaurantApiPage} from './restaurant-api/restaurant-api.page';
import {NewsApiPage} from './news-api/news-api.page';
import {NewsApiDetailsPage} from './news-api/news-api-details/news-api-details.page';
import {RoomsApiPage} from './rooms-api/rooms-api.page';
import {TourismApiPage} from './tourism-api/tourism-api.page';
import {RoomApiDetailsPage} from './rooms-api/room-api-details/room-api-details.page';
import {TourismApiDetailsPage} from './tourism-api/tourism-api-details/tourism-api-details.page';

const routes: Routes = [
  {
    path: 'practical-information/list',
    component: PracticalInformationListPage
  },
  {
    path: 'practical-information/list/:slug',
    component: PracticalInformationListBySlugPage
  },
  {
    path: 'practical-information/tpg',
    component: TpgApiPage
  },
  {
    path: 'practical-information/cff',
    component: CffApiPage
  },
  {
    path: 'practical-information/restaurant',
    component: RestaurantApiPage
  },
  {
    path: 'practical-information/news',
    component: NewsApiPage
  },
  {
    path: 'practical-information/news/:id',
    component: NewsApiDetailsPage
  },
  {
    path: 'practical-information/rooms',
    component: RoomsApiPage
  },
  {
    path: 'practical-information/rooms/:extId',
    component: RoomApiDetailsPage
  },
  {
    path: 'practical-information/rooms/frame/:id',
    component: IframePage
  },
  {
    path: 'practical-information/tourism',
    component: TourismApiPage
  },
  {
    path: 'practical-information/tourism/:id',
    component: TourismApiDetailsPage
  },
  {
    path: 'practical-information/:slug',
    component: IframePage
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PracticalInformationRoutingModule { }
