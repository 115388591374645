import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastController} from '@ionic/angular';
import {getToastOptionInfo} from '../../../../../pages/common/constants';

@Component({
    selector: 'app-admin-button',
    templateUrl: './admin-button.component.html',
    styleUrls: ['./admin-button.component.scss'],
})
export class AdminButtonComponent implements OnInit, OnChanges {

    @Input() public roles: string[];
    public showAdminIcon: boolean;
    private translatedRoles: string[];
    private toast;

    constructor(private translateService: TranslateService,
                private toastController: ToastController) {
    }

    public ngOnInit() {}

    public ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                const changedProp = changes[propName];
                if (propName === 'roles') {
                    const roles: string[] = changedProp.currentValue.filter((role) => {
                        return role !== 'participant';
                    });
                    this.showAdminIcon = roles.indexOf('admin') !== -1 ||
                        roles.indexOf('organizer') !== -1 ||
                        roles.indexOf('project_manager') !== -1;
                    if (this.showAdminIcon) {
                        this.translatedRoles = roles.map((role) => {
                            return this.translateService.instant(role);
                        });
                    }
                }
            }
        }
    }

    public async showAdminInfo(){
        const message = this.translateService.instant('EVENT_ROLE_MESSAGE',
            {roles: this.translatedRoles.join(', ')});
        const toastOptions = getToastOptionInfo(message);
        this.toast = await this.toastController.create(toastOptions);
        await this.toast.present();
    }

}
