import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Event, EventsService} from '../../api';
import {selectArchivedEvents} from '../../store/reducers/events/event.selectors';
import {clearEventsInArchivedEvents, upsertEventsInArchivedEvents} from '../../store/reducers/events/event.actions';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetArchivedEvents extends NetworkBoundResource<Event[], Event[]> {
    constructor(private store: Store<AppState>,
                private eventsService: EventsService) {
        super();
    }

    protected loadFromDatabase(): Observable<Event[]> {
        return this.store.pipe(select(selectArchivedEvents));
    }

    protected saveNetworkResult(events: Event[]) {
        // we first delete all the events
        this.store.dispatch(clearEventsInArchivedEvents());
        // then we add them all
        this.store.dispatch(upsertEventsInArchivedEvents({ events }));
    }

    protected shouldFetch(dataFromDatabase: Event[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
                resourceId: this.constructor.name
            }
        };
    }

    protected startNetworkCall(): Observable<Event[]> {
        return this.eventsService.listEvents('passed');
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENTS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name);
    }
}
