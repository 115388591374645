import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
import {CodeComponent} from '../../../../../components/code/code.component';
import {BarcodeScanner} from '@capacitor-mlkit/barcode-scanning';
import * as URI from 'uri-js';
import {parse} from 'querystring';
import {TranslateService} from '@ngx-translate/core';
import {EventsRepository} from '../../../../../repositories/events/events.repository';
import {Resource} from '../../../../repository/resource';
import {ResourceStatusService} from '../../../state-management/resource.status.service';
import {Router} from '@angular/router';
import {Status} from '../../../../repository/status';
import {getToastOptionSuccess} from '../../../../../pages/common/constants';
import {Subscription} from 'rxjs';
import {ENV} from '../../../../../../environments/variables';
import {Env} from '../../../../../../environments/env';

@Component({
    selector: 'app-scan-button',
    templateUrl: './scan-button.component.html',
    styleUrls: ['./scan-button.component.scss'],
})
export class ScanButtonComponent implements OnInit, OnDestroy {
    private toast;
    private getEventByCodeSubscription: Subscription;
    private isSupported = false;

    constructor(private modalController: ModalController,
                private translateService: TranslateService,
                private toastController: ToastController,
                private eventsRepository: EventsRepository,
                private resourceStatusService: ResourceStatusService,
                private router: Router,
                @Inject(ENV) public environment: Env) {
        this.getEventByCodeSubscription = Subscription.EMPTY;
    }

    public ngOnInit() {}

    ngOnDestroy(): void {
        this.getEventByCodeSubscription.unsubscribe();
    }

    public async showCode() {
        const codeModal = await this.modalController.create({
            component: CodeComponent,
            cssClass: 'mt-event-modal',
            showBackdrop: true,
            backdropDismiss: false
        });
        await codeModal.present();
        const { data } = await codeModal.onWillDismiss();
        if (data) {
            if (data.code) {
                this.authenticateForEvent(data.code);
            }
            if (data.action && data.action === 'scan') {
                await this.scanCode();
            }
        }
    }

    public async scanCode() {
        BarcodeScanner.isSupported().then((result) => {
            this.isSupported = result.supported;
        });

        await BarcodeScanner.scan().then(async (result) => {
            const barcodes = result.barcodes;
            if (barcodes.length > 0) {
                const url = URI.parse(barcodes[0].rawValue);
                const query = parse(url.query);
                if (url.host === this.environment.deeplinksURL &&
                    query.controller &&
                    query.controller === 'event_code_invite' &&
                    query.event_code &&
                    query.event_code.length > 0) {
                    this.authenticateForEvent(query.event_code.toString());
                } else {
                    const message = this.translateService.instant('INVITE_CODE_INPUT_ERROR');
                    const toastOptions = {
                        message,
                        color: 'danger',
                        keyboardClose: true,
                        duration: 4000
                    };
                    this.toast = await this.toastController.create(toastOptions);
                    await this.toast.present();
                }
            }
        }).catch(async (error) => {
            const message = this.translateService.instant('INVITE_CODE_INPUT_ERROR');
            const toastOptions = {
                message,
                color: 'danger',
                keyboardClose: true,
                duration: 4000
            };
            this.toast = await this.toastController.create(toastOptions);
            await this.toast.present();
        });
    }

    private authenticateForEvent(code: string) {
        this.getEventByCodeSubscription = this.eventsRepository.getEventByCode(code)
            .subscribe(async (resourceEventId: Resource<number>) => {
                this.resourceStatusService.consumeResource(resourceEventId);
                if (resourceEventId.status === Status.SUCCESS) {
                    await this.showEventAddedSuccessMessage();
                    await this.router.navigate(['event', resourceEventId.data]);
                } else if (resourceEventId.status === Status.ERROR) {
                    await this.router.navigate(['home']);
                }
            });
    }

    private async showEventAddedSuccessMessage() {
        const message = this.translateService.instant('EVENT_ADDED_MESSAGE_SUCCESS');
        const toast = await this.toastController.create(getToastOptionSuccess(message));
        await toast.present();
    }

}
