import {Component, Inject, OnInit} from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {EventsRepository} from '../../../repositories/events/events.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute} from '@angular/router';
import {Event} from '../../../api';
import {IS_DESKTOP} from '../../../common/ui/directives/show.when';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {isCurrent, prepareEventResource} from './common/operators';
import {map} from 'rxjs/operators';
import {consume} from '../../../common/rxjs/operators';
import {Platform} from '@ionic/angular';
import {Browser} from '@capacitor/browser';

interface EventDetailPageContext {
    event: Event | null;
    isCurrentEvent: boolean;
}

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.page.html',
    styleUrls: ['./event-detail.page.scss'],
})
export class EventDetailPage implements OnInit {

    // OUTPUTS
    private event$: Observable<Event>;
    public pageContext$: Observable<EventDetailPageContext>;

    public isDesktop: boolean;

    constructor(private eventsRepository: EventsRepository,
                private resourceStatusService: ResourceStatusService,
                private route: ActivatedRoute,
                public platform: Platform,
                @Inject(ENV) public environment: Env) { }

    ngOnInit(): void {
        this.setUpEventObserver();
        this.isDesktop = IS_DESKTOP.isPlatform(this.platform);
    }

    private setUpEventObserver() {
        this.event$ = merge(of(true), this.resourceStatusService.triggerRetry$).pipe(
            prepareEventResource(this.route, this.eventsRepository),
            consume(this.resourceStatusService)
        );

        this.pageContext$ = this.event$.pipe(
            map((event: Event): EventDetailPageContext => {
                return {
                    event, isCurrentEvent: isCurrent(event)
                };
            })
        );
    }

    public async openLink(link?: string) {
        if (link) {
            await Browser.open(
                {
                    url: link,
                    windowName: '_system'
                }
            );
        }
    }
}
