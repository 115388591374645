import {Observable, of, zip} from 'rxjs';
import {Resource} from './resource';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {concat} from 'rxjs/internal/observable/concat';
import {NetworkOperationResult} from './networkOperationResult';
import {UserFriendlyError} from './userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {RateLimiter, RateLimiterOptions} from './rateLimiter';

export interface ResourceFetchOptions {
    readonly shouldFetch: boolean;
    readonly shouldErrorIfNoNetwork: boolean;
    readonly rateLimiterOptions?: RateLimiterOptions;
}

export abstract class NetworkBoundResource<ResultType, RequestType> extends NetworkOperationResult<ResultType, RequestType> {
    private readonly rateLimiter: RateLimiter = RateLimiter.getInstance();

    protected abstract loadFromDatabase(): Observable<ResultType>;
    protected abstract shouldFetch(dataFromDatabase: ResultType): ResourceFetchOptions;
    protected abstract startNetworkCall(): Observable<RequestType>;
    protected abstract saveNetworkResult(dataFromNetwork: ResultType);

    public fetch(): Observable<Resource<ResultType>> {
        // we return the result as an observable
        return concat<Resource<ResultType>>(
            // we initially signal we are loading with no data
            of(this.generateLoadingResource()),
            // then we try to fetch the data from the database and decide if we should go to network
            this.getDataFromDatabaseAndCheckIfShouldFetchFromNetwork()
        );
    }

    protected removeRateLimiterLimit(resourceId: string) {
        this.rateLimiter.removeLimit(resourceId);
    }

    private getDataFromDatabaseAndCheckIfShouldFetchFromNetwork(): Observable<Resource<ResultType>> {
        // we load the data from the database and we check if we can load from network
        return zip(this.loadFromDatabase(), super.networkAllowsFetch())
            .pipe(
                take(1),
                switchMap(([dataFromDB, networkAllowsFetch]: [ResultType, boolean]): Observable<Resource<ResultType>> => {
                    const fetchOptions: ResourceFetchOptions = this.shouldFetch(dataFromDB);

                    // we check if we are connected
                    if (networkAllowsFetch) {
                        // we determine if we should fetch the data from network
                        // based on rate limiter and fetch options
                        if (fetchOptions.rateLimiterOptions) {
                            return this.rateLimitedNetworkFetch(dataFromDB, fetchOptions);
                        }
                        // based only on fetch options
                        else if (fetchOptions.shouldFetch) {
                            return this.loadingAndFetchFromNetwork(dataFromDB);
                        }
                        // no need to fetch anything return DB data only
                        else {
                            return this.setSuccessWithDataFromDatabase();
                        }
                    }
                    else if (!fetchOptions.shouldErrorIfNoNetwork) {
                        // we return the data from the database successfully only if the resource provider says so
                        return this.setSuccessWithDataFromDatabase();
                    }
                    else {
                        // otherwise we warn the user that they are offline and the data they are looking for is not in cache
                        return of(
                            Resource.error(
                                this.constructor.name,
                                UserFriendlyError.displayableAsToast('CONNECTION_MISSING_INTERNET', true)
                            )
                        );
                    }
                })
            );
    }

    private rateLimitedNetworkFetch(dataFromDB: ResultType, fetchOptions: ResourceFetchOptions): Observable<Resource<ResultType>> {
        const rateLimiterOptions = fetchOptions.rateLimiterOptions;

        // the should fetch options always wins against the rate limiter
        // (to implement force refresh or fetch on empty data, ...)
        if (fetchOptions.shouldFetch) {
            // we reset the last fetch time for the resource id that should be fetched
            return this.rateLimiter.resetLastFetchTime(rateLimiterOptions.resourceId).pipe(
                tap(() => {
                    this.rateLimiter.removeDependantRateLimits(rateLimiterOptions);
                }),
                switchMap(() => {
                    return this.loadingAndFetchFromNetwork(dataFromDB);
                })
            );
        }
        else {
            // we simply check if it is time or not to fetch
            return this.rateLimiter.shouldFetchAndResetLastFetchTime(rateLimiterOptions)
                .pipe(
                    switchMap((shouldFetchRateLimiter: boolean) => {
                        if (shouldFetchRateLimiter) {
                            return this.loadingAndFetchFromNetwork(dataFromDB);
                        }
                        else {
                            return this.setSuccessWithDataFromDatabase();
                        }
                    })
                );
        }
    }

    private loadingAndFetchFromNetwork(dataFromDB: ResultType): Observable<Resource<ResultType>> {
        return concat<Resource<ResultType>>(
            // we initially send back another loading but this time with the current data from the database
            of(this.generateLoadingResource(dataFromDB)),
            // then we attempt to fetch from network
            this.fetchFromNetwork()
        );
    }

    private fetchFromNetwork(): Observable<Resource<ResultType>> {
        // we perform the network call
        return this.startNetworkCall()
            .pipe(
                take(1),
                switchMap((dataFromNetwork: RequestType): Observable<Resource<ResultType>> => {
                    // we notify network success
                    const convertedDataFromNetwork: ResultType = this.onNetworkSuccess(dataFromNetwork);
                    // we save the data received from network in the database
                    this.saveNetworkResult(convertedDataFromNetwork);
                    // we reload the data from the database and send it as success
                    return this.setSuccessWithDataFromDatabase();
                }),
                catchError((error: HttpErrorResponse): Observable<Resource<ResultType>> => {
                    // we notify network error
                    const convertedError: Error = this.handleNetworkFailure(error);
                    // we send the data we got from the database until now along with the error
                    return this.setFailureWithDataFromDatabase(convertedError);
                })
            );
    }

    private setSuccessWithDataFromDatabase(): Observable<Resource<ResultType>> {
        // this observable on the db remains active until is not explicitly canceled after subscription
        return this.loadFromDatabase()
            .pipe(
                switchMap(
                    (dataFromDBAfterNetwork) => {
                        return of(super.generateSuccessResource(dataFromDBAfterNetwork));
                    }
                ));
    }

    private setFailureWithDataFromDatabase(error) {
        return this.loadFromDatabase()
            .pipe(
                take(1), // we should stop updates from the DB if we are in error
                switchMap((dataFromDBAfterNetworkFail) => {
                        return of(Resource.error(this.constructor.name, error, dataFromDBAfterNetworkFail));
                    }
                ));
    }
}
