import {AppState} from '../../index';
import {adapter} from './event.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {Event} from '../../../api';

export const allEventsKey = 'allEvents';
export const publishedEventsKey = 'publishedEvents';
export const archivedEventsKey = 'archivedEvents';

export const selectAllEventsState =
    createFeatureSelector<AppState, EntityState<Event>>(allEventsKey);
export const selectPublishedEventsState =
    createFeatureSelector<AppState, EntityState<Event>>(publishedEventsKey);
export const selectArchivedEventsState =
    createFeatureSelector<AppState, EntityState<Event>>(archivedEventsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllEvents = createSelector(
    selectAllEventsState,
    (state: EntityState<Event>) => selectAll(state)
);

export const selectPublishedEvents = createSelector(
    selectPublishedEventsState,
    (state: EntityState<Event>) => selectAll(state)
);

export const selectArchivedEvents = createSelector(
    selectArchivedEventsState,
    (state: EntityState<Event>) => selectAll(state)
);

export const selectEventByIdFromAllEvents = createSelector(
    selectAllEvents,
    (state: Event[], id: number) => selectEventById(state, id)
);

export const selectEventByIdFromPublishedEvents = createSelector(
    selectPublishedEvents,
    (state: Event[], id: number) => selectEventById(state, id)
);

export const selectEventByIdFromArchivedEvents = createSelector(
    selectArchivedEvents,
    (state: Event[], id: number) => selectEventById(state, id)
);

function selectEventById(state: Event[], id: number) {
    return state.find(event => event.id === id);
}
