import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventDetailPage} from './event-detail.page';
import {AuthGuard} from '../../../guards/auth.guard';
import {ProfileGuard} from '../../../guards/profile.guard';
import {ParticipantGuard} from '../../../guards/participant.guard';
import {AnonymousEventGuard} from '../../../guards/anonymous-event.guard';
import {EventGuard} from '../../../guards/event.guard';

const routes: Routes = [
    {
        path: 'event/:id',
        component: EventDetailPage,
        children: [
            {
                path: '',
                redirectTo: 'information',
                pathMatch: 'full'
            },
            {
                path: 'information',
                loadChildren: './event-information/event-information.module#EventInformationPageModule'
            },
            {
                path: 'news',
                loadChildren: './event-news/event-news.module#EventNewsPageModule'
            },
            {
                path: 'sessions',
                loadChildren: './event-sessions/event-sessions.module#EventSessionsPageModule'
            },
            {
                path: 'participants',
                loadChildren: './event-participants/event-participants.module#EventParticipantsPageModule',
                canActivate: [AnonymousEventGuard]
            }
        ],
        canActivate: [AuthGuard, EventGuard]
    },
    {
        path: 'event/:id/news/:newsId',
        loadChildren: './event-news/event-news-detail/event-news-detail.module#EventNewsDetailPageModule',
        canActivate: [AuthGuard, EventGuard]
    },
    {
        path: 'event/:id/profile/me',
        loadChildren: './event-profile/event-profile.module#EventProfilePageModule',
        canActivate: [AuthGuard, EventGuard, AnonymousEventGuard, ProfileGuard]
    },
    {
        path: 'event/:id/profile/me/edit',
        loadChildren: './event-profile/event-profile-edit/event-profile-edit.module#EventProfileEditPageModule',
        canActivate: [AuthGuard, EventGuard, AnonymousEventGuard, ProfileGuard]
    },
    {
        path: 'event/:id/participants/:participantId',
        loadChildren: './event-participants/event-participant-detail/event-participant-detail.module#EventParticipantDetailPageModule',
        canActivate: [AuthGuard, EventGuard, AnonymousEventGuard, ParticipantGuard]
    },
    {
        path: 'event/:id/sessions/:sessionId',
        loadChildren: './event-sessions/event-session-detail/event-session-detail.module#EventSessionDetailPageModule',
        canActivate: [AuthGuard, EventGuard]
    },
    {
        path: 'event/:id/sessions/:sessionId/vote',
        loadChildren: './event-sessions/event-session-detail/event-vote/event-vote.module#EventVotePageModule',
        canActivate: [AuthGuard, EventGuard]
    },
    {
        path: 'event/:id/survey',
        loadChildren: './event-survey/event-survey.module#EventSurveyPageModule',
        canActivate: [AuthGuard, EventGuard]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class EventDetailRoutingModule { }
