const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lower = 'abcdefghijklmnopqrstuvwxyz';
const digit = '0123456789';
const special = '@*#|=()<>-_!';
const all = upper + lower + digit + special;

export function generateRandomPassword(length: number): string {
    let result = []; // we need to ensure we have some characters

    result = result.concat(generate(1, upper)); // 1 upper case
    result = result.concat(generate(1, lower)); // 1 lower case
    result = result.concat(generate(1, digit)); // 1 digit
    result = result.concat(generate(1, special)); // 1 special character
    result = result.concat(generate(length - 4, all)); // remaining - whatever

    return shuffle(result).join(''); // shuffle and make a string
}

export function generateRandomHexString(length: number): string {
    return generate(length, lower + digit).join('');
}

/**
 * generate random integer not greater than `max`
 */
export function rand(max) {
    return Math.floor(Math.random() * max);
}

/**
 * generate random character of the given `set`
 */

export function random(set) {
    return set[rand(set.length - 1)];
}

/**
 * generate an array with the given `length`
 * of characters of the given `set`
 */

export function generate(length, set) {
    const result = [];
    while (length--){
        result.push(random(set));
    }
    return result;
}

/**
 * shuffle an array randomly
 */
export function shuffle(arr) {
    let result = [];

    while (arr.length) {
        result = result.concat(arr.splice(rand[arr.length - 1]));
    }

    return result;
}
