<ion-app>
    <ion-split-pane contentId="menu-content" class="custom-pane">
        <ion-menu id="my-menu" type="overlay" contentId="menu-content" *ngIf="showMenu">
            <ion-header>
                <div id="menu-header"></div>
            </ion-header>

            <ion-content>

                <ion-list id="menu-event-list" *ngIf="(userRepository.isLoggedIn() | async) && (showEvents$ | async)">
                    <ion-menu-toggle autoHide="false">
                        <a class="menu-event-link" *ngFor="let e of (events$ | async).slice(0,3); index as ind"
                           [routerDirection]="'root'" [routerLink]="'/event/' + e.id">
                            <app-menu-event-card [myevent]="e" [i]="ind"></app-menu-event-card>
                            <hr [ngClass]="ind === ((events$ | async).slice(0,3).length - 1) ? 'menu-events-divider-last' : ''"
                                class="menu-events-divider">
                        </a>
                    </ion-menu-toggle>
                </ion-list>
                <ion-list id="inset-menu">
                    <ion-menu-toggle autoHide="false" *ngFor="let p of menuPages">
                        <ion-item *ngIf="p.needs_auth ? (userRepository.isLoggedIn() | async) : true"
                                  class="menu-button" [routerDirection]="'root'" [routerLink]="[p.url]"
                                  routerLinkActive="menu-active" #rla="routerLinkActive">
                            <img alt="" [src]="rla.isActive ? p.icon_active : p.icon" class="menu-icon"><span
                                class="menu-text">{{p.title | translate}}</span>
                            <ion-badge class="notif-unread-badge"
                                       *ngIf="p.title === 'NOTIFICATION_MENU' && (unreadNotifications$ | async) > 0">{{ (unreadNotifications$ | async) }}</ion-badge>
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-menu-toggle autoHide="false"
                                     *ngIf="(userRepository.isLoggedIn() | async) && (userRepository.getCurrentUser() | async) && (userRepository.getCurrentUser() | async).account_status !== 4">
                        <ion-item button lines="none" class="menu-button" id="menu_logout" (click)="logout()">
                            <img alt="" src="./assets/imgs/menu_logout.svg" class="menu-icon"><span
                                class="menu-text">{{'LOGOUT_MENU' | translate}}</span>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>

            </ion-content>

        </ion-menu>
        <ion-router-outlet id="menu-content" class="my-nav"></ion-router-outlet>
    </ion-split-pane>
    <app-loading-blocking></app-loading-blocking>
    <app-error></app-error>
    <cookie-law *appShowWhen="[isWebApp]" expiration="365">
        {{ 'COOKIES_TEXT' | translate}}
        <a href="https://cookies.insites.com/about-cookies/" target="_blank"> {{ 'COOKIES_READ_MORE' | translate}}</a>
    </cookie-law>
</ion-app>
