import {Component, Inject, Input, OnChanges, SimpleChange} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import DateTimeFormat = Intl.DateTimeFormat;
import {Notification} from '../../api';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import {Router} from '@angular/router';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnChanges {
  @Input() public notif: Notification;
  @Input() public i: any;

  public date: string;
  public time: string;
  public text: SafeHtml;
  public isNew: boolean;
  private dateFormatter: DateTimeFormat;

  constructor(public translate: TranslateService,
              public sanitizer: DomSanitizer,
              private router: Router,
              @Inject(ENV) public environment: Env) {
    this.dateFormatter = new Intl.DateTimeFormat(this.translate.currentLang, {day: '2-digit', month: 'long', year: 'numeric'});
  }

  public ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const changedProp = changes[propName];
        if (propName === 'notif') {
          this.date = this.dateFormatter.format(moment.tz(changedProp.currentValue.sent_at, 'Europe/Zurich').toDate());
          this.time = moment.tz(changedProp.currentValue.sent_at, 'Europe/Zurich').format('HH:mm');
          let tt;
          if (changedProp.currentValue.content.length > 203) {
            tt = changedProp.currentValue.content.substring(0, 209);
            if ((tt.substring(tt.length - 6, tt.length - 1)).indexOf('p')) {
              tt = tt.substring(0, tt.length - 6);
            }
            tt = tt + '...</p>';
          } else {
            tt = changedProp.currentValue.content;
          }
          this.text = this.sanitizer.bypassSecurityTrustHtml(tt);
          this.isNew = !changedProp.currentValue.read;
        }
      }
    }
  }

  public async onItemClick(notification: Notification) {
      await this.router.navigate(['/notifications', notification.id]);
  }
}
