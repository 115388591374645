import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingComponent} from './loading/loading.component';
import {ErrorComponent} from './error/error.component';
import {ErrorPageModule} from './error-page/error.page.module';
import {ErrorPage} from './error-page/error.page';
import {LoadingBlockingComponent} from './loading-blocking/loading-blocking.component';
import {LoadingNoDataComponent} from './loading-no-data/loading-no-data.component';
import {LoadingListNoDataComponent} from './loading-list-no-data/loading-list-no-data.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        ErrorPageModule
    ],
    exports: [
        LoadingComponent,
        LoadingBlockingComponent,
        LoadingNoDataComponent,
        LoadingListNoDataComponent,
        ErrorComponent
    ],
    declarations: [
        LoadingComponent,
        LoadingBlockingComponent,
        LoadingNoDataComponent,
        LoadingListNoDataComponent,
        ErrorComponent
    ],
})
export class UiStateManagementModule {}
