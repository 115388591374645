import {AuthSession} from '../../../api/model/authSession';
import * as AuthActions from './authSession.actions';
import {Action, createReducer, on} from '@ngrx/store';

export const initialState: AuthSession = {
    token_type: null,
    access_token: null,
    expires_in: 0,
    refresh_token: null
};

const authSessionReducer = createReducer(
  initialState,
  on(AuthActions.initAuthSession, (state, { authSession }) => saveAuthSession(state, authSession)),
  on(AuthActions.clearAuthSession, () => deleteAuthSession())
);

function saveAuthSession(state: AuthSession, authSession: AuthSession): AuthSession {
    return authSession;
}

function deleteAuthSession(): AuthSession {
    return initialState;
}

export function reducer(state: AuthSession | undefined, action: Action) {
    return authSessionReducer(state, action);
}
