import {Component, Inject, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import DateTimeFormat = Intl.DateTimeFormat;
import * as moment from 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';
import {Event} from '../../api';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';

@Component({
  selector: 'app-menu-event-card',
  templateUrl: './menu-event-card.component.html',
  styleUrls: ['./menu-event-card.component.scss'],
})
export class MenuEventCardComponent implements OnInit, OnChanges {

  @Input() public myevent: Event;
  @Input() public i: any;

  public startDay: string;
  public endDay: string;
  public startMonth: string;
  public endMonth: string;
  public basePath: string;
  public isAndroidoriOS = false;
  public liveConference: boolean;
  public streamVideo: boolean;
  private monthFormatter: DateTimeFormat;
  private dayFormatter: DateTimeFormat;
  private current: boolean;
  private published: boolean;

  constructor(public translate: TranslateService,
              private platform: Platform,
              @Inject(ENV) private environment: Env) {
    this.basePath = environment.storage_base_path;
    this.monthFormatter = new Intl.DateTimeFormat(this.translate.currentLang, {month: 'short'});
    this.dayFormatter = new Intl.DateTimeFormat(this.translate.currentLang, {day: '2-digit'});
    this.isAndroidoriOS = this.platform.is('android') || this.platform.is('ios');
  }

  ngOnInit() {}

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const changedProp = changes[propName];
        if (propName === 'myevent') {
          this.startDay = this.dayFormatter.format(moment(changedProp.currentValue.public_start_date).toDate());
          this.endDay = this.dayFormatter.format(moment(changedProp.currentValue.public_end_date).toDate());
          this.startMonth = (this.monthFormatter.format(moment(changedProp.currentValue.public_start_date).toDate())).toUpperCase();
          this.endMonth = (this.monthFormatter.format(moment(changedProp.currentValue.public_end_date).toDate())).toUpperCase();
          const eventStartDate = moment.tz(changedProp.currentValue.public_start_date, 'Europe/Zurich');
          const eventEndDate = moment.tz(changedProp.currentValue.public_end_date + ' 23:59:59', 'Europe/Zurich');
          this.current = moment().tz('Europe/Zurich').isBetween(eventStartDate, eventEndDate, null, '[]');
          this.published = this.myevent.is_published;
          this.liveConference = this.myevent.active_video_conference_sessions.length !== 0;
          this.streamVideo = this.myevent.active_video_stream_sessions.length !== 0;
        }
      }
    }
  }

  public isCurrent() {
    return this.current;
  }

  public isPublished() {
    return this.published;
  }

}
