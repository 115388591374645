import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {IframePage} from './iframe.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderComponentModule,
  ],
  declarations: [IframePage]
})
export class IframePageModule {}
