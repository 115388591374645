import {createAction, props} from '@ngrx/store';
import {EventSession} from './event-session.reducer';

export const upsertEventSessions =
    createAction('[Event Sessions] Upsert sessions', props<{ eventSessions: EventSession[] }>());

export const upsertEventSession =
    createAction('[Event Sessions] Upsert one session', props<{ eventSession: EventSession }>());

export const clearEventSessionsForAnEvent =
    createAction('[Event Sessions] Clear sessions for event', props<{ eventId: number }>());

export const clearEventSessionsInAllEvents = createAction('[Event Sessions] Clear sessions in all events');
