import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {ParticipantSetting, SettingsService} from '../../../api';
import {selectParticipantSettingsForAnEvent} from '../../../store/reducers/eventSettings/event-setting.selectors';
import {EventParticipantSetting} from '../../../store/reducers/eventSettings/event-setting.reducer';
import {upsertCurrentUserEventSettings} from '../../../store/reducers/eventSettings/event-setting.actions';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';

export class GetEventParticipantSettingsByEventId extends NetworkBoundResource<ParticipantSetting, ParticipantSetting> {
    constructor(private store: Store<AppState>,
                private settingsService: SettingsService,
                private eventId: number) {
        super();
    }

    protected loadFromDatabase(): Observable<ParticipantSetting> {
        return this.store.pipe(select(selectParticipantSettingsForAnEvent, this.eventId));
    }

    protected saveNetworkResult(participantSetting: ParticipantSetting) {
        const settingsObject = {
            is_visible: !!participantSetting.is_visible,
            is_agenda_visible: !!participantSetting.is_agenda_visible,
            is_contactable: !!participantSetting.is_contactable,
            is_push_notification_enabled: !!participantSetting.is_push_notification_enabled,
            is_email_notification_enabled: !!participantSetting.is_email_notification_enabled
        };
        const eventParticipantSettings: EventParticipantSetting = {
            ...settingsObject,
            eventId: this.eventId
        };
        this.store.dispatch(upsertCurrentUserEventSettings( { eventId: this.eventId, eventParticipantSettings }));
    }

    protected shouldFetch(dataFromDatabase: ParticipantSetting): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
                resourceId: this.constructor.name + '-' + this.eventId
            }
        };
    }

    protected startNetworkCall(): Observable<ParticipantSetting> {
        return this.settingsService.getMySettings(this.eventId);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_SETTINGS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId);
    }
}
