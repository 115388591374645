import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {StaticPage, StaticPagesService} from '../../api';
import {OrderedStaticPage, selectAllStaticPages} from '../../store/reducers/staticPages/staticPage.selectors';
import {clearStaticPages, upsertStaticPages} from '../../store/reducers/staticPages/staticPage.actions';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetStaticPages extends NetworkBoundResource<StaticPage[], StaticPage[]> {

    constructor(private store: Store<AppState>,
                private staticPagesService: StaticPagesService,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<StaticPage[]> {
        return this.store.pipe(select(selectAllStaticPages));
    }

    protected shouldFetch(dataFromDatabase: StaticPage[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.THEN_MINUTES,
                resourceId: this.constructor.name
            }
        };
    }

    protected startNetworkCall(): Observable<StaticPage[]> {
        return this.staticPagesService.listStaticPages();
    }

    protected saveNetworkResult(staticPages: StaticPage[]) {
        this.store.dispatch(clearStaticPages());
        const orderedStaticPages: OrderedStaticPage[] = [];
        for (let i = 0; i < staticPages.length; i++) {
            const orderedStaticPage: OrderedStaticPage = {...staticPages[i], rank: i};
            orderedStaticPages.push(orderedStaticPage);
        }
        this.store.dispatch(upsertStaticPages({ staticPages: orderedStaticPages }));
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        this.cleanUpOnNetworkFailure();
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('STATIC_PAGES_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name);
    }
}
