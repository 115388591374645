<app-header titleKey="RESTAURANT_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content class="ion-padding">
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>

    <div id="restaurant-api-page" class="practical_info_page">
        <div class="card_cicg_color">
            <ion-card id="menu-today"
                      *ngIf="todayMenu$ && (dailyMenus$ | async)?.length > 0">
                <ion-card-header>
                    <h3 class="text-white">{{(todayMenu$ | async).day}}</h3>
                    <h1 class="text-white ion-margin-bottom">{{'TODAY_MENU_TITLE' | translate}}</h1>
                    <div *ngFor="let dish of (todayMenu$ | async).dishes; let i = index">
                        <h4 class="text-white ion-margin-bottom">0{{ i + 1 + '  ' + dish}}</h4>
                    </div>
                    <hr class="white-hr"/>
                    <div class="text-white ion-margin-top">
                        <i id="pricing">
                            {{translate.currentLang === 'fr' ? (todayMenu$ | async).pricing_fr : (todayMenu$ | async).pricing_en}}
                        </i>
                    </div>
                </ion-card-header>
            </ion-card>
        </div>

        <div id="weekly-menu-container" *ngIf="dailyMenus$ && (dailyMenus$ | async)?.length > 0">
            <ion-grid>
                <ion-row class="header-row ion-align-items-center">
                    <ion-col class="ion-text-left" size="12">
                        <h2 id="myevents-label">{{ 'WEEKLY_MENU_TITLE' | translate }}</h2>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div id="slides-container" class="slide-container-weekly-menu-card">
                <ion-fab *ngIf="!isAndroidOriOS" vertical="center" horizontal="start" (click)="slideBack()"
                         class="arrow--back">
                    <ion-fab-button size="small">
                        <ion-icon name="ios-arrow-back"></ion-icon>
                    </ion-fab-button>
                </ion-fab>
                <ion-fab *ngIf="!isAndroidOriOS" vertical="center" horizontal="end" (click)="slideForward()"
                         class="arrow--forward">
                    <ion-fab-button size="small">
                        <ion-icon name="ios-arrow-forward"></ion-icon>
                    </ion-fab-button>
                </ion-fab>
                <!-- <div *ngIf="!isAndroidOriOS" class="arrow arrow&#45;&#45;back" (click)="slideBack()"><ion-icon name="ios-arrow-back"></ion-icon></div>-->
                <!--   <div *ngIf="!isAndroidOriOS" class="arrow arrow&#45;&#45;forward" (click)="slideForward()"><ion-icon name="ios-arrow-forward"></ion-icon></div>-->
                <ion-slides class="slides-weekly-menu-card"
                            #dailyMenuSlider
                            [options]="{spaceBetween: 3, speed: 100, slidesPerView: isAndroidOriOS ? 1.23: 2.42}"
                            [pager]="(dailyMenus$ | async).length > 1">
                    <ion-slide *ngFor="let dailyMenu of (dailyMenus$ | async); let i = index;">
                        <ion-card class="ion-no-padding weekly-menu-card">
                            <ion-card-header>
                                <h1>{{dailyMenu.day}}</h1>
                            </ion-card-header>
                            <ion-card-content class="ion-text-wrap"
                                              *ngIf="dailyMenu.dishes.length > 0">
                                <div class="ion-margin-top"
                                     *ngFor="let dish of dailyMenu.dishes; let i = index">
                                    <h5>Menu {{ i + 1 }}</h5>
                                    <p>{{dish}}</p>
                                </div>
                            </ion-card-content>
                            <ion-card-content class="ion-text-wrap"
                                              *ngIf="dailyMenu.dishes.length === 0">
                                <h5>{{'LABEL_CLOSED' | translate}}</h5>
                            </ion-card-content>
                        </ion-card>
                    </ion-slide>
                </ion-slides>
            </div>
        </div>

        <span id="practical-information-empty-list"
              *ngIf="dailyMenus$ && (dailyMenus$ | async)?.length === 0">
            {{'API_NO_RESULTS' | translate}}
        </span>
    </div>
    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noMenuDataLoading>
        <div class="practical_info_page">
            <div class="card_cicg_color">
                <ion-card>
                    <ion-card-header>
                        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 80%; line-height: 18px;"></ion-skeleton-text>
                        <div>
                            <ion-skeleton-text animated style="width: 50%; line-height: 16px;"></ion-skeleton-text>
                        </div>
                        <hr class="white-hr"/>
                        <div class="text-white ion-margin-top">
                            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
                        </div>
                    </ion-card-header>
                </ion-card>
            </div>
        </div>
    </app-loading-no-data>
</ion-content>
