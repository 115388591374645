import {Observable} from 'rxjs';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {Survey, SurveysService} from '../../../api';

export class GetEventSurvey extends JustNetworkResource<Survey, Survey> {
    constructor(private surveysService: SurveysService,
                private eventId: number) {
        super(true);
    }

    protected startNetworkCall(): Observable<Survey> {
        return this.surveysService.getSurvey(this.eventId);
    }

    protected onNetworkSuccess(survey: Survey): Survey {
        return survey;
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        if (error.error.message === 'User already answered this survey') {
            return UserFriendlyError.displayableAsToast('SURVEY_NO_TWICE_MESSAGE', false, true);
        }
        else {
            return error;
        }
    }
}
