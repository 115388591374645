import {createAction, props} from '@ngrx/store';
import {EventParticipant} from './event-participant.reducer';
import {EventSession} from '../eventSessions/event-session.reducer';

export const upsertEventParticipants =
    createAction('[Event Participants] Upsert participants', props<{ eventParticipants: EventParticipant[] }>());

export const upsertEventParticipant =
    createAction('[Event Participants] Upsert one participant', props<{ eventParticipant: EventParticipant }>());

export const updateEventParticipantTalks =
    createAction('[Event Participants] Update talks in participant', props<{ participantId: number; eventSessions: EventSession[] }>());

export const updateEventParticipantAgenda =
    createAction('[Event Participants] Upsert agenda in participant', props<{ participantId: number; eventSessions: EventSession[] }>());

export const clearEventParticipantAgenda =
    createAction('[Event Participants] Clear agenda in participant', props<{ participantId: number; }>());

export const clearEventParticipantsForAnEvent =
    createAction('[Event Participants] Clear participants for event', props<{ eventId: number }>());

export const clearEventParticipantsInAllEvents =
    createAction('[Event Participants] Clear participants in all events');

// CURRENT USER PARTICIPANTS ACTIONS

export const upsertCurrentUserEventParticipantAgendaSession =
    createAction('[Event Participants] Upsert one agenda session in current user participant',
        props<{ eventId: number; eventSession: EventSession }>());

export const removeCurrentUserEventParticipantAgendaSession =
    createAction('[Event Participants] Remove one agenda session from current user participant',
        props<{ eventId: number; eventSessionId: number }>());

export const updateCurrentUserEventParticipantAgenda =
    createAction('[Event Participants] Upsert agenda in current user participant',
        props<{ eventId: number; eventSessions: EventSession[] }>());

export const clearCurrentUserEventParticipantAgenda =
    createAction('[Event Participants] Clear agenda in current user participant', props<{ eventId: number; }>());

export const upsertCurrentUserEventParticipant =
    createAction('[Event Participants] Upsert one participant of current user\'s events', props<{ eventParticipant: EventParticipant }>());

export const clearCurrentUserEventParticipants =
    createAction('[Event Participants] Clear all the participants of current user\'s events');
