<div id="code-dialog-container">
    <ion-button id="close-button" fill="clear" (click)="dismissCode()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
    <ion-grid>
        <ion-row class="header-row ion-align-items-center">
            <ion-col size="12" class="ion-text-left">
                <h2>{{"CODE_DIALOG_LABEL" | translate}}</h2>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="ion-text-center ion-align-items-center" id="invite-code">
        <form [formGroup]="inviteCodeForm">
            <ion-item>
                <ion-label id="invite-code-label" position="floating">{{'INVITE_CODE_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="invite-code-input" type="text" formControlName="code" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item id="invite-code-input-error" lines="none" *ngIf="!inviteCodeForm.controls.code.valid  && inviteCodeForm.controls.code.touched">
                <ion-text color="danger"><p>{{'INVITE_CODE_INPUT_ERROR' | translate}}</p></ion-text>
            </ion-item>
                <ion-button id="back-button" class="ion-margin"
                        (click)="dismissCode()">
                    {{"LABEL_EXIT" | translate}}
                </ion-button>
                <ion-button id="submit-code-button" class="ion-margin"
                        (click)="submitCode()">
                    {{"SUBMIT_CODE_BUTTON" | translate}}
                </ion-button>
        </form>
    </div>
    <ion-grid *appShowWhen="[isMobileApp]">
        <ion-row class="header-row ion-align-items-center">
            <ion-col size="12" class="ion-text-left">
                <h2>{{"SCAN_CODE_DIALOG_LABEL" | translate}}</h2>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="ion-text-center ion-align-items-center" id="scan-code" *appShowWhen="[isMobileApp]">
            <ion-button fill="outline" id="scan-qrcode-button"
                    (click)="scanCode()">
                <ion-icon slot="start" name="camera"></ion-icon>
                {{"SCAN_QR_LABEL" | translate}}
            </ion-button>
    </div>
</div>
