import {Component, OnDestroy} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthSessionRepository} from '../../repositories/auth/authSession.repository';
import {switchMap, tap} from 'rxjs/operators';
import {UserRepository} from '../../repositories/user/user.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {Router} from '@angular/router';
import {consumeOnlySuccess} from '../../common/rxjs/operators';
import {Subscription} from 'rxjs';
import {EventsRepository} from '../../repositories/events/events.repository';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
    public loginForm: FormGroup;
    private loginSubscription: Subscription;

    constructor(private modalController: ModalController,
                private authSessionRepository: AuthSessionRepository,
                private userRepository: UserRepository,
                private eventRepository: EventsRepository,
                private router: Router,
                private navParams: NavParams,
                private resourceStatusService: ResourceStatusService) {

        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        });
        this.loginSubscription = Subscription.EMPTY;
    }

    ngOnDestroy(): void {
        this.loginSubscription.unsubscribe();
    }

    public login() {
        this.loginSubscription = this.authSessionRepository.login(this.loginForm.value.email, this.loginForm.value.password).pipe(
            consumeOnlySuccess(this.resourceStatusService),
            tap(() => {
                // we clean app user and published events in case there was an anonymous user and event added by code
                this.userRepository.clearUser();
            }),
            switchMap(() => {
                return this.userRepository.loadUser();
            }),
            consumeOnlySuccess(this.resourceStatusService),
        ).subscribe(async () => {
            if (this.navParams.get('redirectURL')) {
                await this.router.navigate([this.navParams.get('redirectURL')]);
            }
            this.dismissLogin();
        });
    }

    public async dismissLogin() {
        await this.modalController.dismiss();
    }

    public async goToRecoverPassword() {
        await this.router.navigate(['/password/password-forgotten']);
        this.dismissLogin();
    }

    public submit() {
        if (this.loginForm.valid) {
            this.login();
        }
    }

}
