import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import { SurveyQuestionComponent } from './survey-question';
@NgModule({
  declarations: [
    SurveyQuestionComponent
  ],
  imports: [
    TranslateModule.forChild(),
    IonicModule,
    CommonModule
  ],
  exports: [
    SurveyQuestionComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SurveyQuestionComponentModule {}
