import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Session, SessionsService} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {selectEventSessionById} from '../../../store/reducers/eventSessions/event-session.selectors';
import {upsertEventSession} from '../../../store/reducers/eventSessions/event-session.actions';
import {EventSession} from '../../../store/reducers/eventSessions/event-session.reducer';
import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';

export class GetEventSessionById extends NetworkBoundResource<Session, Session> {

    constructor(private store: Store<AppState>,
                private sessionsService: SessionsService,
                private eventId: number,
                private sessionId: number) {
        super();
    }

    protected loadFromDatabase(): Observable<Session> {
        return this.store.pipe(select(selectEventSessionById, this.sessionId));
    }

    protected shouldFetch(dataFromDatabase: Session): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.eventId + '-' + this.sessionId
            }
        };
    }

    protected startNetworkCall(): Observable<Session> {
        return this.sessionsService.getSession(this.eventId, this.sessionId);
    }

    protected saveNetworkResult(session: Session) {
        const eventSession: EventSession = {...session, eventId: this.eventId};
        this.store.dispatch(upsertEventSession({ eventSession }));
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_SESSION_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId + '-' + this.sessionId);
    }
}
