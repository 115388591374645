import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Event} from '../../../api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() public titleKey: string;
  @Input() public titleInterpolation: string;
  @Input() public titleText = '';
  @Input() public defaultBackHref = '';
  @Input() public menuIconEnabled = true;
  @Input() public backArrowEnabled = false;
  @Input() public offlineIconEnabled = true;
  @Input() public qrCodeIconEnabled = false;
  @Input() public adminIconEnabled = false;
  @Input() public profileIconEnabled = false;
  @Input() public event: Event = null;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

}
