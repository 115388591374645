import {Component} from '@angular/core';

@Component({
    selector: 'app-loading-no-data',
    templateUrl: './loading-no-data.component.html',
    styleUrls: ['./loading-no-data.component.scss'],
})
export class LoadingNoDataComponent {
    public isShown: boolean;

    constructor() {
        this.isShown = false;
    }

    public show() {
        this.isShown = true;
    }

    public hide() {
        this.isShown = false;
    }
}
