<app-header titleKey="RECOVER_PASSWORD" [menuIconEnabled]="false" [backArrowEnabled]="true" defaultBackHref="home"></app-header>

<ion-content>
  <div class="ion-text-center ion-align-items-center" id="recover-password-container">
    <form [formGroup]="passwordRecoveryForm">
      <ion-item class="ion-margin-bottom">
        <ion-label id="email-label" position="floating">{{'EMAIL_INPUT_LABEL' | translate}}</ion-label>
        <ion-input required id="email-input" type="email" formControlName="email" (keyup.enter)="submit()"></ion-input>
      </ion-item>
      <ion-button id="recover-password-button"
              (click)="recoverPassword()" [disabled]="!passwordRecoveryForm.valid">
        {{"RECOVER_PASSWORD" | translate}}
      </ion-button>
    </form>
  </div>
</ion-content>
