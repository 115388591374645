import {AppState} from '../../index';
import {adapter} from './event-news.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {EventNews} from './event-news.reducer';

export const eventNewsKey = 'eventNews';

export const selectEventNewsState =
    createFeatureSelector<AppState, EntityState<EventNews>>(eventNewsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectAllEventNewsForAnEvent = createSelector(
    selectEventNewsState,
    (state: EntityState<EventNews>, eventId: number) => selectAll(state).filter((eventNews: EventNews) => {
        return eventNews.eventId === eventId;
    })
);

export const selectAnEventNewsById = createSelector(
    selectEventNewsState,
    (state: EntityState<EventNews>, id: number) =>
        selectAll(state).find((eventNews: EventNews) => eventNews.id === id)
);
