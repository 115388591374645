import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {NotificationsRepository} from '../../../repositories/notifications/notifications.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {delay, flatMap, shareReplay, tap} from 'rxjs/operators';
import {consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {Notification} from '../../../api';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import {FilesRepository} from '../../../repositories/files/files.repository';
import {AbstractFileOpener} from '../../common/AbstractFileOpener';
import {FileCarouselEvent} from '../../../components/file-carrousel/file-carrousel';
import {Observable} from 'rxjs';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-notification-detail',
    templateUrl: './notification-detail.page.html',
    styleUrls: ['./notification-detail.page.scss'],
})
export class NotificationDetailPage extends AbstractFileOpener implements AfterViewInit {

    // OUTPUTS
    public notification$: Observable<Notification>;

    // VARIABLES
    public date: string;
    private eventId: number;

    @ViewChild('noNotificationDataLoading', {static: false}) noNotificationDataLoading: LoadingNoDataComponent;

    constructor(private notificationsRepository: NotificationsRepository,
                filesRepository: FilesRepository,
                resourceStatusService: ResourceStatusService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                @Inject(ENV) public environment: Env) {
        super(filesRepository, resourceStatusService);
    }

    ngAfterViewInit() {
        this.setUpNotificationObserver();
    }

    public async openEvent() {
        await this.router.navigate(['event', this.eventId]);
    }

    public onFileCarouselClick(event: FileCarouselEvent) {
        super.openFile(event.eventId, event.media);
    }

    private setUpNotificationObserver() {
        this.notification$ = this.route.paramMap.pipe(
            delay(0),
            flatMap((params: ParamMap) => {
                // tslint:disable-next-line:radix
                const id = parseInt(params.get('id'));
                return this.notificationsRepository.getNotificationById(id);
            }),
            manageLoadingNoData(this.noNotificationDataLoading),
            consume(this.resourceStatusService),
            tap((notification: Notification) => {
                this.formatDate(notification);
                this.eventId = notification.event_id;
            }),
            shareReplay(1)
        );
    }

    private formatDate(notification: Notification) {
        const dateFormatter =
            new Intl.DateTimeFormat(this.translate.currentLang,
                {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
        this.date = dateFormatter.format(moment.tz(notification.sent_at, 'Europe/Zurich').toDate());
    }
}
