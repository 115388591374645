<app-header [titleText]="title" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<div class="iframe-container">

    <iframe *ngIf="url$ != null"
            id="practical-information-web-page"
            [attr.src]="(url$ | async)"
            frameBorder="0"
            (load)="onLoad()"
            (error)="onError()"
            width="100%"
            height="100%"
            sandbox="allow-popups allow-scripts allow-same-origin"
            class="iframe-container__page">
    </iframe>

    <ion-skeleton-text *ngIf="onLoading"
                       animated
                       class="iframe-container__on-loading">
    </ion-skeleton-text>
</div>
