import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UserRepository} from '../../../repositories/user/user.repository';
import {ToastController} from '@ionic/angular';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Resource} from '../../../common/repository/resource';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordResetDataRequest, User} from '../../../api';
import {switchMap, tap} from 'rxjs/operators';
import {AuthSessionRepository} from '../../../repositories/auth/authSession.repository';
import {consume, consumeOnlySuccess} from '../../../common/rxjs/operators';
import {Subscription} from 'rxjs';
import {Status} from '../../../common/repository/status';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.page.html',
    styleUrls: ['./password-reset.page.scss'],
})
export class PasswordResetPage implements OnInit, AfterViewInit, OnDestroy {

    public passwordResetForm: FormGroup;
    private passwordResetDataRequest: PasswordResetDataRequest;
    private token: string;
    private resetPasswordSubscription: Subscription;

    constructor(private userRepository: UserRepository,
                private toastController: ToastController,
                private authSessionRepository: AuthSessionRepository,
                private resourceStatusService: ResourceStatusService,
                private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute) {
        this.passwordResetForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
            password_confirmation: new FormControl('', [Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])
        });
        this.resetPasswordSubscription = Subscription.EMPTY;
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.token = params.get('token');
        });
    }

    ngAfterViewInit(): void {
        // this.resourceStatusService.consumeResource(Resource.success(), 'passwordReset');
    }

    ngOnDestroy(): void {
        this.resetPasswordSubscription.unsubscribe();
    }

    public resetPassword() {
        this.passwordResetDataRequest = {
            token: this.token,
            email: this.passwordResetForm.value.email,
            password: this.passwordResetForm.value.password,
            password_confirmation: this.passwordResetForm.value.password_confirmation
        };
        this.resetPasswordSubscription = this.userRepository.resetPassword(
            this.token, this.passwordResetDataRequest).pipe(
                consumeOnlySuccess(this.resourceStatusService),
                tap(async () => {
                    const toast = await this.toastController.create({
                        message: this.translateService.instant('RESET_PASSWORD_SUCCESS'),
                        duration: 4000
                    });
                    await toast.present();
                }),
                switchMap(() => {
                    return this.authSessionRepository.login(this.passwordResetForm.value.email, this.passwordResetForm.value.password);
                }),
                consumeOnlySuccess(this.resourceStatusService),
                switchMap(() => {
                    return this.userRepository.loadUser();
                }),
                consumeOnlySuccess(this.resourceStatusService)
            ).subscribe(async () => {
                await this.router.navigate(['/home']);
            });
    }

    public submit() {
        if (this.passwordResetForm.valid) {
            this.resetPassword();
        }
    }

}
