import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthSessionRepository} from '../../repositories/auth/authSession.repository';
import {UserRepository} from '../../repositories/user/user.repository';
import {ResourceStatusService} from '../../common/ui/state-management/resource.status.service';
import {Router} from '@angular/router';
import {IS_MOBILE_APP} from '../../common/ui/directives/show.when';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent implements OnInit {
  public inviteCodeForm: FormGroup;
  public readonly isMobileApp = IS_MOBILE_APP;

  constructor(private modalController: ModalController,
              private authSessionRepository: AuthSessionRepository,
              private userRepository: UserRepository,
              private router: Router,
              private resourceStatusService: ResourceStatusService) {

    this.inviteCodeForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  ngOnInit() {

  }

  public async submitCode() {
    if (this.inviteCodeForm.valid) {
      const code: string = this.inviteCodeForm.value.code.toUpperCase();
      await this.modalController.dismiss({code});
    }

  }

  public async dismissCode() {
    await this.modalController.dismiss();
  }

  public async scanCode(){
    await this.modalController.dismiss({action: 'scan'});
  }

  public async submit() {
    if (this.inviteCodeForm.valid) {
      await this.submitCode();
    }
  }

}
