import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Session, SessionsService} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {selectEventSessionsForAnEvent} from '../../../store/reducers/eventSessions/event-session.selectors';
import {
    clearEventSessionsForAnEvent,
    upsertEventSessions
} from '../../../store/reducers/eventSessions/event-session.actions';
import {EventSession} from '../../../store/reducers/eventSessions/event-session.reducer';
import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';
import {GetEventSessionById} from './getEventSessionById';

export class GetEventSessions extends NetworkBoundResource<Session[], Session[]> {

    constructor(private store: Store<AppState>,
                private sessionsService: SessionsService,
                private eventId: number,
                private forceRefresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Session[]> {
        return this.store.pipe(select(selectEventSessionsForAnEvent, this.eventId));
    }

    protected shouldFetch(dataFromDatabase: Session[]): ResourceFetchOptions {
        const dependentResources: string [] = [];
        for (const session of dataFromDatabase) {
            dependentResources.push(GetEventSessionById.name + '-' + this.eventId + '-' + session.id);
        }

        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.forceRefresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.eventId,
                rateLimitDependantResourceIds: dependentResources,
            }
        };
    }

    protected startNetworkCall(): Observable<Session[]> {
        return this.sessionsService.listSessions(this.eventId);
    }

    protected saveNetworkResult(sessions: Session[]) {
        // we first delete all the sessions
        this.store.dispatch(clearEventSessionsForAnEvent({ eventId: this.eventId} ));
        // then we add them all
        const eventSessions: EventSession[] = sessions.map((session: Session): EventSession => {
            return {...session, eventId: this.eventId};
        });
        this.store.dispatch(upsertEventSessions({ eventSessions }));
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_SESSIONS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId);
    }
}
