import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthSessionService} from '../../api';
import {AuthSession} from '../../api/model/authSession';
import {initAuthSession} from '../../store/reducers/auth/authSession.actions';
import {AppState} from '../../store';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class Login extends JustNetworkResource<boolean, AuthSession> {
    constructor(private store: Store<AppState>,
                private authSessionService: AuthSessionService,
                private user: string,
                private password: string) {
        super(true);
    }

    protected startNetworkCall(): Observable<AuthSession> {
        return this.authSessionService.login(this.user, this.password);
    }

    protected onNetworkSuccess(authSession: AuthSession): boolean {
        this.store.dispatch(initAuthSession({ authSession }));
        return authSession.access_token != null;
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('LOGIN_ERROR_TOAST', false);
    }
}
