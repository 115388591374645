import {Room} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as StaticPageRoomsActions from '../staticPageRooms/staticPageRooms.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export function selectId(staticPageRoom: Room): string {
    return staticPageRoom.id.toString();
}

export const adapter: EntityAdapter<Room> = createEntityAdapter<Room>({
    selectId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<Room> = adapter.getInitialState();

const staticPageRoomsReducer = createReducer(
  initialState,
  on(StaticPageRoomsActions.upsertStaticPageRooms, (state, { staticPageRooms }) => adapter.upsertMany(staticPageRooms, state)),
  on(StaticPageRoomsActions.upsertStaticPageRoom, (state, { staticPageRoom }) => adapter.upsertOne(staticPageRoom, state)),
  on(StaticPageRoomsActions.clearStaticPageRooms, (state) => adapter.removeAll(state)),
);

export function reducer(state: EntityState<Room> | undefined, action: Action) {
    return staticPageRoomsReducer(state, action);
}
