import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {TpgDeparture} from '../../../api';
import {shareReplay, switchMap} from 'rxjs/operators';
import {IonRefresher, Platform} from '@ionic/angular';
import {Browser} from '@capacitor/browser';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-tpg-api',
    templateUrl: './tpg-api.page.html',
    styleUrls: ['./tpg-api.page.scss'],
})
export class TpgApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public tpgDepartures$: Observable<TpgDeparture[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noTpgDataLoading', {static: false}) noTpgDataLoading: LoadingNoDataComponent;

    public isAndroidOriOS: boolean;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform) {
        this.retry = new BehaviorSubject<boolean>(true);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit(): void {
        this.setUpTpgDeparturesObservable();
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    public async openLink() {
        await Browser.open(
            {
                url: 'https://tpg.ch',
                windowName: '_system'
            }
        );
    }

    private setUpTpgDeparturesObservable() {
        this.tpgDepartures$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap(() => {
                    return this.staticPagesRepository.getTpgApiPage();
                }),
                manageLoadingNoData(this.noTpgDataLoading),
                completeIonRefresher(this.ionRefresher),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
