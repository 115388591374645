<div id="login-container">
    <ion-button id="close-button" fill="clear" (click)="dismissLogin()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
    <ion-grid>
        <ion-row class="header-row ion-align-items-center">
            <ion-col size="12" class="ion-text-left">
                <h2>{{"LOGIN_DIALOG_LABEL" | translate}}</h2>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="ion-text-center ion-align-items-center" id="login">
        <form [formGroup]="loginForm">
            <ion-item>
                <ion-label id="email-label" position="floating" >{{'EMAIL_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="email-input" type="email" formControlName="email" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item lines="none" id="email-input-error" *ngIf="!loginForm.controls.email.valid && loginForm.controls.email.touched">
                <ion-text color="danger">
                    <p>{{'EMAIL_INPUT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-item>
                <ion-label id="password-label" position="floating">{{'PASSWORD_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="password-input" type="password" formControlName="password" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item lines="none" id="password-input-error" *ngIf="!loginForm.controls.password.valid && loginForm.controls.password.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_INPUT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
                <ion-button id="back-button" class="ion-margin" (click)="dismissLogin()">
                    {{"LABEL_EXIT" | translate}}
                </ion-button>
                <ion-button id="login-button" class="ion-margin" (click)="login()" [disabled]="!loginForm.valid">
                    {{"LOGIN_BUTTON" | translate}}
                </ion-button>
            <br><br>
            <a id="password-forgotten" (click)="goToRecoverPassword()">{{ 'PASSWORD_FORGOTTEN_LABEL' | translate}}</a>
        </form>
    </div>
</div>

