import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationCardComponent} from './notification-card.component';
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [NotificationCardComponent],
  exports: [NotificationCardComponent]
})
export class NotificationCardComponentModule {}
