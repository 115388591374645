import {HttpErrorResponse} from '@angular/common/http';

export enum UserFriendlyErrorType {
    TOAST= 'TOAST',
    DIALOG= 'DIALOG'
}

export class UserFriendlyError implements Error {
    private static readonly GENERIC_MESSAGE_KEY = 'GENERIC_ERROR'; // key in the translation json

    public static genericAsToast(showRetry: boolean,
                                 originalHttpErrorResponse: HttpErrorResponse = null): UserFriendlyError {
        return new UserFriendlyError(this.GENERIC_MESSAGE_KEY,
            'generic error as toast',
            UserFriendlyErrorType.TOAST,
            showRetry,
            false,
            originalHttpErrorResponse);
    }

    public static genericAsDialog(showRetry: boolean,
                                  showClose: boolean,
                                  originalHttpErrorResponse: HttpErrorResponse = null): UserFriendlyError {
        return new UserFriendlyError(this.GENERIC_MESSAGE_KEY,
            'generic error as dialog',
            UserFriendlyErrorType.DIALOG,
            showRetry,
            showClose,
            originalHttpErrorResponse);
    }

    public static displayableAsToast(messageTransKey: string,
                                     showRetry: boolean,
                                     showClose: boolean = false, originalHttpErrorResponse: HttpErrorResponse = null): UserFriendlyError {
        return new UserFriendlyError(messageTransKey,
            'error displayable as toast',
            UserFriendlyErrorType.TOAST,
            showRetry,
            showClose,
            originalHttpErrorResponse);
    }

    public static displayableAsDialog(messageTransKey: string,
                                      showRetry: boolean,
                                      showClose: boolean,
                                      originalHttpErrorResponse: HttpErrorResponse = null): UserFriendlyError {
        return new UserFriendlyError(messageTransKey,
            'error displayable as dialog',
            UserFriendlyErrorType.DIALOG,
            showRetry,
            showClose,
            originalHttpErrorResponse);
    }

    public message: string;
    public messageTransKey: string;
    public name: string;
    public type: UserFriendlyErrorType;
    public showRetry: boolean;
    public showClose: boolean;
    public originalHttpErrorResponse: HttpErrorResponse;

    private constructor(messageTransKey: string,
                        name: string,
                        type: UserFriendlyErrorType,
                        showRetry: boolean,
                        showClose: boolean,
                        originalHttpErrorResponse: HttpErrorResponse) {
        this.message = messageTransKey;
        this.messageTransKey = messageTransKey;
        this.name = name;
        this.type = type;
        this.showRetry = showRetry;
        this.showClose = showClose;
        this.originalHttpErrorResponse = originalHttpErrorResponse;
    }

    public isForbiddenError(): boolean {
        return this.originalHttpErrorResponse && this.originalHttpErrorResponse.status === 403;
    }
}
