import {AppState} from '../../index';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import {adapter, EventParticipantSetting} from './event-setting.reducer';

export const eventSettingsKey = 'eventSettings';

export const selectEventParticipantSettingsState =
    createFeatureSelector<AppState, EntityState<EventParticipantSetting>>(eventSettingsKey);

// get the selectors
const {
    selectAll,
} = adapter.getSelectors();

export const selectParticipantSettingsForAnEvent = createSelector(
    selectEventParticipantSettingsState,
    (state: EntityState<EventParticipantSetting>, eventId: number) =>
        selectAll(state).find((eventParticipantSetting: EventParticipantSetting) => {
        return eventParticipantSetting.eventId === eventId;
    })
);
