import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PublicSurveyWelcomePage } from './public-survey-welcome.page';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../../common/ui/directives/directives.module';
import {AppDirectivesModule} from '../../../directives/app.directives.module';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        DirectivesModule,
        AppDirectivesModule,
        HeaderComponentModule
    ],
    declarations: [PublicSurveyWelcomePage]
})
export class PublicSurveyWelcomePageModule {}
