import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {finalize, share, tap} from 'rxjs/operators';
import {ToastController} from '@ionic/angular';
import {EventsRepository} from '../repositories/events/events.repository';
import {Resource} from '../common/repository/resource';
import {Event} from '../api';
import {takeFirstSuccessOrErrorAndComplete} from '../common/rxjs/operators';
import {ResourceStatusService} from '../common/ui/state-management/resource.status.service';
import {getToastOptionError} from '../pages/common/constants';
import {TranslateService} from '@ngx-translate/core';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Status} from '../common/repository/status';

@Injectable({
    providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

    constructor(private eventsRepository: EventsRepository,
                private resourceStatusService: ResourceStatusService,
                private router: Router,
                private translateService: TranslateService,
                private toastController: ToastController) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // tslint:disable-next-line:radix
        const id = parseInt(next.paramMap.get('id'));

        // We use a subject here to understand when the guard system of angular unsubscribes from
        // our observable so that we can finalize the flow ourselves if needed and also guarantee
        // our guard execution is done to the end and the loading and error info do not end up in a strange state
        const guardSubject: Subject<boolean | UrlTree> = new Subject();
        const guardObservable: Observable<boolean | UrlTree> = guardSubject.asObservable().pipe(
            finalize(() => {
            }),
            share()
        );

        this.eventsRepository.getEventById(id).pipe(
            tap((eventResource: Resource<Event>) => {
                if (eventResource.status === Status.LOADING) {
                    this.resourceStatusService.consumeResource(eventResource);
                }
            }),
            takeFirstSuccessOrErrorAndComplete()
        ).subscribe((eventResource: Resource<Event>) => {
            if (eventResource.status === Status.SUCCESS) {
                this.resourceStatusService.consumeResource(eventResource);
                // if the user is not a participant we do not allow navigation
                if (!EventsRepository.isCurrentUserEventParticipant(eventResource.data)) {
                    return fromPromise(this.showReasonOfRefusal().then(() => {
                        guardSubject.next(this.router.createUrlTree(['event', id]));
                    }));
                }
                else {
                    guardSubject.next(true);
                }
            }
            else {
                // ERROR case
                this.resourceStatusService.consumeResource(eventResource);
                guardSubject.next(this.router.createUrlTree(['event', id]));
            }
        });

        return guardObservable;
    }

    private async showReasonOfRefusal() {
        const message = this.translateService.instant('NO_PARTICIPANT_TOAST');
        const toastOptions = getToastOptionError(message);
        const toast = await this.toastController.create(toastOptions);
        await toast.present();
    }
}
