import {Observable} from 'rxjs';
import {ParticipantsService, UserEmail} from '../../../api';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class ValidateInviteByToken extends JustNetworkResource<UserEmail, UserEmail> {
    constructor(private participantService: ParticipantsService,
                private inviteId: string) {
        super(true);
    }

    startNetworkCall(): Observable<UserEmail> {
        return this.participantService.validateInviteByToken(this.inviteId);
    }

    onNetworkSuccess(dataFromNetwork: UserEmail): UserEmail {
        return dataFromNetwork;
    }

    onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('INVITE_TOKEN_EXPIRED', false);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }
}
