import {Inject, Injectable} from '@angular/core';
import {concat, Observable, of, pipe} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Resource} from '../../common/repository/resource';
import {Notification, NotificationsService} from '../../api';
import {GetNotifications} from './getNotifications';
import {GetNotificationById} from './getNotificationById';
import {AppState} from '../../store';
import {
    selectAllNotifications,
    selectNumberOfUnreadNotifications,
    selectWelcomeNotification
} from '../../store/reducers/notifications/notification.selectors';
import {
    deleteNotificationByPredicate,
    upsertNotification
} from '../../store/reducers/notifications/notification.actions';
import {TranslateService} from '@ngx-translate/core';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {UserRepository} from '../user/user.repository';
import {ENV} from '../../../environments/variables';
import {Env} from '../../../environments/env';

@Injectable({
    providedIn: 'root'
})
export class NotificationsRepository {

    constructor(private store: Store<AppState>,
                private notificationsService: NotificationsService,
                private userRepository: UserRepository,
                private translate: TranslateService,
                @Inject(ENV) public environment: Env) {}

    getNotifications(refresh: boolean): Observable<Resource<Notification[]>> {
        return this.userRepository.isLoggedIn().pipe(
            switchMap((isLogged: boolean) => {
                if (isLogged) {
                    return this.setUpWelcomeNotification().pipe(
                        switchMap(() => {
                            const getNotifications = new GetNotifications(this.store, this.notificationsService, refresh);
                            return getNotifications.fetch();
                        })
                    );
                }
                else {
                    return this.setUpWelcomeNotification().pipe(
                        switchMap(() => {
                            return concat(of(Resource.loading<Notification[]>(GetNotifications.name)),
                                this.store.pipe(select(selectAllNotifications)).pipe(
                                map((notifications: Notification[]) => {
                                    return Resource.success(GetNotifications.name, notifications);
                                })
                            ));
                        })
                    );
                }
            })
        );
    }

    getNumberOfUnreadNotification(): Observable<number> {
        return this.store.pipe(select(selectNumberOfUnreadNotifications));
    }

    getNotificationById(id: number, markAsRead: boolean = true): Observable<Resource<Notification>> {
        const getNotificationById = new GetNotificationById(this.store, this.notificationsService, id);
        return getNotificationById.fetch().pipe(
            switchMap((notificationResource: Resource<Notification>) => {
                // mark the notification as read
                const notification: Notification = notificationResource.data;
                if (notification != null && !notification.read) {
                    // we notify the api only if it is not the welcome notification
                    if (notification.id !== -1 && markAsRead) {
                        return this.notificationsService.markAsRead(notification.id).pipe(
                            switchMap( () => {
                                this.markNotificationAsRead(notification);
                                return of(notificationResource);
                            }),
                            catchError(() => {
                                return of(notificationResource);
                            })
                        );
                    }
                    else if (notification.id === -1 && markAsRead) {
                        this.markNotificationAsRead(notification);
                        return of(notificationResource);
                    }
                    else {
                        return of(notificationResource);
                    }
                }
                else {
                    return of(notificationResource);
                }
            })
        );
    }

    clearNotifications(): void {
        this.store.dispatch(deleteNotificationByPredicate({ predicate : notification => notification.id !== -1}));
    }

    private markNotificationAsRead(notification: Notification) {
        const readNotification = Object.assign({}, notification);
        readNotification.read = true;
        // this will cause the re-fire but at least we have the fresh notification as read
        this.store.dispatch(upsertNotification( { notification: readNotification }));
    }

    private setUpWelcomeNotification(): Observable<Notification> {
        return this.store.pipe(
            select(selectWelcomeNotification),
            take(1),
            tap((existingWelcomeNotification: Notification) => {
                if (existingWelcomeNotification == null) {
                    // we insert the welcome notification
                    const welcomeNotification: Notification = {
                        id: -1,
                        object: this.translate.instant('WELCOME_NOTIF_OBJECT'),
                        sent_at: new Date().toUTCString(),
                        event_title: this.translate.instant('WELCOME_NOTIF_TITLE'),
                        event_logo: 'assets/imgs/logo.png',
                        read: false,
                        content: this.translate.instant('WELCOME_NOTIF_TEXT'),
                    };

                    this.store.dispatch(upsertNotification({ notification: welcomeNotification }));
                }
                else {
                    // we update the welcome notification (i.e. for language changes)
                    const updatedWelcomeNotification: Notification = {
                        id: existingWelcomeNotification.id,
                        object: this.translate.instant('WELCOME_NOTIF_OBJECT'),
                        sent_at: existingWelcomeNotification.sent_at,
                        event_title: this.translate.instant('WELCOME_NOTIF_TITLE'),
                        event_logo: existingWelcomeNotification.event_logo,
                        read: existingWelcomeNotification.read,
                        content: this.translate.instant('WELCOME_NOTIF_TEXT'),
                    };

                    this.store.dispatch(upsertNotification({ notification: updatedWelcomeNotification }));
                }
            })
        );
    }
}
