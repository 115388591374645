import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {CicgNews} from '../../../api';
import {StaticPagesRepository} from '../../../repositories/staticPages/staticPages.repository';
import {ResourceStatusService} from '../../../common/ui/state-management/resource.status.service';
import {IonRefresher, Platform} from '@ionic/angular';
import {shareReplay, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {completeIonRefresher, consume, manageLoadingNoData} from '../../../common/rxjs/operators';
import {ENV} from '../../../../environments/variables';
import {Env} from '../../../../environments/env';
import {LoadingNoDataComponent} from '../../../common/ui/state-management/loading-no-data/loading-no-data.component';

@Component({
    selector: 'app-news-api',
    templateUrl: './news-api.page.html',
    styleUrls: ['./news-api.page.scss'],
})
export class NewsApiPage implements AfterViewInit {

    // INPUTS
    private retry: BehaviorSubject<boolean>;

    // OUTPUTS
    public cicgNews$: Observable<CicgNews[]>;
    private triggerRetry$: Observable<boolean>;

    // VARIABLES
    @ViewChild('refresher', {static: false}) ionRefresher: IonRefresher;
    @ViewChild('noNewsDataLoading', {static: false}) noNewsDataLoading: LoadingNoDataComponent;

    public isAndroidOriOS: boolean;

    constructor(private staticPagesRepository: StaticPagesRepository,
                private resourceStatusService: ResourceStatusService,
                private platform: Platform,
                private router: Router,
                @Inject(ENV) public environment: Env) {
        this.retry = new BehaviorSubject<boolean>(false);
        this.triggerRetry$ = this.retry.asObservable();
        this.isAndroidOriOS = this.platform.is('android') || this.platform.is('ios');
    }

    ngAfterViewInit(): void {
        this.setUpCicgNewsObservable();
    }

    public async openNews(cicgNews: CicgNews) {
        await this.router.navigate(['practical-information/news', cicgNews.nid]);
    }

    public forceRefresh() {
        this.retry.next(true);
    }

    private setUpCicgNewsObservable() {
        this.cicgNews$ = merge(this.triggerRetry$, this.resourceStatusService.triggerRetry$)
            .pipe(
                switchMap((refresh: boolean) => {
                    return this.staticPagesRepository.getNewsApiPage(refresh);
                }),
                completeIonRefresher(this.ionRefresher),
                manageLoadingNoData(this.noNewsDataLoading),
                consume(this.resourceStatusService),
                shareReplay(1)
            );
    }
}
