import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserRepository} from '../repositories/user/user.repository';
import {switchMap} from 'rxjs/operators';
import {LoginComponent} from '../components/login/login.component';
import {ModalController} from '@ionic/angular';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private userRepository: UserRepository,
                private router: Router,
                private modalController: ModalController) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.userRepository.isLoggedIn().pipe(
            switchMap((auth: boolean) => {
                if (!auth) {
                    return fromPromise(this.showLogin(state.url).then(() => {
                        return this.router.createUrlTree(['home']);
                    }));
                }
                return of(auth);
            })
        );
    }

    public async showLogin(redirectURL) {
        const loginModal = await this.modalController.create({
            component: LoginComponent,
            componentProps: {redirectURL},
            cssClass: 'mt-event-modal',
            showBackdrop: true,
            backdropDismiss: false
        });
        await loginModal.present();
    }

}
