import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {CffApiPage} from './cff-api.page';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {UiStateManagementModule} from '../../../common/ui/state-management/ui.state.management.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HeaderComponentModule,
        TranslateModule,
        UiStateManagementModule
    ],
  declarations: [CffApiPage]
})
export class CffApiPageModule {}
