import {Component, Input, Output, EventEmitter, OnChanges, SimpleChange} from '@angular/core';
import { Question, Answer } from '../../api';

/**
 * Generated class for the SurveyQuestionComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.html',
  styleUrls: ['./survey-question.scss']
})
export class SurveyQuestionComponent implements OnChanges{

  @Input() public question: Question;
  @Input() public eventLang: string;
  @Output() public answerChange: EventEmitter<Answer> = new EventEmitter();
  public standardPlaceholder = '';

  public ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const changedProp = changes[propName];
        if (propName === 'eventLang') {
          if (changedProp.currentValue === 'en') {
            this.standardPlaceholder = 'Click here to answer';
          } else if (changedProp.currentValue === 'fr') {
            this.standardPlaceholder = 'Cliquez ici pour répondre';
          } else {
            this.standardPlaceholder = 'Click here to answer';
          }
        }
      }
    }
  }

  public onChange($event) {

    const val = $event.target.value;
    const newAnwser: Answer = {
      question_id: this.question.id,
      content: val
    };
    this.answerChange.emit(newAnwser);
  }
}
