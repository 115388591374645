import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Event, EventsService} from '../../api';
import {upsertAnEventInAllEvents} from '../../store/reducers/events/event.actions';
import {selectEventByIdFromAllEvents} from '../../store/reducers/events/event.selectors';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetEventById extends NetworkBoundResource<Event, Event> {
    constructor(private store: Store<AppState>,
                private eventsService: EventsService,
                private id: number) {
        super();
    }

    protected loadFromDatabase(): Observable<Event> {
        return this.store.pipe(select(selectEventByIdFromAllEvents, this.id));
    }

    protected saveNetworkResult(event: Event) {
        this.store.dispatch(upsertAnEventInAllEvents( { event }));
    }

    protected shouldFetch(dataFromDatabase: Event): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.id
            }
        };
    }

    protected startNetworkCall(): Observable<Event> {
        return this.eventsService.getEvent(this.id);
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_FORBIDDEN_ERROR', false, true, error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.id);
    }
}
