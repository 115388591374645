import {Room} from '../../api';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

export async function executeOpenRoom(room: Room, router: Router, translateService: TranslateService) {
    if (room.ext_id) {
        await router.navigate(['practical-information/rooms', room.ext_id]);
    }
    else if ((translateService.currentLang === 'fr' && room.url_fr != null && room.url_fr.startsWith('http')) ||
        (translateService.currentLang === 'en' && room.url_en != null && room.url_en.startsWith('http'))) {
        await router.navigate(['practical-information/rooms/frame', room.id]);
    }
}
