import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponent} from './header.component';
import {UiStateManagementModule} from '../state-management/ui.state.management.module';
import {OfflineButtonComponent} from './buttons/offline-button/offline-button.component';
import {AdminButtonComponent} from './buttons/admin-button/admin-button.component';
import {ProfileButtonComponent} from './buttons/profile-button/profile-button.component';
import {ScanButtonComponent} from './buttons/scan-button/scan-button.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    UiStateManagementModule
  ],
  declarations: [HeaderComponent, OfflineButtonComponent, AdminButtonComponent, ProfileButtonComponent, ScanButtonComponent],
  exports: [HeaderComponent, OfflineButtonComponent, AdminButtonComponent, ProfileButtonComponent, ScanButtonComponent],
})
export class HeaderComponentModule {}
