import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {PasswordRegisterPage} from './password-register.page';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponentModule} from '../../../common/ui/app-header/header.component.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderComponentModule
  ],
  declarations: [PasswordRegisterPage]
})
export class PasswordRegisterPageModule {}
