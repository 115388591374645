import {Participant} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as EventParticipantsActions from './event-participant.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
    adapter as sessionsAdapter,
    EventSession,
    initialState as sessionsInitialState
} from '../eventSessions/event-session.reducer';

export interface EventParticipant extends Participant {
    eventId: number;
    talks?: EntityState<EventSession>;
    agenda?: EntityState<EventSession>;
}

export function selectByEventParticipantId(eventParticipant: EventParticipant): number {
    return eventParticipant.id;
}

export function selectByEventId(eventParticipant: EventParticipant): number {
    return eventParticipant.eventId;
}

export const participantsAdapterForAll: EntityAdapter<EventParticipant> = createEntityAdapter<EventParticipant>({
    selectId: selectByEventParticipantId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const participantsAdapterForCurrentUser: EntityAdapter<EventParticipant> = createEntityAdapter<EventParticipant>({
    selectId: selectByEventId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const participantsInitialState: EntityState<EventParticipant> = participantsAdapterForAll.getInitialState();

export function getEventParticipantById(state: EntityState<EventParticipant>, id: number) {
    return participantsAdapterForAll.getSelectors().selectAll(state).find(
        (eventParticipant: EventParticipant) => eventParticipant.id === id
    );
}

export function getCurrentEventParticipantByEventId(state: EntityState<EventParticipant>, id: number) {
    return participantsAdapterForCurrentUser.getSelectors().selectAll(state).find(
        (eventParticipant: EventParticipant) => eventParticipant.eventId === id
    );
}

export function getEventParticipantAgendaState(eventParticipant: EventParticipant): EntityState<EventSession> {
    if (!eventParticipant || !eventParticipant.agenda) {
        return sessionsInitialState;
    }
    return eventParticipant.agenda;
}

export function reducerForAll(state: EntityState<EventParticipant> | undefined, action: Action) {
    return eventParticipantsReducer(state, action);
}

export function reducerForCurrentUser(state: EntityState<EventParticipant> | undefined, action: Action) {
    return currentUserEventParticipantReducer(state, action);
}

const eventParticipantsReducer = createReducer(
    participantsInitialState,
    on(EventParticipantsActions.upsertEventParticipants, (state, { eventParticipants }) =>
        upsertEventParticipants(eventParticipants, state)),
    on(EventParticipantsActions.upsertEventParticipant, (state, { eventParticipant }) =>
        participantsAdapterForAll.upsertOne(eventParticipant, state)),
    on(EventParticipantsActions.clearEventParticipantsForAnEvent, (state, { eventId }) =>
        participantsAdapterForAll.removeMany((eventParticipant: EventParticipant) => {
            return eventParticipant.eventId === eventId;
        }, state)),
    on(EventParticipantsActions.clearEventParticipantsInAllEvents, (state) =>
        participantsAdapterForAll.removeAll(state)),
    on(EventParticipantsActions.updateEventParticipantTalks, (state, { participantId, eventSessions }) => {
        return updateEventParticipantTalks(participantId, eventSessions, state);
    }),
    on(EventParticipantsActions.updateEventParticipantAgenda, (state, { participantId, eventSessions }) => {
        return updateEventParticipantAgenda(participantsAdapterForAll, participantId, eventSessions, state);
    }),
    on(EventParticipantsActions.clearEventParticipantAgenda, (state, { participantId }) => {
        return clearAgendaInParticipant(participantsAdapterForAll, participantId, state);
    })
);

function upsertEventParticipants(eventParticipants: EventParticipant[], state: EntityState<EventParticipant>) {
    const updatedInsertedState = participantsAdapterForAll.upsertMany(eventParticipants, state);
    const currentEventParticipants: EventParticipant[] = participantsAdapterForAll.getSelectors().selectAll(updatedInsertedState);
    const eventParticipantsToDelete: EventParticipant[] = currentEventParticipants.filter((eventParticipant: EventParticipant) => {
        return !eventParticipants.some((eventParticipant2: EventParticipant) => {
            return eventParticipant.id === eventParticipant2.id;
        });
    });

    return participantsAdapterForAll.removeMany(eventParticipantsToDelete.map((eventParticipant: EventParticipant) => {
        return eventParticipant.id;
    }), updatedInsertedState);
}

function updateEventParticipantTalks(participantId: number,
                                     eventSessions: EventSession[],
                                     state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    // we always rewrite the full talks of the user when we receive one new
    const newParticipantTalkState = sessionsAdapter.addMany(eventSessions, sessionsInitialState);
    return participantsAdapterForAll.updateOne({ id: participantId, changes: { talks: newParticipantTalkState }}, state);
}

const currentUserEventParticipantReducer = createReducer(
    participantsInitialState,
    on(EventParticipantsActions.upsertCurrentUserEventParticipant, (state, { eventParticipant }) =>
        participantsAdapterForCurrentUser.upsertOne(eventParticipant, state)),
    on(EventParticipantsActions.clearCurrentUserEventParticipants, (state) =>
        participantsAdapterForCurrentUser.removeAll(state)),
    on(EventParticipantsActions.upsertCurrentUserEventParticipantAgendaSession, (state, { eventId, eventSession }) => {
        return upsertEventParticipantAgendaSession(eventId, eventSession, state);
    }),
    on(EventParticipantsActions.removeCurrentUserEventParticipantAgendaSession, (state, { eventId, eventSessionId }) => {
        return removeEventParticipantAgendaSession(eventId, eventSessionId, state);
    }),
    on(EventParticipantsActions.updateCurrentUserEventParticipantAgenda, (state, { eventId, eventSessions }) => {
        return updateEventParticipantAgenda(participantsAdapterForCurrentUser, eventId, eventSessions, state);
    }),
    on(EventParticipantsActions.clearCurrentUserEventParticipantAgenda, (state, { eventId }) => {
        return clearAgendaInParticipant(participantsAdapterForCurrentUser, eventId, state);
    })
);

function updateEventParticipantAgenda(adapter: EntityAdapter<EventParticipant>,
                                      id: number,
                                      eventSessions: EventSession[],
                                      state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    // we always rewrite the full agenda of the user when we receive one new
    const newParticipantAgendaState = sessionsAdapter.addMany(eventSessions, sessionsInitialState);
    return updateAgendaInParticipant(adapter, id, newParticipantAgendaState, state);
}

function upsertEventParticipantAgendaSession(eventId: number,
                                             eventSession: EventSession,
                                             state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    const participant: EventParticipant = getCurrentEventParticipantByEventId(state, eventId);
    const participantAgendaState: EntityState<EventSession> = getEventParticipantAgendaState(participant);
    const newParticipantAgendaState = sessionsAdapter.upsertOne(eventSession, participantAgendaState);
    return updateAgendaInParticipant(participantsAdapterForCurrentUser, eventId, newParticipantAgendaState, state);
}

function removeEventParticipantAgendaSession(eventId: number,
                                             eventSessionId: number,
                                             state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    const participant: EventParticipant = getCurrentEventParticipantByEventId(state, eventId);
    const participantAgendaState: EntityState<EventSession> = getEventParticipantAgendaState(participant);
    const newParticipantAgendaState = sessionsAdapter.removeOne(eventSessionId, participantAgendaState);
    return updateAgendaInParticipant(participantsAdapterForCurrentUser, eventId, newParticipantAgendaState, state);
}

function updateAgendaInParticipant(adapter: EntityAdapter<EventParticipant>,
                                   id: number,
                                   newParticipantAgendaState: EntityState<EventSession>,
                                   state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    return adapter.updateOne({ id, changes: { agenda: newParticipantAgendaState } }, state);
}

function clearAgendaInParticipant(adapter: EntityAdapter<EventParticipant>,
                                  id: number,
                                  state: EntityState<EventParticipant>): EntityState<EventParticipant> {
    return adapter.updateOne({ id, changes: { agenda: sessionsInitialState } }, state);
}
