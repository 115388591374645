import {Component, OnInit} from '@angular/core';
import {ResourceStatusService} from '../resource.status.service';
import {concat, Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {Status} from '../../../repository/status';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
    public showLoading$: Observable<boolean>;

    constructor(private resourceStatusService: ResourceStatusService) {}

    ngOnInit() {
        this.showLoading$ = concat(of(false), this.resourceStatusService.loadingSuccessStatus$.pipe(
            delay(0),
            switchMap((status: Status): Observable<boolean> => {
                return of(status === Status.LOADING);
            })
        ));
    }
}
