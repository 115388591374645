import {Component, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonRouterOutlet, ModalController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Event, UserPushToken} from './api';
import {EventsRepository} from './repositories/events/events.repository';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {delay, map, shareReplay, switchMap} from 'rxjs/operators';
import {UserRepository} from './repositories/user/user.repository';
import {ResourceStatusService} from './common/ui/state-management/resource.status.service';
import {NotificationsRepository} from './repositories/notifications/notifications.repository';
import {ENV} from '../environments/variables';
import {Env} from '../environments/env';
import {consume, consumeOnlySuccess} from './common/rxjs/operators';
import {App, AppState} from '@capacitor/app';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';
import {
    ActionPerformed,
    PermissionStatus,
    PushNotifications,
    PushNotificationSchema,
    Token
} from '@capacitor/push-notifications';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import {Router} from '@angular/router';
import {AuthSessionRepository} from './repositories/auth/authSession.repository';
import {CguPcaComponent} from './components/cgu-pca/cgu-pca.component';
import {IS_WEB_APP} from './common/ui/directives/show.when';
import {RateLimiter} from './common/repository/rateLimiter';
import {HydrateRepository} from './repositories/hydrate/hydrate.repository';
import {Resource} from './common/repository/resource';
import {Status} from './common/repository/status';
import {getToastOptionError, getToastOptionSuccess} from './pages/common/constants';
import {SplashPage} from './pages/splash/splash.page';
import {LoggedOutEvent} from './store/reducers/user/user.selectors';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    public menuPages = [
        {
            title: 'DASHBOARD_MENU',
            url: '/home',
            icon: './assets/imgs/menu_dashboard.svg',
            icon_active: './assets/imgs/menu_dashboard_active.svg',
            needs_auth: false
        },
        {
            title: 'EVENTS_MENU',
            url: '/events',
            icon: './assets/imgs/menu_calendar.svg',
            icon_active: './assets/imgs/menu_calendar_active.svg',
            needs_auth: true
        },
        {
            title: 'PRACTICAL_INFORMATION_MENU',
            url: '/practical-information/list',
            icon: './assets/imgs/menu_informations.svg',
            icon_active: './assets/imgs/menu_informations_active.svg',
            needs_auth: false
        },
        {
            title: 'NOTIFICATION_MENU',
            url: '/notifications',
            icon: './assets/imgs/menu_notifications.svg',
            icon_active: './assets/imgs/menu_notifications_active.svg',
            needs_auth: false
        },
        {
            title: 'SETTINGS_MENU',
            url: '/settings',
            icon: './assets/imgs/menu_settings.svg',
            icon_active: './assets/imgs/menu_settings_active.svg',
            needs_auth: false
        },
        {
            title: 'ABOUT_MENU',
            url: '/about',
            icon: './assets/imgs/menu_informations.svg',
            icon_active: './assets/imgs/menu_informations_active.svg',
            needs_auth: false
        }
    ];

    // INPUTS

    // OUTPUTS
    private langSubscription: Subscription;
    private logoutSubscription: Subscription;
    private authSessionSubscription: Subscription;
    private loginSubscription: Subscription;
    private getEventByCodeSubscription: Subscription;
    private initStoreSubscription: Subscription;
    private termsAndConditionsSubscription: Subscription;
    public events$: Observable<Event[]>;
    public showEvents$: Observable<boolean>;
    public unreadNotifications$: Observable<number>;
    public readonly isWebApp = IS_WEB_APP;
    public showMenu = true;

    // variables
    @ViewChild(IonRouterOutlet, {static: false}) routerOutlet: IonRouterOutlet;

    constructor(
        private platform: Platform,
        private translate: TranslateService,
        private eventsRepository: EventsRepository,
        public userRepository: UserRepository,
        private modalController: ModalController,
        private authSessionRepository: AuthSessionRepository,
        private notificationsRepository: NotificationsRepository,
        private hydrateRepository: HydrateRepository,
        private resourceStatusService: ResourceStatusService,
        private translateService: TranslateService,
        private toastController: ToastController,
        private router: Router,
        private alertController: AlertController,
        private zone: NgZone,
        @Inject(ENV) public environment: Env
    ) {
        this.langSubscription = Subscription.EMPTY;
        this.logoutSubscription = Subscription.EMPTY;
        this.authSessionSubscription = Subscription.EMPTY;
        this.loginSubscription = Subscription.EMPTY;
        this.getEventByCodeSubscription = Subscription.EMPTY;
        this.initStoreSubscription = Subscription.EMPTY;
        this.termsAndConditionsSubscription = Subscription.EMPTY;
    }

    ngOnInit(): void {
        this.initializeApp();
    }

    ngOnDestroy(): void {
        this.langSubscription.unsubscribe();
        this.logoutSubscription.unsubscribe();
        this.authSessionSubscription.unsubscribe();
        this.loginSubscription.unsubscribe();
        this.getEventByCodeSubscription.unsubscribe();
        this.initStoreSubscription.unsubscribe();
        this.termsAndConditionsSubscription.unsubscribe();
    }

    private initializeApp() {
        this.setUpLangSubscription();
        this.setUpEventsObserver();
        this.setUpNotificationsObserver();
        this.setUpLoginSubscription();
        this.setUpLogoutSubscription();
        this.setUpAuthSessionSubscription();

        this.platform.ready().then(async (platform: string) => {
            this.showMenu = !document.URL.includes('showNoMenu');
            // platform can be cordova if in native or dom if in web
            if (platform === 'cordova') {
                // we execute native work
                await this.setUpStatusBar();
                this.setUpAppListeners();
                this.setUpBranchDeepLinks();
                this.setUpFirebaseToken();
                await this.setUpSplashScreen();
            }
            else {
                // we are in web
                // we do not have the splash screen so we check the T&C now
                // for the native case we check them when the splash screen is gone
                this.checkTermsAndConditions();
            }
        });
    }

    public async logout() {
        const alert = await this.alertController.create({
            header: this.translate.instant('LOGOUT_WARNING_TITLE_DIALOG'),
            message: this.translate.instant('LOGOUT_WARNING_DIALOG'),
            buttons: [
                {
                    text: this.translate.instant('CANCEL'),
                    role: 'cancel',
                    handler: null
                },
                {
                    text: this.translate.instant('LOGOUT_MENU'),
                    handler: () => {
                        if (this.platform.is('cordova')) {
                            this.userRepository.sendFirebaseTokenToServer({push_token: '-1'}).subscribe(() => {
                                this.userRepository.clearUser();
                            });
                        } else {
                            this.userRepository.clearUser();
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await alert.present();
    }

    private async setUpStatusBar() {
        if (this.platform.is('android')) {
            await StatusBar.setStyle({style: Style.Dark});
            await StatusBar.setBackgroundColor({color : this.environment.primary_color});
        }
    }

    private async setUpSplashScreen() {
        if (this.environment.showAnimatedSplash) {
            await this.showAnimatedSplashScreen();
        }
        else {
            await SplashScreen.hide();
            // we are in mobile but without animated splash screen so we check the T&C here
            // as soon as the splash screen is gone
            this.checkTermsAndConditions();
        }
    }

    private async showAnimatedSplashScreen() {
        setTimeout(async () => {
            const splashModal = await this.modalController.create({
                component: SplashPage,
                animated: false,
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: 'splash-modal',
            });
            await splashModal.present();
            await splashModal.onDidDismiss();
            // we are in mobile and the splash screen has an animated extra screen
            // so we have to check the T&C here after the animated splash screen is gone
            this.checkTermsAndConditions();
        }, 500);
    }

    private setUpAppListeners() {
        // to know when the ap is active
        App.addListener('appStateChange', async (state: AppState) => {
            if (state.isActive) {
                // always remove all the delivered notifications otherwise when notifications
                // are dismissed the counter remains there
                await PushNotifications.removeAllDeliveredNotifications();
            }
        });
        // to know when the back button is clicked (Android only)
        App.addListener('backButton', async () => {
            if (this.routerOutlet && this.routerOutlet.canGoBack()) {
                await this.routerOutlet.pop();
            }
            else {
                const alert = await this.alertController.create({
                    header: this.translate.instant('EXIT_WARNING_DIALOG_TITLE'),
                    message: this.translate.instant('EXIT_WARNING_DIALOG_TEXT'),
                    buttons: [
                        {
                            text: this.translate.instant('CANCEL'),
                            role: 'cancel',
                            handler: null
                        },
                        {
                            text: this.translate.instant('EXIT_WARNING_BUTTON'),
                            handler: () => {
                                App.exitApp();
                            }
                        }
                    ]
                });
                await alert.present();
            }
        });
    }

    private setUpBranchDeepLinks() {

        // disable the user tracking to allow to publish the ios app
        // @ts-ignore
        // BranchDeepLinks.disableTracking({ isEnabled: true }).then(res => {
        //     console.log('BranchDeepLinks[disableTracking] response: ' + JSON.stringify(res.is_enabled));
        // }).catch(err => {
        //     console.error('BranchDeepLinks[disableTracking] response: ' + JSON.stringify(err));
        // });

        BranchDeepLinks.addListener('init', async (event: BranchInitEvent) => {
            // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
            // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
            if (event.referringParams['+clicked_branch_link']) {
                await this.zone.run(async () => {
                    const data = event.referringParams;
                    // read deep link data on click
                    if (data.controller === 'event_details') {
                        await this.router.navigate(['event', data.event_id]);
                    }
                    else if (data.controller === 'NewsDetailPage') {
                        await this.router.navigate(['event', data.event_id, 'news', data.news_id]);
                    }
                    else if (data.controller === 'FeedbackPage') {
                        await this.router.navigate(['event', data.event_id, 'survey']);
                    }
                    else if (data.controller === 'registration_page') {
                        await this.router.navigate(['password', 'password-register', data.invite_id]);
                    }
                    else if (data.controller === 'reset_password_page') {
                        await this.router.navigate(['password', 'password-reset', data.token]);
                    }
                    else if (data.controller === 'NotificationPageDetail') {
                        await this.router.navigate(['notifications', data.notif_id]);
                    }
                    else if (data.controller === 'MyProfilePage') {
                        await this.router.navigate(['event', data.event_id, 'profile', 'me']);
                    }
                    else if (data.controller === 'event_code_invite') {
                        this.authenticateForEvent(data.event_code);
                    }
                });
            }

        });

        BranchDeepLinks.addListener('initError', (error: any) => {
            console.error(error);
        });
    }

    private setUpFirebaseToken() {
        this.userRepository.isLoggedIn().subscribe(async (logged: boolean) => {
            if (logged) {
                PushNotifications.addListener('registration',
                    (token: Token) => {
                        const pushToken: UserPushToken = {
                            push_token: token.value
                        };
                        this.userRepository.sendFirebaseTokenToServer(pushToken).subscribe();
                    });

                PushNotifications.addListener('registrationError',
                    (error: any) => {
                        console.log('PUSH Error on registration: ' + JSON.stringify(error));
                    }
                );

                PushNotifications.addListener('pushNotificationReceived',
                    async (notification: PushNotificationSchema) => {
                        const notificationDialog = await this.alertController.create({
                            header: notification.title,
                            message: notification.body,
                            buttons : [
                                {text: this.translate.instant('CANCEL'), role : 'cancel'},
                                {text: this.translate.instant('SHOW'), handler: () => { this.notifAction(notification); }}
                            ],
                            backdropDismiss: false
                        });
                        await notificationDialog.present();
                        // remove all the delivered notifications
                        await PushNotifications.removeAllDeliveredNotifications();
                    }
                );

                PushNotifications.addListener('pushNotificationActionPerformed',
                    async (notificationAction: ActionPerformed) => {
                        if (notificationAction.actionId === 'tap') {
                            await this.notifAction(notificationAction.notification);
                        }
                        // remove all the delivered notifications
                        await PushNotifications.removeAllDeliveredNotifications();
                    }
                );

                // Request permission to use push notifications
                // iOS will prompt user and return if they granted permission or not
                // Android will just grant without prompting
                let permStatus: PermissionStatus = await PushNotifications.checkPermissions();
                if (permStatus.receive === 'prompt') {
                    permStatus = await PushNotifications.requestPermissions();
                }

                if (permStatus.receive !== 'granted') {
                    const message = this.translateService.instant('NOTIFICATIONS_PERMISSION_NOT_GRANTED');
                    const toast = await this.toastController.create(getToastOptionError(message));
                    await toast.present();
                }
                await PushNotifications.register();
            }
        });
    }

    private async notifAction(notif: any) {
        if (notif.data.type) {
            await this.zone.run(async () => {
                switch (notif.data.type) {
                    case 'news':
                        await this.router.navigate(
                            ['event', notif.data.event_id, 'news', notif.data.object_id]);
                        break;
                    case 'survey':
                        await this.router.navigate(
                            ['event', notif.data.event_id, 'survey']);
                        break;
                    case 'notification':
                        await this.router.navigate(
                            ['notifications', notif.data.object_id]);
                        break;
                    case 'event_invite':
                        await this.router.navigate(
                            ['event', notif.data.event_id]);
                        break;
                    default:
                        break;
                }
            });
        }
    }

    private setUpLangSubscription() {
        this.langSubscription = this.userRepository.getCurrentUserLang().pipe(
            switchMap((currentLang: string) => {
                if (currentLang == null) {
                    // we get the browser lang to set it as initial user lang
                    let lang = this.translate.getBrowserLang();
                    if (lang === undefined) {
                        lang = 'en'; // if we do not even have the browser lang we force english
                    }
                    return this.translate.use(lang);
                }
                else {
                    if (this.translate.currentLang !== currentLang) {
                        // if the language is different we change it
                        return this.translate.use(currentLang);
                    }
                    else {
                        return EMPTY;
                    }
                }
            })
        ).subscribe(async () => {
            // console.info(`Successfully initialized '${lang}' language.'`);
        }, err => {
            console.error(`Problem with language initialization.'`);
            console.error(JSON.stringify(err));
        });
    }

    private setUpEventsObserver() {
        this.events$ = this.eventsRepository.getPublishedEvents()
            .pipe(
                consume(this.resourceStatusService),
                shareReplay(1)
            );

        this.showEvents$ = this.events$.pipe(
            map((events: Event[]) => {
                return events.length > 0;
            })
        );
    }

    private setUpNotificationsObserver() {
        this.unreadNotifications$ = this.notificationsRepository.getNumberOfUnreadNotification()
            .pipe(delay(0));
        // we do this to prevent an error with the change of the ngif of the counter
        // see https://blog.angular-university.io/angular-debugging/
    }

    private setUpLoginSubscription() {
        this.loginSubscription = this.userRepository.observeLogin().subscribe(
            async () => {
                const syncDialog = await this.alertController.create({
                    header: this.translate.instant('MEGA_SYNC_DIALOG_HEADER'),
                    message: this.translate.instant('MEGA_SYNC_DIALOG_MESSAGE'),
                    buttons : [
                        {
                            text: this.translate.instant('OK'),
                            role : 'cancel',
                            handler: () => {
                                this.initOfflineStore();
                            }
                        },
                    ],
                    backdropDismiss: false
                });
                await syncDialog.present();
            }
        );
    }

    private initOfflineStore() {
        this.initStoreSubscription = this.hydrateRepository.initializeStore().pipe(
            consume(this.resourceStatusService)
        ).subscribe();
    }

    private setUpLogoutSubscription() {
        this.logoutSubscription = this.userRepository.observeLogout().subscribe(async (loggedOutEvent: LoggedOutEvent) => {
            // partial logout it you are guest user (when there is a valid login after being an anonymous user)
            // valid also for normal user
            this.eventsRepository.clearEvents();
            this.eventsRepository.clearNewsForAllEvents();
            this.eventsRepository.clearSessionsForAllEvents();
            await RateLimiter.getInstance().removeAllLimits();

            // full logout if you were a full user
            if (!loggedOutEvent.wasGuest) {
                this.authSessionRepository.clearAuthSession();
                this.eventsRepository.clearParticipantsForAllEvents(); // includes their agendas and talks
                this.eventsRepository.clearParticipantsForCurrentUser(); // includes their agendas
                this.eventsRepository.clearAllEventSettingsForCurrentUser();
                this.notificationsRepository.clearNotifications();
                await this.router.navigate(['home']);
            }
        });
    }

    private setUpAuthSessionSubscription() {
        this.authSessionSubscription = this.authSessionRepository.observeInvalidateAuthSession().subscribe(() => {
            this.userRepository.clearUser();
        });
    }

    private checkTermsAndConditions() {
        this.termsAndConditionsSubscription = this.userRepository.checkTermsAndConditions().pipe(
            consumeOnlySuccess(this.resourceStatusService)
        ).subscribe(async (checked: boolean) => {
            if (!checked) {
                const tandcModal = await this.modalController.create({
                    component: CguPcaComponent,
                    cssClass: 'mt-event-modal',
                    showBackdrop: true,
                    backdropDismiss: false
                });
                await tandcModal.present();
            }
        });
    }

    private authenticateForEvent(code: string) {
        this.getEventByCodeSubscription = this.eventsRepository.getEventByCode(code)
            .subscribe(async (resourceEventId: Resource<number>) => {
                this.resourceStatusService.consumeResource(resourceEventId);
                if (resourceEventId.status === Status.SUCCESS) {
                    await this.showEventAddedSuccessMessage();
                    await this.router.navigate(['event', resourceEventId.data]);
                } else if (resourceEventId.status === Status.ERROR) {
                    await this.router.navigate(['home']);
                }
            });
    }

    private async showEventAddedSuccessMessage() {
        const message = this.translateService.instant('EVENT_ADDED_MESSAGE_SUCCESS');
        const toast = await this.toastController.create(getToastOptionSuccess(message));
        await toast.present();
    }
}
