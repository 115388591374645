import {Observable} from 'rxjs';
import {GdprService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class CheckTermsAndConditions extends JustNetworkResource<boolean, any> {
    constructor(private gdprService: GdprService) {
        super(true);
    }

    startNetworkCall(): Observable<any> {
        return this.gdprService.checkCguPca();
    }

    onNetworkSuccess(dataFromNetwork: any): boolean {
        return dataFromNetwork.uptodate;
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('CGU_ERROR_TOAST', false);
    }
}
