import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PublicSurveyWelcomePage} from './public-survey-welcome/public-survey-welcome.page';
import {PublicSurveyQuestionsPage} from './public-survey-questions/public-survey-questions.page';

const routes: Routes = [
  {
    path: 'public-survey/welcome',
    component: PublicSurveyWelcomePage
  },
  {
    path: 'public-survey/questions',
    component: PublicSurveyQuestionsPage
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PublicSurveyRoutingModule { }
