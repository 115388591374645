import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Participant, ParticipantsService} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {selectEventParticipantById} from '../../../store/reducers/eventParticipants/event-participant.selectors';
import {EventParticipant} from '../../../store/reducers/eventParticipants/event-participant.reducer';
import {upsertEventParticipant} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';
import {GetEventParticipantAgendaById} from '../agenda/getEventParticipantAgendaById';

export class GetEventParticipantById extends NetworkBoundResource<Participant, Participant> {

    constructor(private store: Store<AppState>,
                private participantService: ParticipantsService,
                private eventId: number,
                private participantId: number,
                private forceRefresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Participant> {
        return this.store.pipe(select(selectEventParticipantById, { id: this.participantId }));
    }

    protected saveNetworkResult(participant: Participant) {
        const eventParticipant: EventParticipant = {...participant, eventId: this.eventId};
        this.store.dispatch(upsertEventParticipant({ eventParticipant }));
    }

    protected shouldFetch(dataFromDatabase: Participant): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || this.forceRefresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.eventId + '-' + this.participantId,
                rateLimitDependantResourceIds: [GetEventParticipantAgendaById.name + '-' + this.eventId + '-' + this.participantId]
            }
        };
    }

    protected startNetworkCall(): Observable<Participant> {
        return this.participantService.getParticipant(this.eventId, this.participantId);
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('PROFILE_NO_SHARE_LABEL', false, true, error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_PARTICIPANT_LOAD_ERROR', true, false, error);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId + '-' + this.participantId);
    }
}
