<app-header [titleText]="(roomDetail$ | async)?.name" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/rooms"></app-header>

<ion-content class="practical_info_content_iframe practical_info_page"
             scroll-events>
    <div class="room-detail-page page-header"
         *ngIf="(roomDetail$ | async) != null">
        <div *appShowWhen="[isDesktop]" [ngClass]="(roomDetail$ | async)?.banner && (roomDetail$ | async)?.banner.length > 0 ?
            'header-image header-image--desktop' : 'header-image default_bg header-image--desktop'"
             [ngStyle]="{'background-image': ((roomDetail$ | async)?.banner && (roomDetail$ | async)?.banner.length > 0 ? 'url(' + (roomDetail$ | async)?.banner + ')' : '' )}">
        </div>
        <div *appShowWhen="[isMobile]" [ngClass]="(roomDetail$ | async)?.banner && (roomDetail$ | async)?.banner.length > 0 ?
            'header-image header-image--mobile' : 'header-image no-header'"
             [ngStyle]="{'background-image': ((roomDetail$ | async)?.banner && (roomDetail$ | async)?.banner.length > 0 ? 'url(' + (roomDetail$ | async)?.banner + ')' : '' )}">
        </div>
        <div class="main-content">
            <div class="ion-padding">
                <ion-item lines="full" class="ion-margin-bottom">
                    <ion-label class="ion-text-wrap">
                        <h1 class="ion-text-capitalize">
                            {{(roomDetail$ | async)?.name}}
                        </h1>
                        <p>
                            {{(roomDetail$ | async)?.type_room}}
                            {{(roomDetail$ | async)?.type_room ? '|' : ''}}
                            {{(roomDetail$ | async)?.floor}}
                            {{(roomDetail$ | async)?.floor ? '|' : ''}}
                            <span *ngFor="let room_type of (roomDetail$ | async)?.type_espace; index as i;">
                        {{room_type + (i + 1 == (roomDetail$ | async)?.type_espace.length ? '' : ', ')}}
                    </span>
                        </p>
                    </ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">
                        <ion-img *ngIf="(roomDetail$ | async)?.situation"
                                 [src]="(roomDetail$ | async)?.situation"
                                 ionImgViewer
                                 [alt]="(roomDetail$ | async)?.name + ' image'"
                                 [title]="(roomDetail$ | async)?.name"
                                 [text]="(roomDetail$ | async)?.name"
                                 [slideOptions]="{zoom: { enabled: true }}"
                                 scheme="light">
                        </ion-img>
                        <ion-text color="primary" class="ion-text-center">
                            <h5 class="accent">
                                {{'CLICK_TO_ZOOM' | translate}}
                            </h5>
                        </ion-text>
                    </ion-label>
                </ion-item>
                <ion-item *ngIf="(roomDetail$ | async)?.video">
                    <div *appShowWhen="[isMobile]" class="margin-left-right-auto" id="video-container-mobile"
                         [innerHTML]="sanitizer.bypassSecurityTrustHtml((roomDetail$ | async)?.video)">
                    </div>
                    <div *appShowWhen="[isDesktop]" class="margin-left-right-auto" id="video-container-desktop"
                         [innerHTML]="sanitizer.bypassSecurityTrustHtml((roomDetail$ | async)?.video)">
                    </div>
                </ion-item>
            </div>
        </div>
    </div>
    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noRoomDataLoading>
        <div class="room-detail-page page-header">
            <div *appShowWhen="[isDesktop]"
                 class="header-image default_bg header-image--desktop">
            </div>
            <div *appShowWhen="[isMobile]"
                 class="header-image no-header">
            </div>
            <div class="main-content">
                <div class="ion-padding">
                    <ion-item lines="full" class="ion-margin-bottom">
                        <ion-label class="ion-text-wrap">
                            <ion-skeleton-text animated style="width: 80%; line-height: 18px;"></ion-skeleton-text>
                            <p>
                                <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
                            </p>
                        </ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap">
                            <ion-skeleton-text animated style="width: 100%; line-height: 380px;"></ion-skeleton-text>
                            <ion-text color="primary" class="ion-text-center">
                                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                            </ion-text>
                        </ion-label>
                    </ion-item>
                </div>
            </div>
        </div>
    </app-loading-no-data>
</ion-content>
