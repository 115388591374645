import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {User, UsersService} from '../../api';
import {AppState} from '../../store';
import {initUser} from '../../store/reducers/user/user.actions';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class LoadUser extends JustNetworkResource<User, User> {
    constructor(private store: Store<AppState>,
                private userService: UsersService) {
        super(true);
    }

    protected startNetworkCall(): Observable<User> {
        return this.userService.user();
    }

    protected onNetworkSuccess(user: User): User {
        this.store.dispatch(initUser( { user }));
        return user;
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('USER_NOT_FOUND', false);
    }
}
