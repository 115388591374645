import {Observable} from 'rxjs';
import {ParticipantsService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class ParticipateWithEventCodeAuth extends JustNetworkResource<number, any> {
    constructor(private participantsService: ParticipantsService,
                private eventCode: string) {
        super(true);
    }

    startNetworkCall(): Observable<any> {
        return this.participantsService.participateWithEventCodeAuth(this.eventCode);
    }

    onNetworkSuccess(result: any): number {
        return result.event_id;
    }

    onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_ADDED_MESSAGE_ERROR', false);
    }

    protected on404NetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('INVALID_CODE_ERROR', false, true);
    }
}
