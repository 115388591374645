import {Action, createReducer, on} from '@ngrx/store';
import * as UserActions from '../user/user.actions';
import {User} from '../../../api';

export const initialState: User = {
    account_status: 0,
    email: null,
    firstname: null,
    id: 0,
    lang_code: null, // we put as default language null, if this is null when we start the app we overwrite it with the browser lang
    lastname: null
};

const userReducer = createReducer(
    initialState,
    on(UserActions.initUser, (state, { user }) => saveUser(state, user)),
    on(UserActions.setUserLang, (state, { languageCode }) => ({ ...state, lang_code: languageCode.lang_code })),
    on(UserActions.clearUser, (state) => deleteUser(state))
);

function saveUser(state: User, user: User): User {
    return user;
}

function deleteUser(state: User): User {
    // we have to keep the language in case of a logout
    return {...initialState, lang_code: state.lang_code};
}

export function reducer(state: User | undefined, action: Action) {
    return userReducer(state, action);
}
