import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResourceStatusService} from '../resource.status.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss'],
})
export class ErrorPage implements OnInit {

  @Input() errorMessage: string;
  @Input() showClose: boolean;
  @Input() showRetry: boolean;

  constructor(private modalController: ModalController,
              private resourceStatusService: ResourceStatusService) { }

  ngOnInit() {
    // ensure there is at least close
    if (!this.showClose && !this.showRetry) {
      this.showClose = true;
    }
  }

  public async retry() {
    this.resourceStatusService.retry.next(true);
    this.dismissError();
  }

  public async dismissError() {
    await this.modalController.dismiss();
  }
}
