import {Injectable} from '@angular/core';
import {StaticPage} from '../../api';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastController} from '@ionic/angular';
import {getToastOptionError} from '../common/constants';
import {Browser} from '@capacitor/browser';
import {Network} from '@capacitor/network';


@Injectable({
    providedIn: 'root'
})
export class PracticalInformationClickService {

    constructor(private router: Router,
                private translate: TranslateService,
                private toastController: ToastController) {
    }

    public async clickOnStaticPage(staticPage: StaticPage) {
        // API case
        if (staticPage.is_api) {
            const offline = await this.checkIfOffline();
            if (!offline) {
                switch (staticPage.api_type_id) {
                    case 1:
                        await this.router.navigate(['practical-information/tpg']);
                        break;
                    case 2:
                        await this.router.navigate(['practical-information/cff']);
                        break;
                    case 3:
                        await this.router.navigate(['practical-information/restaurant']);
                        break;
                    case 4:
                        await this.router.navigate(['practical-information/news']);
                        break;
                    case 5:
                        await this.router.navigate(['practical-information/rooms']);
                        break;
                    case 6:
                        await this.router.navigate(['practical-information/tourism']);
                        break;
                }
            }
        }
        // Children
        else if (staticPage.children && staticPage.children.length > 0) {
            // children can be navigated offline
            const slug = staticPage.slug;
            await this.router.navigate(['/practical-information/list', slug]);
        }
        // External web
        else if (staticPage.url && !staticPage.open_in_app) {
            const offline = await this.checkIfOffline();
            if (!offline) {
                const url = this.translate.currentLang === 'fr' ? staticPage.url_fr : staticPage.url;
                await Browser.open(
                    {
                        url,
                        windowName: '_system'
                    }
                );
            }
        }
        // Normal case with iframe
        else {
            const offline = await this.checkIfOffline();
            if (!offline) {
                const slug = staticPage.slug;
                await this.router.navigate(['/practical-information', slug]);
            }
        }
    }

    private async checkIfOffline(): Promise<boolean> {
        const networkStatus = await Network.getStatus();
        if (!networkStatus.connected) {
            const message = this.translate.instant('NO_INTERNET_CONNECTION');
            const toastOptions = getToastOptionError(message);
            const toast = await this.toastController.create(toastOptions);
            await toast.present();
            return true;
        }
        return false;
    }
}
