import {ResourceFetchOptions, NetworkBoundResource} from '../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ApiPagesService, CicgNews} from '../../api';
import {AppState} from '../../store';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {selectAllStaticPageNews} from '../../store/reducers/staticPageNews/staticPageNews.selectors';
import {upsertStaticPageNews} from '../../store/reducers/staticPageNews/staticPageNews.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../common/repository/rateLimiter';

export class GetNewsApiPage extends NetworkBoundResource<CicgNews[], CicgNews[]> {
    constructor(private store: Store<AppState>,
                private apiPagesService: ApiPagesService,
                private lang: string,
                private refresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<CicgNews[]> {
        return this.store.pipe(select(selectAllStaticPageNews));
    }

    protected saveNetworkResult(news: CicgNews[]) {
        this.store.dispatch(upsertStaticPageNews({ staticPageNews: news }));
    }

    protected shouldFetch(dataFromDatabase: CicgNews[]): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || dataFromDatabase.length === 0 || this.refresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.TWO_MINUTES,
                resourceId: this.constructor.name
            }
        };
    }

    protected startNetworkCall(): Observable<CicgNews[]> {
        return this.apiPagesService.newsApiPage(this.lang);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        this.cleanUpOnNetworkFailure();
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('STATIC_PAGE_NEWS_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name);
    }
}
