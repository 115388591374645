import {Observable} from 'rxjs';
import {GdprService} from '../../api';
import {JustNetworkResource} from '../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class DownloadUserData extends JustNetworkResource<Blob, any> {
    constructor(private gdprService: GdprService) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        return this.gdprService.downloadUser();
    }

    protected onNetworkSuccess(dataFromNetwork: any): Blob {
        return new Blob([JSON.stringify(dataFromNetwork, null, 4)], {type: 'text/plain'});
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
      return UserFriendlyError.displayableAsToast('DOWNLOAD_DATA_KO_TOAST', false);
    }
}
