import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {concat, forkJoin, Observable, of} from 'rxjs';
import {selectHydrateState} from '../../store/reducers/hydrate/hydrate.selectors';
import {Resource} from '../../common/repository/resource';
import {NotificationsRepository} from '../notifications/notifications.repository';
import {RateLimiter} from '../../common/repository/rateLimiter';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {Status} from '../../common/repository/status';
import {Event, News, Notification, Participant, Session} from '../../api';
import {fromPromise} from 'rxjs/internal-compatibility';
import {
    forkJoinFromArrayResourceToResources,
    mapSeveralResourcesToOneSuccessOrError,
    takeFirstSuccessOrErrorAndComplete
} from '../../common/rxjs/operators';
import {CurrentUserIsNotParticipantError, EventsRepository} from '../events/events.repository';
import {UserFriendlyError} from '../../common/repository/userFriendlyError';

@Injectable({
    providedIn: 'root'
})
export class HydrateRepository {

    constructor(private store: Store<AppState>,
                private notificationsRepository: NotificationsRepository,
                private eventsRepository: EventsRepository) {}

    public getHydrateStatus(): Observable<boolean> {
        return this.store.pipe(select(selectHydrateState));
    }

    public initializeStore(): Observable<Resource<any>> {
        return concat(
            of(Resource.loadingBlocking('initializeStore', 'LOADING_STORE_SYNC')), // we first block the UI until the sync is finished
            fromPromise(RateLimiter.getInstance().removeAllLimits()).pipe(
                switchMap(() => {
                    const notifications$ = this.initNotifications();
                    const publishedEvents$ = this.initPublishedEvents();
                    const archivedEvents$ = this.initArchivedEvents();

                    return forkJoin([notifications$, publishedEvents$, archivedEvents$]);
                }),
                mapSeveralResourcesToOneSuccessOrError('initializeStore', true),
                take(1), // we take only the first final result and stop
                map((resource: Resource<any>) => {
                    if (resource.status === Status.ERROR) {
                        const userFriendlyError: UserFriendlyError =
                            UserFriendlyError.displayableAsToast('MEGA_SYNC_ERROR', false);
                        return Resource.error('initializeStore', userFriendlyError);
                    }
                    return resource;
                })
            )
        );
    }

    private initNotifications(): Observable<Resource<any>> {
        return this.notificationsRepository.getNotifications(true).pipe(
            forkJoinFromArrayResourceToResources((notification: Notification) => {
                return this.notificationsRepository.getNotificationById(notification.id, false);
            }),
            mapSeveralResourcesToOneSuccessOrError('initNotifications', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initPublishedEvents(): Observable<Resource<any>> {
        return this.eventsRepository.getPublishedEvents().pipe(
            forkJoinFromArrayResourceToResources((event: Event) => {
                return this.initEvent(event.id);
            }),
            mapSeveralResourcesToOneSuccessOrError('initPublishedEvents', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initArchivedEvents(): Observable<Resource<any>> {
        return this.eventsRepository.getArchivedEvents().pipe(
            forkJoinFromArrayResourceToResources((event: Event) => {
                return this.initEvent(event.id);
            }),
            mapSeveralResourcesToOneSuccessOrError('initArchivedEvents', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getEventById(eventId).pipe(
            takeFirstSuccessOrErrorAndComplete(),
            switchMap((eventResource: Resource<Event>) => {
                if (eventResource.status === Status.SUCCESS && eventResource.data) {
                    const event: Event = eventResource.data;
                    // load everything about the event
                    const news$ = this.initNewsForEvent(event.id);
                    const sessions$ = this.initSessionsForEvent(event.id);
                    const participants$ = this.initParticipantsForEvent(event.id);
                    const talks$ = this.initSpeakerTalksForEvent(event.id);
                    const agendas$ = this.initParticipantAgendasForEvent(event.id);

                    const toExecute$: Observable<Resource<any>>[] = [
                        news$, sessions$, participants$, talks$, agendas$
                    ];

                    // load everything about the event about the current user if it is participant
                    if (EventsRepository.isCurrentUserEventParticipant(event)) {
                        const agenda$ = this.initCurrentUserAgendaForEvent(event);
                        const profile$ = this.initCurrentUserProfileForEvent(event);
                        const settings$ = this.initCurrentUserSettingsForEvent(event);
                        toExecute$.push(agenda$, profile$, settings$);
                    }

                    return forkJoin(toExecute$);
                }
                else {
                    return forkJoin([eventResource]); // we return the error or the success with empty data of the resource with array
                }
            }),
            mapSeveralResourcesToOneSuccessOrError('initEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initNewsForEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getNewsForEvent(eventId).pipe(
            forkJoinFromArrayResourceToResources((news: News) => {
                return this.eventsRepository.getNewsByIdForEvent(eventId, news.id);
            }),
            mapSeveralResourcesToOneSuccessOrError('initNewsForEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initSessionsForEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getSessionsForEvent(eventId).pipe(
            forkJoinFromArrayResourceToResources((session: Session) => {
                return this.eventsRepository.getSessionByIdForEvent(eventId, session.id);
            }),
            mapSeveralResourcesToOneSuccessOrError('initSessionsForEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initParticipantsForEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getParticipantsForEvent(eventId).pipe(
            forkJoinFromArrayResourceToResources((participant: Participant) => {
                return this.eventsRepository.getParticipantByIdForEvent(eventId, participant.id);
            }),
            mapSeveralResourcesToOneSuccessOrError('initParticipantsForEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initSpeakerTalksForEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getParticipantsForEvent(eventId).pipe(
            forkJoinFromArrayResourceToResources((participant: Participant) => {
                if (participant.is_speaker) {
                    return this.eventsRepository.getParticipantTalks(eventId, participant);
                }
                else {
                    return of(Resource.success('initSpeakerTalksForEvent'));
                }
            }),
            mapSeveralResourcesToOneSuccessOrError('initSpeakerTalksForEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initParticipantAgendasForEvent(eventId: number): Observable<Resource<any>> {
        return this.eventsRepository.getParticipantsForEvent(eventId).pipe(
            forkJoinFromArrayResourceToResources((participant: Participant) => {
                if (participant.is_agenda_visible) {
                    return this.eventsRepository.getParticipantAgenda(eventId, participant);
                }
                else {
                    return of(Resource.success('initParticipantAgendasForEvent'));
                }
            }),
            mapSeveralResourcesToOneSuccessOrError('initParticipantAgendasForEvent', false),
            take(1) // we take only the first final result and stop
        );
    }

    private initCurrentUserAgendaForEvent(event: Event): Observable<Resource<any>>{
        return this.eventsRepository.getParticipantAgendaForCurrentUser(event).pipe(
            takeFirstSuccessOrErrorAndComplete() // we take only the first final result and stop
        );
    }

    private initCurrentUserProfileForEvent(event: Event): Observable<Resource<any>>{
        return this.eventsRepository.getParticipantForCurrentUserForEvent(event).pipe(
            takeFirstSuccessOrErrorAndComplete(), // we take only the first final result and stop
            catchError((error: Error) => {
                if (error instanceof CurrentUserIsNotParticipantError) {
                    return of(Resource.success('initCurrentUserProfileForEvent'));
                }
                return of(Resource.error('initCurrentUserProfileForEvent', error));
            })
        );
    }

    private initCurrentUserSettingsForEvent(event: Event): Observable<Resource<any>>{
        return this.eventsRepository.getEventSettingsForCurrentUser(event).pipe(
            takeFirstSuccessOrErrorAndComplete() // we take only the first final result and stop
        );
    }
}
