import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthSessionRepository} from '../../../repositories/auth/authSession.repository';
import {AuthSessionService} from '../../../api';
import {AuthSession} from '../../../api/model/authSession';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private static getRequestWithAuthorizationHeaders(request: HttpRequest<any>,
                                                      accessToken: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken} `
            }
        });
    }

    private static performRequestWithAuthorization(request: HttpRequest<any>,
                                                   next: HttpHandler,
                                                   accessToken: string): Observable<HttpEvent<any>> {
        const authorizedRequest = TokenInterceptor.getRequestWithAuthorizationHeaders(request, accessToken);
        return next.handle(authorizedRequest);
    }

    private authSessionRepository: AuthSessionRepository;
    private authSessionService: AuthSessionService;

    private authSessionRequestInProgress: boolean;

    constructor(private injector: Injector) {
        this.authSessionRequestInProgress = false;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authSessionRepository = this.injector.get(AuthSessionRepository);
        this.authSessionService = this.injector.get(AuthSessionService);

        // if the request is to the auth endpoint just let it pass as is
        // if the request is to local resources just let it pass as is
        if (request.url.includes(AuthSessionService.AUTH_PATH) ||
          !request.url.includes('http') || !request.url.includes('https')) {
            return next.handle(request);
        }

        // we check if the access token exists in memory
        return this.authSessionRepository.getAccessToken()
          .pipe(
            take(1),
            switchMap((accessToken: string) => {
                if (accessToken != null) {
                    // if the access token exists we simply set the authorization headers for the request
                    // and we keep going with the original request
                    return TokenInterceptor.performRequestWithAuthorization(request, next, accessToken);
                } else {
                    // if the access token does not exists we first request a new one and then we do the request
                    // we verify that we are not already requesting it
                    if (!this.authSessionRequestInProgress) {
                        // we perform the API call to login the application
                        this.authSessionRequestInProgress = true;
                        return this.authSessionService.loginApp().pipe(
                          switchMap( (authSession: AuthSession) => {
                              this.authSessionRepository.setAuthSession(authSession);
                              this.authSessionRequestInProgress = false;
                              return TokenInterceptor.performRequestWithAuthorization(request, next, authSession.access_token);
                          })
                        );
                    } else {
                        // we simply listen for the new token to arrive and then do our request
                        return this.authSessionRepository.getAccessToken()
                          .pipe(
                            filter(result => result != null),
                            take(1),
                            switchMap((newAccessToken: string) => {
                                return TokenInterceptor.performRequestWithAuthorization(request, next, newAccessToken);
                            })
                          );
                    }
                }
            }));
    }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private authSessionRepository: AuthSessionRepository;
    private authSessionService: AuthSessionService;

    private gotErrorPreviously: boolean;

    constructor(private injector: Injector) {
        this.gotErrorPreviously = false;
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authSessionRepository = this.injector.get(AuthSessionRepository);
        this.authSessionService = this.injector.get(AuthSessionService);

        return next
          .handle(request)
          .pipe(catchError((error: HttpErrorResponse) => {
              // if the request is to the auth endpoint or we were already trying to recover from a previous 401
              // we clean the auth session because something is very wrong and we should force to start from zero
              if (request.url.includes(AuthSessionService.AUTH_PATH) || this.gotErrorPreviously) {
                  // if there is any error in refreshing the token we invalidate the auth session
                  // and throw the error so the user will be forced to login if they keep trying to
                  // perform any action
                  this.gotErrorPreviously = false;
                  this.authSessionRepository.clearAuthSession();
                  return throwError(error);
              }

              // any error different than 401 not authorized we just throw below
              if (error instanceof HttpErrorResponse && error.status === 401) {
                  // we are not authorized to do the request
                  // if the request is to local resources just throw the error
                  // it should not happen but to be safe
                  if (!request.url.includes('http') || !request.url.includes('https')) {
                      return throwError(error);
                  }

                  this.gotErrorPreviously = true;

                  // we try to see if we have a refresh token
                  return this.authSessionRepository.getRefreshToken().pipe(
                      take(1),
                      switchMap((refreshToken: string) => {
                          if (refreshToken != null) {
                              // we have a refresh token so we should attempt to refresh the auth session
                              // and then restart the request, the token interceptor will do the rest
                              return this.authSessionService.refreshToken(refreshToken).pipe(
                                  switchMap( (authSession: AuthSession) => {
                                      this.gotErrorPreviously = false;
                                      this.authSessionRepository.setAuthSession(authSession);
                                      return next.handle(request);
                                  }) // any error on doing the refresh is handled above
                              );
                          }
                          else {
                              // we do not have the refresh token so we should be only app logged
                              // we invalidate the auth session in the repo and then we simply retry
                              // the token interceptor should redo the app login etc.
                              this.authSessionRepository.clearAuthSession();
                              return next.handle(request).pipe(
                                  tap(() => {
                                      this.gotErrorPreviously = false;
                                  })
                              );
                          }
                      })
                  );
              }
              else {
                  return throwError(error);
              }
          }));
    }
}
