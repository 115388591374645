import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AgendasService, Session, SessionId} from '../../../api';
import {AppState} from '../../../store';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {upsertCurrentUserEventParticipantAgendaSession} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {EventSession} from '../../../store/reducers/eventSessions/event-session.reducer';
import {HttpErrorResponse} from '@angular/common/http';

export class AddEventSessionToCurrentUserEventParticipantAgenda extends JustNetworkResource<Session, any> {
    constructor(private store: Store<AppState>,
                private agendasService: AgendasService,
                private eventId: number,
                private session: Session) {
        super(true);
    }

    protected startNetworkCall(): Observable<any> {
        const sessionId: SessionId = { session_id: this.session.id };
        return this.agendasService.addSessionToMyAgenda(this.eventId, sessionId);
    }

    protected onNetworkSuccess(anything: any): Session {
        return this.addSessionToParticipantAgenda();
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('ADD_AGENDA_ERROR', false);
    }

    protected on403NetworkFailure(error: HttpErrorResponse): Error {
        // if the server answers with an error saying that the session is already in the agenda
        // we add it anyway to the store so that we put client and server are back in sync
        // "\"message\": \"Session already in the agenda\","
        const errorMessage: string = error.error.message;
        if (errorMessage && errorMessage === 'Session already in the agenda') {
            this.addSessionToParticipantAgenda(); // to sync the client with the server
            return UserFriendlyError.displayableAsToast('ALREADY_IN_AGENDA_ERROR', false);
        }
        else {
            return UserFriendlyError.displayableAsToast('ADD_AGENDA_ERROR', false);
        }
    }

    private addSessionToParticipantAgenda(): Session {
        const eventSession: EventSession = {...this.session, eventId: this.eventId};
        this.store.dispatch(upsertCurrentUserEventParticipantAgendaSession({ eventId: this.eventId, eventSession }));
        return this.session;
    }
}
