import {ResourceFetchOptions, NetworkBoundResource} from '../../../common/repository/networkBoundResource';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Participant, ProfilesService} from '../../../api';
import {AppState} from '../../../store';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {selectCurrentUserEventParticipantByEventId} from '../../../store/reducers/eventParticipants/event-participant.selectors';
import {EventParticipant} from '../../../store/reducers/eventParticipants/event-participant.reducer';
import {upsertCurrentUserEventParticipant} from '../../../store/reducers/eventParticipants/event-participant.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {CACHE_TIMES} from '../../../common/repository/rateLimiter';
import {GetEventParticipantAgendaByCurrentUser} from '../agenda/getEventParticipantAgendaByCurrentUser';

export class GetEventParticipantByCurrentUser extends NetworkBoundResource<Participant, Participant> {

    constructor(private store: Store<AppState>,
                private profilesService: ProfilesService,
                private eventId: number,
                private forceRefresh: boolean) {
        super();
    }

    protected loadFromDatabase(): Observable<Participant> {
        return this.store.pipe(select(selectCurrentUserEventParticipantByEventId, this.eventId));
    }

    protected saveNetworkResult(participant: Participant) {
        const eventParticipant: EventParticipant = {...participant, eventId: this.eventId};
        this.store.dispatch(upsertCurrentUserEventParticipant({ eventParticipant }));
    }

    protected shouldFetch(dataFromDatabase: Participant): ResourceFetchOptions {
        return {
            shouldErrorIfNoNetwork: dataFromDatabase == null,
            shouldFetch: dataFromDatabase == null || this.forceRefresh,
            rateLimiterOptions: {
                maxCacheTimeInSeconds: CACHE_TIMES.ONE_MINUTE,
                resourceId: this.constructor.name + '-' + this.eventId,
                rateLimitDependantResourceIds: [GetEventParticipantAgendaByCurrentUser.name + '-' + this.eventId]
            }
        };
    }

    protected startNetworkCall(): Observable<Participant> {
        return this.profilesService.getMyProfile(this.eventId);
    }

    protected onOtherNetworkFailure(error: HttpErrorResponse): Error {
        return UserFriendlyError.displayableAsToast('EVENT_PARTICIPANT_PROFILE_LOAD_ERROR', true);
    }

    protected cleanUpOnNetworkFailure(): void {
        super.removeRateLimiterLimit(this.constructor.name + '-' + this.eventId);
    }
}
