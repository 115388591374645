<app-header titleKey="SEND_EMAIL_BUTTON" [menuIconEnabled]="false" [backArrowEnabled]="true" defaultBackHref="home"></app-header>

<ion-content>
    <div class="ion-text-center ion-align-items-center" id="recover-password-container">
        <form [formGroup]="passwordResetForm">
            <ion-item class="ion-margin-bottom">
                <ion-label id="email-label" position="floating">{{'EMAIL_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="email-input" type="email" formControlName="email" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item class="ion-margin-bottom">
                <ion-label id="password-label" position="floating">{{'PASSWORD_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="password-input" type="password" formControlName="password" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item lines="none" id="pwd-length-error" *ngIf="passwordResetForm.controls.password.hasError('minlength') && passwordResetForm.controls.password.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_LENGTH_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-item lines="none" id="pwd-pattern-error" *ngIf="passwordResetForm.controls.password.hasError('pattern') && passwordResetForm.controls.password.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_FORMAT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-item class="ion-margin-bottom">
                <ion-label id="confirmPassword-label" position="floating">{{'PASSWORD_VERIFY_INPUT_LABEL' | translate}}</ion-label>
                <ion-input required id="confirmPassword-input" type="password" formControlName="password_confirmation" (keyup.enter)="submit()"></ion-input>
            </ion-item>
            <ion-item lines="none" id="pwd-not-matching" *ngIf="passwordResetForm.value.password !== passwordResetForm.value.password_confirmation && passwordResetForm.controls.password_confirmation.touched">
                <ion-text color="danger">
                    <p>{{'PASSWORD_VERIFY_INPUT_ERROR' | translate}}</p>
                </ion-text>
            </ion-item>
            <ion-button id="reset-password-button"
                        (click)="resetPassword()" [disabled]="!passwordResetForm.valid || passwordResetForm.value.password !== passwordResetForm.value.password_confirmation">
                {{"SEND_EMAIL_BUTTON" | translate}}
            </ion-button>
        </form>
    </div>
</ion-content>
