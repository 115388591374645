import {News} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as EventNewsActions from './event-news.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface EventNews extends News {
    eventId: number;
}

export function selectId(eventNews: EventNews): number {
    return eventNews.id;
}

export const adapter: EntityAdapter<EventNews> = createEntityAdapter<EventNews>({
    selectId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<EventNews> = adapter.getInitialState();

const eventNewsReducer = createReducer(
    initialState,
    on(EventNewsActions.upsertManyEventNews, (state, { eventNews }) => adapter.upsertMany(eventNews, state)),
    on(EventNewsActions.upsertOneEventNews, (state, { eventNews }) => adapter.upsertOne(eventNews, state)),
    on(EventNewsActions.clearAllEventNewsForAnEvent, (state, { eventId }) =>
        adapter.removeMany((eventNews: EventNews) => {
            return eventNews.eventId === eventId;
        }, state)),
    on(EventNewsActions.clearAllEventNewsInAllEvents, (state) => adapter.removeAll(state))
);

export function reducer(state: EntityState<EventNews> | undefined, action: Action) {
    return eventNewsReducer(state, action);
}
