import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileItemComponent } from './file-item';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    FileItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  exports: [ FileItemComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FileItemComponentModule {}
