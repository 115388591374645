import {Observable} from 'rxjs';
import {InviteDataRequest, ParticipantsService} from '../../../api';
import {JustNetworkResource} from '../../../common/repository/justNetworkResource';
import {UserFriendlyError} from '../../../common/repository/userFriendlyError';
import {HttpErrorResponse} from '@angular/common/http';

export class ParticipantInviteByToken extends JustNetworkResource<string, any> {
    constructor(private participantService: ParticipantsService,
                private inviteId: string,
                private newData: InviteDataRequest) {
        super(true);
    }

    startNetworkCall(): Observable<any> {
        return this.participantService.participantInviteByToken(this.inviteId, this.newData);
    }

    onNetworkSuccess(dataFromNetwork: any): string {
        return dataFromNetwork.email;
    }

    onOtherNetworkFailure(error: HttpErrorResponse): Error {
        const errorObj = JSON.parse(error.error);
        const {message, errors} = errorObj;
        const toDisplay = message + ': ' + errors.password.map((passErr) => passErr + ' ');
        return UserFriendlyError.displayableAsToast(toDisplay, false);
    }

    // channel any error to onNetworkFailure
    // since we don't know if the status matter to avoid any problem we take over the handling
    // of network errors to redirect them all to the generic
    protected handleNetworkFailure(error: HttpErrorResponse): Error {
        return this.onOtherNetworkFailure(error);
    }
}
