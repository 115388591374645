import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {finalize, share, tap} from 'rxjs/operators';
import {EventsRepository} from '../repositories/events/events.repository';
import {Resource} from '../common/repository/resource';
import {Event} from '../api';
import {ResourceStatusService} from '../common/ui/state-management/resource.status.service';
import {Status} from '../common/repository/status';
import {takeFirstSuccessOrErrorAndComplete} from '../common/rxjs/operators';
import {UserFriendlyError} from '../common/repository/userFriendlyError';

@Injectable({
    providedIn: 'root'
})
export class EventGuard implements CanActivate {

    constructor(private eventsRepository: EventsRepository,
                private resourceStatusService: ResourceStatusService,
                private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // tslint:disable-next-line:radix
        const id = parseInt(next.paramMap.get('id'));

        // We use a subject here to understand when the guard system of angular unsubscribes from
        // our observable so that we can finalize the flow ourselves if needed and also guarantee
        // our guard execution is done to the end and the loading and error info do not end up in a strange state
        const guardSubject: Subject<boolean | UrlTree> = new Subject();
        const guardObservable: Observable<boolean | UrlTree> = guardSubject.asObservable().pipe(
            finalize(() => {
            }),
            share()
        );

        this.eventsRepository.getEventById(id).pipe(
            tap((eventResource: Resource<Event>) => {
                // we show all the status
                this.resourceStatusService.consumeResource(eventResource);
            }),
            takeFirstSuccessOrErrorAndComplete()
        ).subscribe((eventResource: Resource<Event>) => {
            if (eventResource.status === Status.ERROR) {
                if (eventResource.err instanceof UserFriendlyError) {
                    const error: UserFriendlyError = eventResource.err;
                    if (error.isForbiddenError()) {
                        // we block and redirect back to home
                        guardSubject.next(this.router.createUrlTree(['home']));
                    }
                }
                else {
                    // we let the user continue to the event
                    guardSubject.next(true);
                }
            }
            else {
                guardSubject.next(true);
            }
        });

        return guardObservable;
    }
}
