<app-header titleKey="CFF_INFORMATION_LABEL" [menuIconEnabled]="false" [backArrowEnabled]="true"
            defaultBackHref="practical-information/list"></app-header>

<ion-content>
    <ion-refresher #refresher slot="fixed" (ionRefresh)="forceRefresh()">
        <ion-refresher-content
                pullingText="{{'PULL_TO_REFRESH' | translate}}"
                refreshingText="{{'REFRESHING' | translate}}">
        </ion-refresher-content>
    </ion-refresher>

    <div id="cff-api-page" class="practical_info_page">
        <ion-list id="cff-departures-list"
                  *ngIf="cffDepartures$ &&
                        (cffDepartures$ | async)?.length > 0">
            <ion-item id="{{'cff-departure-list-item-' + i}}"
                      class="no-margin-left"
                      *ngFor="let cffDeparture of (cffDepartures$ | async); index as i;">
                <ion-avatar slot="start" class="with-fixed-size">
                    <img src="../assets/imgs/cff.jpg">
                    <!--<div id="{{'cff-departure-list-item-' + i + '-icon'}}"
                         class="item-icon-image">
                        <div class="initials-container ion-text-center">
                            <div class="initials">
                                <span [ngClass]="isAndroidOriOS ? 'span-sub' : ''"></span>
                            </div>
                        </div>
                    </div>-->
                </ion-avatar>
                <ion-label>
                    <div id="{{'cff-departure-list-item-' + i + '-stop'}}" class="ion-align-items-center subtitle">
                        <ion-badge color="danger" type="string" class="vertical-align-middle">
                            {{cffDeparture.train.length <= 4 ? cffDeparture.train : cffDeparture.train.substr(0, 4)}}
                        </ion-badge>
                        <ion-text class="vertical-align-middle">
                        {{'PLATFORM_LABEL' | translate}} {{cffDeparture.platform}}
                        </ion-text>
                        <ion-text color="danger" class="vertical-align-middle-align-bottom">
                            {{cffDeparture.estimated_platform !== null ? ' >> Pl. ' + cffDeparture.estimated_platform : ''}}
                        </ion-text>
                    </div>
                    <h1 id="{{'cff-departure-list-item-' + i + '-label'}}" class="title">
                        <ion-icon name="md-arrow-forward" class="vertical-align-middle"></ion-icon>
                        {{cffDeparture.destination}}
                    </h1>
                </ion-label>
                <div slot="end" class="time-info">
                    <ion-row *ngIf="!cffDeparture.cancelled">
                        {{cffDeparture.departure_time}}
                    </ion-row>
                    <ion-row *ngIf="cffDeparture.estimated_time !== null &&
                         cffDeparture.estimated_time !== cffDeparture.departure_time">
                        <ion-text color="danger">
                            {{cffDeparture.estimated_time}}
                        </ion-text>
                    </ion-row>

                    <ion-text *ngIf="cffDeparture.cancelled" color="danger">
                        <h2>
                            {{'CANCELLED_LABEL' | translate}}
                        </h2>
                    </ion-text>
                </div>
            </ion-item>
            <ion-item class="no-margin-left" (click)="openLink()" lines="none">
                <h5 class="item-label">
                    {{'MORE_INFO_TEXT' | translate}}
                    <span class="url-link">https://cff.ch</span>
                </h5>
            </ion-item>
        </ion-list>

        <span *ngIf="(cffDepartures$ | async)?.length === 0" id="practical-information-empty-list">
            {{'API_NO_RESULTS' | translate}}
        </span>
    </div>
    <!--  LOADING TEMPLATE  -->
    <app-loading-no-data #noCffDataLoading>
        <app-loading-list-no-data></app-loading-list-no-data>
    </app-loading-no-data>
</ion-content>
