import {CicgNews} from '../../../api';
import {Action, createReducer, on} from '@ngrx/store';
import * as StaticPageNewsActions from '../staticPageNews/staticPageNews.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export function selectNId(staticPageNews: CicgNews): string {
    return staticPageNews.nid;
}

export const adapter: EntityAdapter<CicgNews> = createEntityAdapter<CicgNews>({
    selectId: selectNId,
    sortComparer: false, // here it goes a compare function if we want to sort the collection https://ngrx.io/guide/entity/adapter
});

export const initialState: EntityState<CicgNews> = adapter.getInitialState();

const staticPageNewsReducer = createReducer(
  initialState,
  on(StaticPageNewsActions.upsertStaticPageNews, (state, { staticPageNews }) => adapter.upsertMany(staticPageNews, state)),
  on(StaticPageNewsActions.upsertSingleStaticPageNews, (state, { singleStaticPageNews }) => adapter.upsertOne(singleStaticPageNews, state)),
  on(StaticPageNewsActions.clearStaticPageNews, (state) => adapter.removeAll(state)),
);

export function reducer(state: EntityState<CicgNews> | undefined, action: Action) {
    return staticPageNewsReducer(state, action);
}
