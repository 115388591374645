<div class="carrousel-holder clickable">
    <ion-slides *ngIf="files?.length > 0 && !isDesktop"
                [pager]="files?.length > 1"
                [options]="{
                  spaceBetween: 0,
                  speed: 100,
                  slidesPerView: files?.length > 1 ? 1.8 : 1
                }">
        <ion-slide *ngFor="let file of files; index as ind;">
            <app-file-item [file]="file" [index]="ind" (click)="onItemClick(file)"></app-file-item>
        </ion-slide>
    </ion-slides>
    <ion-grid *ngIf="files?.length > 0 && isDesktop">
        <ion-row *ngFor="let num of threePagers; index as n">
            <ion-col class="no-padding-col"
                     size="4"
                     *ngFor="let file of (files ? files.slice((n*3), ((n*3)+3)) : []); index as ind;">
                <app-file-item [file]="file" [index]="ind" (click)="onItemClick(file)"></app-file-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
